/* eslint-disable */

import { AllTypesProps, ReturnTypes, Ops } from './const';
export const HOST = "http://127.0.0.1:8000/graphql/"


export const HEADERS = {}
export const apiSubscription = (options: chainOptions) => (query: string) => {
  try {
    const queryString = options[0] + '?query=' + encodeURIComponent(query);
    const wsString = queryString.replace('http', 'ws');
    const host = (options.length > 1 && options[1]?.websocket?.[0]) || wsString;
    const webSocketOptions = options[1]?.websocket || [host];
    const ws = new WebSocket(...webSocketOptions);
    return {
      ws,
      on: (e: (args: any) => void) => {
        ws.onmessage = (event: any) => {
          if (event.data) {
            const parsed = JSON.parse(event.data);
            const data = parsed.data;
            return e(data);
          }
        };
      },
      off: (e: (args: any) => void) => {
        ws.onclose = e;
      },
      error: (e: (args: any) => void) => {
        ws.onerror = e;
      },
      open: (e: () => void) => {
        ws.onopen = e;
      },
    };
  } catch {
    throw new Error('No websockets implemented');
  }
};
const handleFetchResponse = (response: Response): Promise<GraphQLResponse> => {
  if (!response.ok) {
    return new Promise((_, reject) => {
      response
        .text()
        .then((text) => {
          try {
            reject(JSON.parse(text));
          } catch (err) {
            reject(text);
          }
        })
        .catch(reject);
    });
  }
  return response.json();
};

export const apiFetch =
  (options: fetchOptions) =>
  (query: string, variables: Record<string, unknown> = {}) => {
    const fetchOptions = options[1] || {};
    if (fetchOptions.method && fetchOptions.method === 'GET') {
      return fetch(`${options[0]}?query=${encodeURIComponent(query)}`, fetchOptions)
        .then(handleFetchResponse)
        .then((response: GraphQLResponse) => {
          if (response.errors) {
            throw new GraphQLError(response);
          }
          return response.data;
        });
    }
    return fetch(`${options[0]}`, {
      body: JSON.stringify({ query, variables }),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      ...fetchOptions,
    })
      .then(handleFetchResponse)
      .then((response: GraphQLResponse) => {
        if (response.errors) {
          throw new GraphQLError(response);
        }
        return response.data;
      });
  };

export const InternalsBuildQuery = ({
  ops,
  props,
  returns,
  options,
  scalars,
}: {
  props: AllTypesPropsType;
  returns: ReturnTypesType;
  ops: Operations;
  options?: OperationOptions;
  scalars?: ScalarDefinition;
}) => {
  const ibb = (
    k: string,
    o: InputValueType | VType,
    p = '',
    root = true,
    vars: Array<{ name: string; graphQLType: string }> = [],
  ): string => {
    const keyForPath = purifyGraphQLKey(k);
    const newPath = [p, keyForPath].join(SEPARATOR);
    if (!o) {
      return '';
    }
    if (typeof o === 'boolean' || typeof o === 'number') {
      return k;
    }
    if (typeof o === 'string') {
      return `${k} ${o}`;
    }
    if (Array.isArray(o)) {
      const args = InternalArgsBuilt({
        props,
        returns,
        ops,
        scalars,
        vars,
      })(o[0], newPath);
      return `${ibb(args ? `${k}(${args})` : k, o[1], p, false, vars)}`;
    }
    if (k === '__alias') {
      return Object.entries(o)
        .map(([alias, objectUnderAlias]) => {
          if (typeof objectUnderAlias !== 'object' || Array.isArray(objectUnderAlias)) {
            throw new Error(
              'Invalid alias it should be __alias:{ YOUR_ALIAS_NAME: { OPERATION_NAME: { ...selectors }}}',
            );
          }
          const operationName = Object.keys(objectUnderAlias)[0];
          const operation = objectUnderAlias[operationName];
          return ibb(`${alias}:${operationName}`, operation, p, false, vars);
        })
        .join('\n');
    }
    const hasOperationName = root && options?.operationName ? ' ' + options.operationName : '';
    const keyForDirectives = o.__directives ?? '';
    const query = `{${Object.entries(o)
      .filter(([k]) => k !== '__directives')
      .map((e) => ibb(...e, [p, `field<>${keyForPath}`].join(SEPARATOR), false, vars))
      .join('\n')}}`;
    if (!root) {
      return `${k} ${keyForDirectives}${hasOperationName} ${query}`;
    }
    const varsString = vars.map((v) => `${v.name}: ${v.graphQLType}`).join(', ');
    return `${k} ${keyForDirectives}${hasOperationName}${varsString ? `(${varsString})` : ''} ${query}`;
  };
  return ibb;
};

export const Thunder =
  (fn: FetchFunction) =>
  <O extends keyof typeof Ops, SCLR extends ScalarDefinition, R extends keyof ValueTypes = GenericOperation<O>>(
    operation: O,
    graphqlOptions?: ThunderGraphQLOptions<SCLR>,
  ) =>
  <Z extends ValueTypes[R]>(o: Z | ValueTypes[R], ops?: OperationOptions & { variables?: Record<string, unknown> }) =>
    fn(
      Zeus(operation, o, {
        operationOptions: ops,
        scalars: graphqlOptions?.scalars,
      }),
      ops?.variables,
    ).then((data) => {
      if (graphqlOptions?.scalars) {
        return decodeScalarsInResponse({
          response: data,
          initialOp: operation,
          initialZeusQuery: o as VType,
          returns: ReturnTypes,
          scalars: graphqlOptions.scalars,
          ops: Ops,
        });
      }
      return data;
    }) as Promise<InputType<GraphQLTypes[R], Z, SCLR>>;

export const Chain = (...options: chainOptions) => Thunder(apiFetch(options));

export const SubscriptionThunder =
  (fn: SubscriptionFunction) =>
  <O extends keyof typeof Ops, SCLR extends ScalarDefinition, R extends keyof ValueTypes = GenericOperation<O>>(
    operation: O,
    graphqlOptions?: ThunderGraphQLOptions<SCLR>,
  ) =>
  <Z extends ValueTypes[R]>(o: Z | ValueTypes[R], ops?: OperationOptions & { variables?: ExtractVariables<Z> }) => {
    const returnedFunction = fn(
      Zeus(operation, o, {
        operationOptions: ops,
        scalars: graphqlOptions?.scalars,
      }),
    ) as SubscriptionToGraphQL<Z, GraphQLTypes[R], SCLR>;
    if (returnedFunction?.on && graphqlOptions?.scalars) {
      const wrapped = returnedFunction.on;
      returnedFunction.on = (fnToCall: (args: InputType<GraphQLTypes[R], Z, SCLR>) => void) =>
        wrapped((data: InputType<GraphQLTypes[R], Z, SCLR>) => {
          if (graphqlOptions?.scalars) {
            return fnToCall(
              decodeScalarsInResponse({
                response: data,
                initialOp: operation,
                initialZeusQuery: o as VType,
                returns: ReturnTypes,
                scalars: graphqlOptions.scalars,
                ops: Ops,
              }),
            );
          }
          return fnToCall(data);
        });
    }
    return returnedFunction;
  };

export const Subscription = (...options: chainOptions) => SubscriptionThunder(apiSubscription(options));
export const Zeus = <
  Z extends ValueTypes[R],
  O extends keyof typeof Ops,
  R extends keyof ValueTypes = GenericOperation<O>,
>(
  operation: O,
  o: Z | ValueTypes[R],
  ops?: {
    operationOptions?: OperationOptions;
    scalars?: ScalarDefinition;
  },
) =>
  InternalsBuildQuery({
    props: AllTypesProps,
    returns: ReturnTypes,
    ops: Ops,
    options: ops?.operationOptions,
    scalars: ops?.scalars,
  })(operation, o as VType);

export const ZeusSelect = <T>() => ((t: unknown) => t) as SelectionFunction<T>;

export const Selector = <T extends keyof ValueTypes>(key: T) => key && ZeusSelect<ValueTypes[T]>();

export const TypeFromSelector = <T extends keyof ValueTypes>(key: T) => key && ZeusSelect<ValueTypes[T]>();
export const Gql = Chain(HOST, {
  headers: {
    'Content-Type': 'application/json',
    ...HEADERS,
  },
});

export const ZeusScalars = ZeusSelect<ScalarCoders>();

export const decodeScalarsInResponse = <O extends Operations>({
  response,
  scalars,
  returns,
  ops,
  initialZeusQuery,
  initialOp,
}: {
  ops: O;
  response: any;
  returns: ReturnTypesType;
  scalars?: Record<string, ScalarResolver | undefined>;
  initialOp: keyof O;
  initialZeusQuery: InputValueType | VType;
}) => {
  if (!scalars) {
    return response;
  }
  const builder = PrepareScalarPaths({
    ops,
    returns,
  });

  const scalarPaths = builder(initialOp as string, ops[initialOp], initialZeusQuery);
  if (scalarPaths) {
    const r = traverseResponse({ scalarPaths, resolvers: scalars })('Query', response, ['Query']);
    return r;
  }
  return response;
};

export const traverseResponse = ({
  resolvers,
  scalarPaths,
}: {
  scalarPaths: { [x: string]: `scalar.${string}` };
  resolvers: {
    [x: string]: ScalarResolver | undefined;
  };
}) => {
  const ibb = (k: string, o: InputValueType | VType, p: string[] = []): unknown => {
    if (Array.isArray(o)) {
      return o.map((eachO) => ibb(k, eachO, p));
    }
    const scalarPathString = p.join(SEPARATOR);
    const currentScalarString = scalarPaths[scalarPathString];
    if (currentScalarString) {
      const currentDecoder = resolvers[currentScalarString.split('.')[1]]?.decode;
      if (currentDecoder) {
        return currentDecoder(o);
      }
    }
    if (typeof o === 'boolean' || typeof o === 'number' || typeof o === 'string' || !o) {
      return o;
    }
    return Object.fromEntries(Object.entries(o).map(([k, v]) => [k, ibb(k, v, [...p, purifyGraphQLKey(k)])]));
  };
  return ibb;
};

export type AllTypesPropsType = {
  [x: string]:
    | undefined
    | `scalar.${string}`
    | 'enum'
    | {
        [x: string]:
          | undefined
          | string
          | {
              [x: string]: string | undefined;
            };
      };
};

export type ReturnTypesType = {
  [x: string]:
    | {
        [x: string]: string | undefined;
      }
    | `scalar.${string}`
    | undefined;
};
export type InputValueType = {
  [x: string]: undefined | boolean | string | number | [any, undefined | boolean | InputValueType] | InputValueType;
};
export type VType =
  | undefined
  | boolean
  | string
  | number
  | [any, undefined | boolean | InputValueType]
  | InputValueType;

export type PlainType = boolean | number | string | null | undefined;
export type ZeusArgsType =
  | PlainType
  | {
      [x: string]: ZeusArgsType;
    }
  | Array<ZeusArgsType>;

export type Operations = Record<string, string>;

export type VariableDefinition = {
  [x: string]: unknown;
};

export const SEPARATOR = '|';

export type fetchOptions = Parameters<typeof fetch>;
type websocketOptions = typeof WebSocket extends new (...args: infer R) => WebSocket ? R : never;
export type chainOptions = [fetchOptions[0], fetchOptions[1] & { websocket?: websocketOptions }] | [fetchOptions[0]];
export type FetchFunction = (query: string, variables?: Record<string, unknown>) => Promise<any>;
export type SubscriptionFunction = (query: string) => any;
type NotUndefined<T> = T extends undefined ? never : T;
export type ResolverType<F> = NotUndefined<F extends [infer ARGS, any] ? ARGS : undefined>;

export type OperationOptions = {
  operationName?: string;
};

export type ScalarCoder = Record<string, (s: unknown) => string>;

export interface GraphQLResponse {
  data?: Record<string, any>;
  errors?: Array<{
    message: string;
  }>;
}
export class GraphQLError extends Error {
  constructor(public response: GraphQLResponse) {
    super('');
    console.error(response);
  }
  toString() {
    return 'GraphQL Response Error';
  }
}
export type GenericOperation<O> = O extends keyof typeof Ops ? typeof Ops[O] : never;
export type ThunderGraphQLOptions<SCLR extends ScalarDefinition> = {
  scalars?: SCLR | ScalarCoders;
};

const ExtractScalar = (mappedParts: string[], returns: ReturnTypesType): `scalar.${string}` | undefined => {
  if (mappedParts.length === 0) {
    return;
  }
  const oKey = mappedParts[0];
  const returnP1 = returns[oKey];
  if (typeof returnP1 === 'object') {
    const returnP2 = returnP1[mappedParts[1]];
    if (returnP2) {
      return ExtractScalar([returnP2, ...mappedParts.slice(2)], returns);
    }
    return undefined;
  }
  return returnP1 as `scalar.${string}` | undefined;
};

export const PrepareScalarPaths = ({ ops, returns }: { returns: ReturnTypesType; ops: Operations }) => {
  const ibb = (
    k: string,
    originalKey: string,
    o: InputValueType | VType,
    p: string[] = [],
    pOriginals: string[] = [],
    root = true,
  ): { [x: string]: `scalar.${string}` } | undefined => {
    if (!o) {
      return;
    }
    if (typeof o === 'boolean' || typeof o === 'number' || typeof o === 'string') {
      const extractionArray = [...pOriginals, originalKey];
      const isScalar = ExtractScalar(extractionArray, returns);
      if (isScalar?.startsWith('scalar')) {
        const partOfTree = {
          [[...p, k].join(SEPARATOR)]: isScalar,
        };
        return partOfTree;
      }
      return {};
    }
    if (Array.isArray(o)) {
      return ibb(k, k, o[1], p, pOriginals, false);
    }
    if (k === '__alias') {
      return Object.entries(o)
        .map(([alias, objectUnderAlias]) => {
          if (typeof objectUnderAlias !== 'object' || Array.isArray(objectUnderAlias)) {
            throw new Error(
              'Invalid alias it should be __alias:{ YOUR_ALIAS_NAME: { OPERATION_NAME: { ...selectors }}}',
            );
          }
          const operationName = Object.keys(objectUnderAlias)[0];
          const operation = objectUnderAlias[operationName];
          return ibb(alias, operationName, operation, p, pOriginals, false);
        })
        .reduce((a, b) => ({
          ...a,
          ...b,
        }));
    }
    const keyName = root ? ops[k] : k;
    return Object.entries(o)
      .filter(([k]) => k !== '__directives')
      .map(([k, v]) =>
        ibb(k, k, v, [...p, purifyGraphQLKey(keyName || k)], [...pOriginals, purifyGraphQLKey(originalKey)], false),
      )
      .reduce((a, b) => ({
        ...a,
        ...b,
      }));
  };
  return ibb;
};

export const purifyGraphQLKey = (k: string) => k.replace(/\([^)]*\)/g, '').replace(/^[^:]*\:/g, '');

const mapPart = (p: string) => {
  const [isArg, isField] = p.split('<>');
  if (isField) {
    return {
      v: isField,
      __type: 'field',
    } as const;
  }
  return {
    v: isArg,
    __type: 'arg',
  } as const;
};

type Part = ReturnType<typeof mapPart>;

export const ResolveFromPath = (props: AllTypesPropsType, returns: ReturnTypesType, ops: Operations) => {
  const ResolvePropsType = (mappedParts: Part[]) => {
    const oKey = ops[mappedParts[0].v];
    const propsP1 = oKey ? props[oKey] : props[mappedParts[0].v];
    if (propsP1 === 'enum' && mappedParts.length === 1) {
      return 'enum';
    }
    if (typeof propsP1 === 'string' && propsP1.startsWith('scalar.') && mappedParts.length === 1) {
      return propsP1;
    }
    if (typeof propsP1 === 'object') {
      if (mappedParts.length < 2) {
        return 'not';
      }
      const propsP2 = propsP1[mappedParts[1].v];
      if (typeof propsP2 === 'string') {
        return rpp(
          `${propsP2}${SEPARATOR}${mappedParts
            .slice(2)
            .map((mp) => mp.v)
            .join(SEPARATOR)}`,
        );
      }
      if (typeof propsP2 === 'object') {
        if (mappedParts.length < 3) {
          return 'not';
        }
        const propsP3 = propsP2[mappedParts[2].v];
        if (propsP3 && mappedParts[2].__type === 'arg') {
          return rpp(
            `${propsP3}${SEPARATOR}${mappedParts
              .slice(3)
              .map((mp) => mp.v)
              .join(SEPARATOR)}`,
          );
        }
      }
    }
  };
  const ResolveReturnType = (mappedParts: Part[]) => {
    if (mappedParts.length === 0) {
      return 'not';
    }
    const oKey = ops[mappedParts[0].v];
    const returnP1 = oKey ? returns[oKey] : returns[mappedParts[0].v];
    if (typeof returnP1 === 'object') {
      const returnP2 = returnP1[mappedParts[1].v];
      if (returnP2) {
        return rpp(
          `${returnP2}${SEPARATOR}${mappedParts
            .slice(2)
            .map((mp) => mp.v)
            .join(SEPARATOR)}`,
        );
      }
    }
  };
  const rpp = (path: string): 'enum' | 'not' | `scalar.${string}` => {
    const parts = path.split(SEPARATOR).filter((l) => l.length > 0);
    const mappedParts = parts.map(mapPart);
    const propsP1 = ResolvePropsType(mappedParts);
    if (propsP1) {
      return propsP1;
    }
    const returnP1 = ResolveReturnType(mappedParts);
    if (returnP1) {
      return returnP1;
    }
    return 'not';
  };
  return rpp;
};

export const InternalArgsBuilt = ({
  props,
  ops,
  returns,
  scalars,
  vars,
}: {
  props: AllTypesPropsType;
  returns: ReturnTypesType;
  ops: Operations;
  scalars?: ScalarDefinition;
  vars: Array<{ name: string; graphQLType: string }>;
}) => {
  const arb = (a: ZeusArgsType, p = '', root = true): string => {
    if (typeof a === 'string') {
      if (a.startsWith(START_VAR_NAME)) {
        const [varName, graphQLType] = a.replace(START_VAR_NAME, '$').split(GRAPHQL_TYPE_SEPARATOR);
        const v = vars.find((v) => v.name === varName);
        if (!v) {
          vars.push({
            name: varName,
            graphQLType,
          });
        } else {
          if (v.graphQLType !== graphQLType) {
            throw new Error(
              `Invalid variable exists with two different GraphQL Types, "${v.graphQLType}" and ${graphQLType}`,
            );
          }
        }
        return varName;
      }
    }
    const checkType = ResolveFromPath(props, returns, ops)(p);
    if (checkType.startsWith('scalar.')) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_, ...splittedScalar] = checkType.split('.');
      const scalarKey = splittedScalar.join('.');
      return (scalars?.[scalarKey]?.encode?.(a) as string) || JSON.stringify(a);
    }
    if (Array.isArray(a)) {
      return `[${a.map((arr) => arb(arr, p, false)).join(', ')}]`;
    }
    if (typeof a === 'string') {
      if (checkType === 'enum') {
        return a;
      }
      return `${JSON.stringify(a)}`;
    }
    if (typeof a === 'object') {
      if (a === null) {
        return `null`;
      }
      const returnedObjectString = Object.entries(a)
        .filter(([, v]) => typeof v !== 'undefined')
        .map(([k, v]) => `${k}: ${arb(v, [p, k].join(SEPARATOR), false)}`)
        .join(',\n');
      if (!root) {
        return `{${returnedObjectString}}`;
      }
      return returnedObjectString;
    }
    return `${a}`;
  };
  return arb;
};

export const resolverFor = <X, T extends keyof ResolverInputTypes, Z extends keyof ResolverInputTypes[T]>(
  type: T,
  field: Z,
  fn: (
    args: Required<ResolverInputTypes[T]>[Z] extends [infer Input, any] ? Input : any,
    source: any,
  ) => Z extends keyof ModelTypes[T] ? ModelTypes[T][Z] | Promise<ModelTypes[T][Z]> | X : any,
) => fn as (args?: any, source?: any) => any;

export type UnwrapPromise<T> = T extends Promise<infer R> ? R : T;
export type ZeusState<T extends (...args: any[]) => Promise<any>> = NonNullable<UnwrapPromise<ReturnType<T>>>;
export type ZeusHook<
  T extends (...args: any[]) => Record<string, (...args: any[]) => Promise<any>>,
  N extends keyof ReturnType<T>,
> = ZeusState<ReturnType<T>[N]>;

export type WithTypeNameValue<T> = T & {
  __typename?: boolean;
  __directives?: string;
};
export type AliasType<T> = WithTypeNameValue<T> & {
  __alias?: Record<string, WithTypeNameValue<T>>;
};
type DeepAnify<T> = {
  [P in keyof T]?: any;
};
type IsPayLoad<T> = T extends [any, infer PayLoad] ? PayLoad : T;
export type ScalarDefinition = Record<string, ScalarResolver>;

type IsScalar<S, SCLR extends ScalarDefinition> = S extends 'scalar' & { name: infer T }
  ? T extends keyof SCLR
    ? SCLR[T]['decode'] extends (s: unknown) => unknown
      ? ReturnType<SCLR[T]['decode']>
      : unknown
    : unknown
  : S;
type IsArray<T, U, SCLR extends ScalarDefinition> = T extends Array<infer R>
  ? InputType<R, U, SCLR>[]
  : InputType<T, U, SCLR>;
type FlattenArray<T> = T extends Array<infer R> ? R : T;
type BaseZeusResolver = boolean | 1 | string | Variable<any, string>;

type IsInterfaced<SRC extends DeepAnify<DST>, DST, SCLR extends ScalarDefinition> = FlattenArray<SRC> extends
  | ZEUS_INTERFACES
  | ZEUS_UNIONS
  ? {
      [P in keyof SRC]: SRC[P] extends '__union' & infer R
        ? P extends keyof DST
          ? IsArray<R, '__typename' extends keyof DST ? DST[P] & { __typename: true } : DST[P], SCLR>
          : Record<string, unknown>
        : never;
    }[keyof DST] & {
      [P in keyof Omit<
        Pick<
          SRC,
          {
            [P in keyof DST]: SRC[P] extends '__union' & infer R ? never : P;
          }[keyof DST]
        >,
        '__typename'
      >]: IsPayLoad<DST[P]> extends BaseZeusResolver ? IsScalar<SRC[P], SCLR> : IsArray<SRC[P], DST[P], SCLR>;
    }
  : {
      [P in keyof Pick<SRC, keyof DST>]: IsPayLoad<DST[P]> extends BaseZeusResolver
        ? IsScalar<SRC[P], SCLR>
        : IsArray<SRC[P], DST[P], SCLR>;
    };

export type MapType<SRC, DST, SCLR extends ScalarDefinition> = SRC extends DeepAnify<DST>
  ? IsInterfaced<SRC, DST, SCLR>
  : never;
// eslint-disable-next-line @typescript-eslint/ban-types
export type InputType<SRC, DST, SCLR extends ScalarDefinition = {}> = IsPayLoad<DST> extends { __alias: infer R }
  ? {
      [P in keyof R]: MapType<SRC, R[P], SCLR>[keyof MapType<SRC, R[P], SCLR>];
    } & MapType<SRC, Omit<IsPayLoad<DST>, '__alias'>, SCLR>
  : MapType<SRC, IsPayLoad<DST>, SCLR>;
export type SubscriptionToGraphQL<Z, T, SCLR extends ScalarDefinition> = {
  ws: WebSocket;
  on: (fn: (args: InputType<T, Z, SCLR>) => void) => void;
  off: (fn: (e: { data?: InputType<T, Z, SCLR>; code?: number; reason?: string; message?: string }) => void) => void;
  error: (fn: (e: { data?: InputType<T, Z, SCLR>; errors?: string[] }) => void) => void;
  open: () => void;
};

// eslint-disable-next-line @typescript-eslint/ban-types
export type FromSelector<SELECTOR, NAME extends keyof GraphQLTypes, SCLR extends ScalarDefinition = {}> = InputType<
  GraphQLTypes[NAME],
  SELECTOR,
  SCLR
>;

export type ScalarResolver = {
  encode?: (s: unknown) => string;
  decode?: (s: unknown) => unknown;
};

export type SelectionFunction<V> = <T>(t: T | V) => T;

type BuiltInVariableTypes = {
  ['String']: string;
  ['Int']: number;
  ['Float']: number;
  ['ID']: unknown;
  ['Boolean']: boolean;
};
type AllVariableTypes = keyof BuiltInVariableTypes | keyof ZEUS_VARIABLES;
type VariableRequired<T extends string> = `${T}!` | T | `[${T}]` | `[${T}]!` | `[${T}!]` | `[${T}!]!`;
type VR<T extends string> = VariableRequired<VariableRequired<T>>;

export type GraphQLVariableType = VR<AllVariableTypes>;

type ExtractVariableTypeString<T extends string> = T extends VR<infer R1>
  ? R1 extends VR<infer R2>
    ? R2 extends VR<infer R3>
      ? R3 extends VR<infer R4>
        ? R4 extends VR<infer R5>
          ? R5
          : R4
        : R3
      : R2
    : R1
  : T;

type DecomposeType<T, Type> = T extends `[${infer R}]`
  ? Array<DecomposeType<R, Type>> | undefined
  : T extends `${infer R}!`
  ? NonNullable<DecomposeType<R, Type>>
  : Type | undefined;

type ExtractTypeFromGraphQLType<T extends string> = T extends keyof ZEUS_VARIABLES
  ? ZEUS_VARIABLES[T]
  : T extends keyof BuiltInVariableTypes
  ? BuiltInVariableTypes[T]
  : any;

export type GetVariableType<T extends string> = DecomposeType<
  T,
  ExtractTypeFromGraphQLType<ExtractVariableTypeString<T>>
>;

type UndefinedKeys<T> = {
  [K in keyof T]-?: T[K] extends NonNullable<T[K]> ? never : K;
}[keyof T];

type WithNullableKeys<T> = Pick<T, UndefinedKeys<T>>;
type WithNonNullableKeys<T> = Omit<T, UndefinedKeys<T>>;

type OptionalKeys<T> = {
  [P in keyof T]?: T[P];
};

export type WithOptionalNullables<T> = OptionalKeys<WithNullableKeys<T>> & WithNonNullableKeys<T>;

export type Variable<T extends GraphQLVariableType, Name extends string> = {
  ' __zeus_name': Name;
  ' __zeus_type': T;
};

export type ExtractVariables<Query> = Query extends Variable<infer VType, infer VName>
  ? { [key in VName]: GetVariableType<VType> }
  : Query extends [infer Inputs, infer Outputs]
  ? ExtractVariables<Inputs> & ExtractVariables<Outputs>
  : Query extends string | number | boolean
  ? // eslint-disable-next-line @typescript-eslint/ban-types
    {}
  : UnionToIntersection<{ [K in keyof Query]: WithOptionalNullables<ExtractVariables<Query[K]>> }[keyof Query]>;

type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (k: infer I) => void ? I : never;

export const START_VAR_NAME = `$ZEUS_VAR`;
export const GRAPHQL_TYPE_SEPARATOR = `__$GRAPHQL__`;

export const $ = <Type extends GraphQLVariableType, Name extends string>(name: Name, graphqlType: Type) => {
  return (START_VAR_NAME + name + GRAPHQL_TYPE_SEPARATOR + graphqlType) as unknown as Variable<Type, Name>;
};
type ZEUS_INTERFACES = never
export type ScalarCoders = {
	UUID?: ScalarResolver;
	DateTime?: ScalarResolver;
	Decimal?: ScalarResolver;
	JSONString?: ScalarResolver;
	Upload?: ScalarResolver;
}
type ZEUS_UNIONS = GraphQLTypes["SolutionResponses"] | GraphQLTypes["SolutionUnion"] | GraphQLTypes["AssessmentPreviewResult"] | GraphQLTypes["TestQuestion"] | GraphQLTypes["TestPreviewResult"] | GraphQLTypes["SignupResult"] | GraphQLTypes["SubmitOnboardingAccountDetailsResult"] | GraphQLTypes["SolutionTokenResult"] | GraphQLTypes["ChangeAtsAssociationSettingsUnion"] | GraphQLTypes["DeleteAtsAssociationUnion"]

export type ValueTypes = {
    ["Query"]: AliasType<{
	_debug?:ValueTypes["DjangoDebug"],
	me?:ValueTypes["User"],
supportedSkills?: [{	position?: ValueTypes["PositionInput"] | undefined | null | Variable<any, string>,	before?: string | undefined | null | Variable<any, string>,	after?: string | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>,	last?: number | undefined | null | Variable<any, string>},ValueTypes["SkillConnection"]],
suggestedSkills?: [{	position?: ValueTypes["PositionInput"] | undefined | null | Variable<any, string>,	before?: string | undefined | null | Variable<any, string>,	after?: string | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>,	last?: number | undefined | null | Variable<any, string>},ValueTypes["SkillConnection"]],
solutionResponses?: [{	solutionResponsesInput?: ValueTypes["SolutionResponsesInput"] | undefined | null | Variable<any, string>},ValueTypes["SolutionResponses"]],
solutionResponsesWithToken?: [{	solutionResponsesInput?: ValueTypes["SolutionResponsesInput"] | undefined | null | Variable<any, string>,	token: string | Variable<any, string>},ValueTypes["SolutionResponses"]],
	client?:ValueTypes["ClientQuery"],
	candidate?:ValueTypes["CandidateQuery"],
		__typename?: boolean | `@${string}`
}>;
	/** Debugging information for the current query. */
["DjangoDebug"]: AliasType<{
	/** Executed SQL queries for this API query. */
	sql?:ValueTypes["DjangoDebugSQL"],
		__typename?: boolean | `@${string}`
}>;
	/** Represents a single database query made to a Django managed DB. */
["DjangoDebugSQL"]: AliasType<{
	/** The type of database being used (e.g. postrgesql, mysql, sqlite). */
	vendor?:boolean | `@${string}`,
	/** The Django database alias (e.g. 'default'). */
	alias?:boolean | `@${string}`,
	/** The actual SQL sent to this database. */
	sql?:boolean | `@${string}`,
	/** Duration of this database query in seconds. */
	duration?:boolean | `@${string}`,
	/** The raw SQL of this query, without params. */
	rawSql?:boolean | `@${string}`,
	/** JSON encoded database query parameters. */
	params?:boolean | `@${string}`,
	/** Start time of this database query. */
	startTime?:boolean | `@${string}`,
	/** Stop time of this database query. */
	stopTime?:boolean | `@${string}`,
	/** Whether this database query took more than 10 seconds. */
	isSlow?:boolean | `@${string}`,
	/** Whether this database query was a SELECT. */
	isSelect?:boolean | `@${string}`,
	/** Postgres transaction ID if available. */
	transId?:boolean | `@${string}`,
	/** Postgres transaction status if available. */
	transStatus?:boolean | `@${string}`,
	/** Postgres isolation level if available. */
	isoLevel?:boolean | `@${string}`,
	/** Postgres connection encoding if available. */
	encoding?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["User"]: AliasType<{
	uuid?:boolean | `@${string}`,
	email?:boolean | `@${string}`,
	firstName?:boolean | `@${string}`,
	lastName?:boolean | `@${string}`,
	locale?:boolean | `@${string}`,
	role?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	account?:ValueTypes["Account"],
	lastSeenAt?:boolean | `@${string}`,
	phone?:boolean | `@${string}`,
	active?:boolean | `@${string}`,
	admin?:boolean | `@${string}`,
	accountLocale?:boolean | `@${string}`,
	onboardingCompleted?:boolean | `@${string}`,
	expiresOn?:boolean | `@${string}`,
	emailConfirmed?:boolean | `@${string}`,
	features?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** Leverages the internal Python implmeentation of UUID (uuid.UUID) to provide native UUID objects
in fields, resolvers and input. */
["UUID"]:unknown;
	/** An enumeration. */
["UserLocale"]:UserLocale;
	/** The `DateTime` scalar type represents a DateTime
value as specified by
[iso8601](https://en.wikipedia.org/wiki/ISO_8601). */
["DateTime"]:unknown;
	["Account"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	expiresOn?:boolean | `@${string}`,
	locale?:boolean | `@${string}`,
	billingModel?:boolean | `@${string}`,
	tokensRemaining?:boolean | `@${string}`,
	slots?:boolean | `@${string}`,
	brandingEnabled?:boolean | `@${string}`,
	hasSubscription?:boolean | `@${string}`,
	verify?:boolean | `@${string}`,
	subscription?:ValueTypes["Subscription"],
	slotsInUse?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** An enumeration. */
["AccountLocale"]:AccountLocale;
	/** An enumeration. */
["AccountBillingModel"]:AccountBillingModel;
	["Subscription"]: AliasType<{
	status?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** An enumeration. */
["SubscriptionState"]:SubscriptionState;
	/** An enumeration. */
["Locale"]:Locale;
	/** An enumeration. */
["Feature"]:Feature;
	["SkillConnection"]: AliasType<{
	/** Pagination data for this connection. */
	pageInfo?:ValueTypes["PageInfo"],
	/** Contains the nodes in this connection. */
	edges?:ValueTypes["SkillEdge"],
		__typename?: boolean | `@${string}`
}>;
	/** The Relay compliant `PageInfo` type, containing data necessary to paginate this connection. */
["PageInfo"]: AliasType<{
	/** When paginating forwards, are there more items? */
	hasNextPage?:boolean | `@${string}`,
	/** When paginating backwards, are there more items? */
	hasPreviousPage?:boolean | `@${string}`,
	/** When paginating backwards, the cursor to continue. */
	startCursor?:boolean | `@${string}`,
	/** When paginating forwards, the cursor to continue. */
	endCursor?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** A Relay edge containing a `Skill` and its cursor. */
["SkillEdge"]: AliasType<{
	/** The item at the end of the edge */
	node?:ValueTypes["Competency"],
	/** A cursor for use in pagination */
	cursor?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Competency"]: AliasType<{
	uuid?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	domain?:boolean | `@${string}`,
	icon?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	skillType?:boolean | `@${string}`,
	testDuration?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** An enumeration. */
["Icon"]:Icon;
	/** An enumeration. */
["SkillType"]:SkillType;
	["PositionInput"]: {
	jobTitle: string | Variable<any, string>,
	seniority?: string | undefined | null | Variable<any, string>,
	testLevel: ValueTypes["TestLevel"] | Variable<any, string>,
	testType: ValueTypes["TestType"] | Variable<any, string>,
	description?: string | undefined | null | Variable<any, string>,
	locale?: ValueTypes["Locale"] | undefined | null | Variable<any, string>
};
	/** An enumeration. */
["TestLevel"]:TestLevel;
	/** An enumeration. */
["TestType"]:TestType;
	["SolutionResponses"]: AliasType<{		["...on SolutionResponsesSuccess"] : ValueTypes["SolutionResponsesSuccess"],
		["...on SolutionResponsesError"] : ValueTypes["SolutionResponsesError"]
		__typename?: boolean | `@${string}`
}>;
	["SolutionResponsesSuccess"]: AliasType<{
	tests?:ValueTypes["TestSolution"],
		__typename?: boolean | `@${string}`
}>;
	["TestSolution"]: AliasType<{
	id?:boolean | `@${string}`,
	solution?:ValueTypes["Solution"],
	startedAt?:boolean | `@${string}`,
	submittedAt?:boolean | `@${string}`,
	score?:boolean | `@${string}`,
	responses?:ValueTypes["SolutionTestResponse"],
	skillName?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Solution"]: AliasType<{
	candidate?:ValueTypes["SolutionCandidate"],
	uuid?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	seenAt?:boolean | `@${string}`,
	submittedAt?:boolean | `@${string}`,
	testRoleName?:boolean | `@${string}`,
	starred?:boolean | `@${string}`,
	solutionState?:boolean | `@${string}`,
	score?:boolean | `@${string}`,
	timeSpent?:boolean | `@${string}`,
	personalityFit?:boolean | `@${string}`,
	skillSummaries?:ValueTypes["SkillSummary"],
	summary?:ValueTypes["SummaryEntry"],
	notificationSentAt?:boolean | `@${string}`,
	notificationsFrequency?:boolean | `@${string}`,
	assessment?:ValueTypes["Assessment"],
	openQuestionsResponses?:ValueTypes["Response"],
	situationalSummaries?:ValueTypes["SituationalSummary"],
	hideMedians?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SolutionCandidate"]: AliasType<{
	firstName?:boolean | `@${string}`,
	lastName?:boolean | `@${string}`,
	email?:boolean | `@${string}`,
	uuid?:boolean | `@${string}`,
	phoneNumber?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** An enumeration. */
["CandidateStatus"]:CandidateStatus;
	/** The `Decimal` scalar type represents a python Decimal. */
["Decimal"]:unknown;
	/** An enumeration. */
["PersonalityFit"]:PersonalityFit;
	["SkillSummary"]: AliasType<{
	skillUuid?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	score?:boolean | `@${string}`,
	availableTime?:boolean | `@${string}`,
	timeSpent?:boolean | `@${string}`,
	areas?:ValueTypes["Area"],
	activities?:ValueTypes["JobActivity"],
	comment?:boolean | `@${string}`,
	detailsIncluded?:boolean | `@${string}`,
	suggestedQuestions?:boolean | `@${string}`,
	questionsAnswered?:boolean | `@${string}`,
	questionsTotal?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Area"]: AliasType<{
	name?:boolean | `@${string}`,
	score?:boolean | `@${string}`,
	scoreRange?:boolean | `@${string}`,
	comment?:boolean | `@${string}`,
	explanation?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AreaScoreRange"]:AreaScoreRange;
	["JobActivity"]: AliasType<{
	name?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SummaryEntry"]: AliasType<{
	competency?:ValueTypes["Competency"],
	score?:boolean | `@${string}`,
	availableTime?:boolean | `@${string}`,
	timeSpent?:boolean | `@${string}`,
	totalQuestionsCount?:boolean | `@${string}`,
	answeredQuestionsCount?:boolean | `@${string}`,
	comment?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** An enumeration. */
["NotificationFrequency"]:NotificationFrequency;
	["Assessment"]: AliasType<{
	uuid?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	roleName?:boolean | `@${string}`,
	availableTime?:boolean | `@${string}`,
	numberOfQuestions?:boolean | `@${string}`,
	numberOfOpenQuestions?:boolean | `@${string}`,
	organizationName?:boolean | `@${string}`,
	requiresExternalTool?:boolean | `@${string}`,
	requiredExternalTools?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	competencies?:ValueTypes["Competency"],
	locale?:boolean | `@${string}`,
	talentPool?:boolean | `@${string}`,
	testType?:boolean | `@${string}`,
	testLevel?:boolean | `@${string}`,
	state?:boolean | `@${string}`,
	notificationFrequency?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	seniority?:boolean | `@${string}`,
	solutionsDistribution?:ValueTypes["Bin"],
	averageSkillSummary?:ValueTypes["SummarySkillEntry"],
	atsAssociation?:ValueTypes["AtsAssociation"],
	completedBy?:boolean | `@${string}`,
	mostRecentActivityAt?:boolean | `@${string}`,
	mostRecentActivitySecondsAgo?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** An enumeration. */
["ExternalToolKind"]:ExternalToolKind;
	/** An enumeration. */
["TestState"]:TestState;
	/** An enumeration. */
["Seniority"]:Seniority;
	["Bin"]: AliasType<{
	label?:boolean | `@${string}`,
	value?:boolean | `@${string}`,
	selected?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SummarySkillEntry"]: AliasType<{
	skill?:ValueTypes["Skill"],
	score?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Skill"]: AliasType<{
	uuid?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	domain?:boolean | `@${string}`,
	icon?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AtsAssociation"]: AliasType<{
	integration?:ValueTypes["AtsIntegration"],
	assessment?:ValueTypes["Assessment"],
	jobId?:boolean | `@${string}`,
	stageId?:boolean | `@${string}`,
	emailText?:boolean | `@${string}`,
	emailSubject?:boolean | `@${string}`,
	lastChangedAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AtsIntegration"]: AliasType<{
	account?:ValueTypes["Account"],
	remoteId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Response"]: AliasType<{
	uuid?:boolean | `@${string}`,
	question?:ValueTypes["Question"],
	score?:boolean | `@${string}`,
	scored?:boolean | `@${string}`,
	outdated?:boolean | `@${string}`,
	answersGiven?:ValueTypes["AnswerGiven"],
		__typename?: boolean | `@${string}`
}>;
	["Question"]: AliasType<{
	uuid?:boolean | `@${string}`,
	competencyName?:boolean | `@${string}`,
	skill?:ValueTypes["Skill"],
	custom?:boolean | `@${string}`,
	questionType?:boolean | `@${string}`,
	answers?:ValueTypes["Answer"],
	content?:boolean | `@${string}`,
	appendix?:boolean | `@${string}`,
	availableTime?:boolean | `@${string}`,
	questionStatus?:boolean | `@${string}`,
	requiresExternalTool?:boolean | `@${string}`,
	aiGenerated?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** An enumeration. */
["QuestionTypes"]:QuestionTypes;
	["Answer"]: AliasType<{
	uuid?:boolean | `@${string}`,
	correct?:boolean | `@${string}`,
	content?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AnswerGiven"]: AliasType<{
	uuid?:boolean | `@${string}`,
	answer?:ValueTypes["Answer"],
	correct?:boolean | `@${string}`,
	userSelected?:boolean | `@${string}`,
	content?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SituationalSummary"]: AliasType<{
	skillUuid?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	dimensions?:ValueTypes["DimensionSummary"],
		__typename?: boolean | `@${string}`
}>;
	["DimensionSummary"]: AliasType<{
	skillUuid?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	score?:boolean | `@${string}`,
	distribution?:ValueTypes["Bin"],
	comment?:boolean | `@${string}`,
	scoreLabel?:boolean | `@${string}`,
	dimensionComment?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SolutionTestResponse"]: AliasType<{
	question?:ValueTypes["SolutionTestQuestion"],
	label?:boolean | `@${string}`,
	answerGiven?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SolutionTestQuestion"]: AliasType<{
	answers?:ValueTypes["SolutionTestAnswer"],
	content?:boolean | `@${string}`,
	externalTool?:ValueTypes["ExternalTool"],
	image?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SolutionTestAnswer"]: AliasType<{
	content?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ExternalTool"]: AliasType<{
	instruction?:boolean | `@${string}`,
	url?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** An enumeration. */
["ResponseLabel"]:ResponseLabel;
	["SolutionResponsesError"]: AliasType<{
	error?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SolutionResponsesInput"]: {
	solutionId: ValueTypes["UUID"] | Variable<any, string>
};
	["ClientQuery"]: AliasType<{
solution?: [{	uuid: ValueTypes["UUID"] | Variable<any, string>},ValueTypes["SolutionUnion"]],
test?: [{	uuid: ValueTypes["UUID"] | Variable<any, string>},ValueTypes["TestAssessment"]],
	branding?:ValueTypes["AccountBranding"],
solutionWithToken?: [{	uuid: ValueTypes["UUID"] | Variable<any, string>,	token: string | Variable<any, string>},ValueTypes["SolutionUnion"]],
	productPlan?:ValueTypes["ProductPlan"],
suggestedOpenQuestionsConnection?: [{	skillsUuids: Array<ValueTypes["UUID"] | undefined | null> | Variable<any, string>,	locale: ValueTypes["Locale"] | Variable<any, string>,	jobTitle: string | Variable<any, string>,	jobDescription?: string | undefined | null | Variable<any, string>,	before?: string | undefined | null | Variable<any, string>,	after?: string | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>,	last?: number | undefined | null | Variable<any, string>},ValueTypes["QuestionConnection"]],
suggestedOpenAiQuestionsConnection?: [{	locale: ValueTypes["Locale"] | Variable<any, string>,	jobTitle: string | Variable<any, string>,	jobDescription?: string | undefined | null | Variable<any, string>,	before?: string | undefined | null | Variable<any, string>,	after?: string | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>,	last?: number | undefined | null | Variable<any, string>},ValueTypes["QuestionConnection"]],
solutionConnection?: [{	uuid: ValueTypes["UUID"] | Variable<any, string>,	before?: string | undefined | null | Variable<any, string>,	after?: string | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>,	last?: number | undefined | null | Variable<any, string>},ValueTypes["SolutionConnection"]],
skillsBenchmark?: [{	skillsUuids?: Array<ValueTypes["UUID"] | undefined | null> | undefined | null | Variable<any, string>,	testLevel?: ValueTypes["TestLevel"] | undefined | null | Variable<any, string>,	testType?: ValueTypes["TestType"] | undefined | null | Variable<any, string>,	before?: string | undefined | null | Variable<any, string>,	after?: string | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>,	last?: number | undefined | null | Variable<any, string>},ValueTypes["SkillsBenchmarkConnection"]],
	currentSubscription?:ValueTypes["CurrentSubscription"],
assessmentPreview?: [{	uuid: ValueTypes["UUID"] | Variable<any, string>},ValueTypes["AssessmentPreview"]],
solutionResponses?: [{	solutionUuid: ValueTypes["UUID"] | Variable<any, string>},ValueTypes["SolutionResponses"]],
solutionResponsesWithToken?: [{	solutionUuid: ValueTypes["UUID"] | Variable<any, string>,	token: string | Variable<any, string>},ValueTypes["SolutionResponses"]],
	atsAssociationOptions?:ValueTypes["AtsJob"],
assessment?: [{	uuid: ValueTypes["UUID"] | Variable<any, string>},ValueTypes["Assessment"]],
assessmentConnection?: [{	before?: string | undefined | null | Variable<any, string>,	after?: string | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>,	last?: number | undefined | null | Variable<any, string>},ValueTypes["AssessmentConnection"]],
personalityTestResult?: [{	solutionId: ValueTypes["UUID"] | Variable<any, string>},ValueTypes["PersonalityTestResult"]],
		__typename?: boolean | `@${string}`
}>;
	["SolutionUnion"]: AliasType<{		["...on Solution"] : ValueTypes["Solution"],
		["...on SolutionError"] : ValueTypes["SolutionError"]
		__typename?: boolean | `@${string}`
}>;
	["SolutionError"]: AliasType<{
	error?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TestAssessment"]: AliasType<{
	uuid?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	roleName?:boolean | `@${string}`,
	availableTime?:boolean | `@${string}`,
	numberOfQuestions?:boolean | `@${string}`,
	numberOfOpenQuestions?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	organizationName?:boolean | `@${string}`,
	branding?:ValueTypes["AccountBranding"],
	requiresExternalTool?:boolean | `@${string}`,
	requiredExternalTools?:boolean | `@${string}`,
	locale?:boolean | `@${string}`,
	talentPool?:boolean | `@${string}`,
	testType?:boolean | `@${string}`,
	testLevel?:boolean | `@${string}`,
	competencies?:ValueTypes["Skill"],
	createdAt?:boolean | `@${string}`,
	expiresAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	mostRecentActivityAt?:boolean | `@${string}`,
	mostRecentActivitySecondsAgo?:boolean | `@${string}`,
	disabledAt?:boolean | `@${string}`,
	typeformTestId?:boolean | `@${string}`,
	seniority?:boolean | `@${string}`,
	testState?:boolean | `@${string}`,
	/** Number of submitted solutions to the test */
	completedBy?:boolean | `@${string}`,
	/** Average time in seconds spent on solutions */
	averageSolutionTime?:boolean | `@${string}`,
	/** Average score of solutions */
	averageSolutionScore?:boolean | `@${string}`,
	/** Owner of the test (HR user / organization) */
	assignedTo?:ValueTypes["BaseUser"],
	solutionsDistribution?:ValueTypes["Bin"],
	experimental?:boolean | `@${string}`,
	questions?:ValueTypes["Question"],
	skills?:ValueTypes["Skill"],
	averageSummary?:ValueTypes["TestAssessmentSummary"],
	solutionUuids?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AccountBranding"]: AliasType<{
	uuid?:boolean | `@${string}`,
	logo?:boolean | `@${string}`,
	image?:boolean | `@${string}`,
	primaryColor?:boolean | `@${string}`,
	secondaryColor?:boolean | `@${string}`,
	headline?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BaseUser"]: AliasType<{
	uuid?:boolean | `@${string}`,
	firstName?:boolean | `@${string}`,
	lastName?:boolean | `@${string}`,
	organizationName?:boolean | `@${string}`,
	email?:boolean | `@${string}`,
	isAdmin?:boolean | `@${string}`,
	locale?:boolean | `@${string}`,
	accountId?:boolean | `@${string}`,
	role?:boolean | `@${string}`,
	logUrl?:boolean | `@${string}`,
	accountLogUrl?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** An enumeration. */
["Role"]:Role;
	["TestAssessmentSummary"]: AliasType<{
	competency?:ValueTypes["SummaryCompetency"],
	numberOfQuestions?:boolean | `@${string}`,
	score?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SummaryCompetency"]: AliasType<{
	uuid?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductPlan"]: AliasType<{
	maxNumberOfSkills?:boolean | `@${string}`,
	maxNumberOfOpenQuestions?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["QuestionConnection"]: AliasType<{
	/** Pagination data for this connection. */
	pageInfo?:ValueTypes["PageInfo"],
	/** Contains the nodes in this connection. */
	edges?:ValueTypes["QuestionEdge"],
		__typename?: boolean | `@${string}`
}>;
	/** A Relay edge containing a `Question` and its cursor. */
["QuestionEdge"]: AliasType<{
	/** The item at the end of the edge */
	node?:ValueTypes["Question"],
	/** A cursor for use in pagination */
	cursor?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SolutionConnection"]: AliasType<{
	/** Pagination data for this connection. */
	pageInfo?:ValueTypes["PageInfo"],
	/** Contains the nodes in this connection. */
	edges?:ValueTypes["SolutionEdge"],
		__typename?: boolean | `@${string}`
}>;
	/** A Relay edge containing a `Solution` and its cursor. */
["SolutionEdge"]: AliasType<{
	/** The item at the end of the edge */
	node?:ValueTypes["Solution"],
	/** A cursor for use in pagination */
	cursor?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SkillsBenchmarkConnection"]: AliasType<{
	/** Pagination data for this connection. */
	pageInfo?:ValueTypes["PageInfo"],
	/** Contains the nodes in this connection. */
	edges?:ValueTypes["SkillsBenchmarkEdge"],
		__typename?: boolean | `@${string}`
}>;
	/** A Relay edge containing a `SkillsBenchmark` and its cursor. */
["SkillsBenchmarkEdge"]: AliasType<{
	/** The item at the end of the edge */
	node?:ValueTypes["SkillsBenchmark"],
	/** A cursor for use in pagination */
	cursor?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SkillsBenchmark"]: AliasType<{
	skill?:ValueTypes["Skill"],
	testType?:boolean | `@${string}`,
	testLevel?:boolean | `@${string}`,
	median?:boolean | `@${string}`,
	mean?:boolean | `@${string}`,
	standardDeviation?:boolean | `@${string}`,
	count?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CurrentSubscription"]: AliasType<{
	plan?:ValueTypes["SubscriptionPlan"],
	cancelAtPeriodEnd?:boolean | `@${string}`,
	currentPeriodStart?:boolean | `@${string}`,
	currentPeriodEnd?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionPlan"]: AliasType<{
	name?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AssessmentPreview"]: AliasType<{
	result?:ValueTypes["AssessmentPreviewResult"],
		__typename?: boolean | `@${string}`
}>;
	["AssessmentPreviewResult"]: AliasType<{		["...on Assessment"] : ValueTypes["Assessment"],
		["...on AssessmentPreviewError"] : ValueTypes["AssessmentPreviewError"]
		__typename?: boolean | `@${string}`
}>;
	["AssessmentPreviewError"]: AliasType<{
	value?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AtsJob"]: AliasType<{
	name?:boolean | `@${string}`,
	id?:boolean | `@${string}`,
	stages?:ValueTypes["AtsStage"],
		__typename?: boolean | `@${string}`
}>;
	["AtsStage"]: AliasType<{
	name?:boolean | `@${string}`,
	id?:boolean | `@${string}`,
	index?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AssessmentConnection"]: AliasType<{
	/** Pagination data for this connection. */
	pageInfo?:ValueTypes["PageInfo"],
	/** Contains the nodes in this connection. */
	edges?:ValueTypes["AssessmentEdge"],
		__typename?: boolean | `@${string}`
}>;
	/** A Relay edge containing a `Assessment` and its cursor. */
["AssessmentEdge"]: AliasType<{
	/** The item at the end of the edge */
	node?:ValueTypes["Assessment"],
	/** A cursor for use in pagination */
	cursor?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PersonalityTestResult"]: AliasType<{
	name?:boolean | `@${string}`,
	scales?:ValueTypes["PersonalityTestScale"],
		__typename?: boolean | `@${string}`
}>;
	["PersonalityTestScale"]: AliasType<{
	name?:boolean | `@${string}`,
	scoreRange?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	comment?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** An enumeration. */
["ScoreRange"]:ScoreRange;
	["CandidateQuery"]: AliasType<{
solutionAttemptCandidate?: [{	token: string | Variable<any, string>},ValueTypes["SolutionAttemptCandidate"]],
solutionResults?: [{	id: ValueTypes["UUID"] | Variable<any, string>},ValueTypes["SolutionResults"]],
assessment?: [{	id: ValueTypes["UUID"] | Variable<any, string>},ValueTypes["CandidateAssessment"]],
solution?: [{	id: ValueTypes["UUID"] | Variable<any, string>},ValueTypes["CandidateSolution"]],
candidateTestPreview?: [{	id: ValueTypes["UUID"] | Variable<any, string>},ValueTypes["TestPreviewResult"]],
candidateSolutionPreview?: [{	/** Candidate's session token. */
	sessionToken: string | Variable<any, string>},ValueTypes["CandidateSolutionPreview"]],
		__typename?: boolean | `@${string}`
}>;
	["SolutionAttemptCandidate"]: AliasType<{
	firstName?:boolean | `@${string}`,
	lastName?:boolean | `@${string}`,
	email?:boolean | `@${string}`,
	canAttempt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SolutionResults"]: AliasType<{
	scales?:ValueTypes["CandidateScaleResult"],
		__typename?: boolean | `@${string}`
}>;
	["CandidateScaleResult"]: AliasType<{
	name?:boolean | `@${string}`,
	comment?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CandidateAssessment"]: AliasType<{
	skills?:ValueTypes["CandidateSkill"],
		__typename?: boolean | `@${string}`
}>;
	["CandidateSkill"]: AliasType<{
	name?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CandidateSolution"]: AliasType<{
	suggestedTime?:boolean | `@${string}`,
	testType?:boolean | `@${string}`,
	showComments?:boolean | `@${string}`,
	companyName?:boolean | `@${string}`,
	tests?:ValueTypes["Test"],
		__typename?: boolean | `@${string}`
}>;
	["Test"]: AliasType<{
	availableTime?:boolean | `@${string}`,
	id?:boolean | `@${string}`,
	questions?:ValueTypes["TestQuestion"],
	scorable?:boolean | `@${string}`,
	skillName?:boolean | `@${string}`,
	skillType?:boolean | `@${string}`,
	softTimeLimit?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TestQuestion"]: AliasType<{		["...on SingleChoiceQuestion"] : ValueTypes["SingleChoiceQuestion"],
		["...on MultipleChoiceQuestion"] : ValueTypes["MultipleChoiceQuestion"],
		["...on ClosedNumberQuestion"] : ValueTypes["ClosedNumberQuestion"],
		["...on OpenTextQuestion"] : ValueTypes["OpenTextQuestion"],
		["...on LikertQuestion"] : ValueTypes["LikertQuestion"],
		["...on SituationalQuestion"] : ValueTypes["SituationalQuestion"]
		__typename?: boolean | `@${string}`
}>;
	["SingleChoiceQuestion"]: AliasType<{
	externalTool?:ValueTypes["ExternalTool"],
	possibleAnswers?:ValueTypes["PossibleAnswer"],
	uuid?:boolean | `@${string}`,
	text?:boolean | `@${string}`,
	image?:boolean | `@${string}`,
	answerOptional?:boolean | `@${string}`,
	changingWindowAllowed?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PossibleAnswer"]: AliasType<{
	uuid?:boolean | `@${string}`,
	text?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MultipleChoiceQuestion"]: AliasType<{
	externalTool?:ValueTypes["ExternalTool"],
	possibleAnswers?:ValueTypes["PossibleAnswer"],
	uuid?:boolean | `@${string}`,
	text?:boolean | `@${string}`,
	image?:boolean | `@${string}`,
	answerOptional?:boolean | `@${string}`,
	changingWindowAllowed?:boolean | `@${string}`,
	maxAnswers?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ClosedNumberQuestion"]: AliasType<{
	externalTool?:ValueTypes["ExternalTool"],
	uuid?:boolean | `@${string}`,
	text?:boolean | `@${string}`,
	image?:boolean | `@${string}`,
	answerOptional?:boolean | `@${string}`,
	changingWindowAllowed?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OpenTextQuestion"]: AliasType<{
	externalTool?:ValueTypes["ExternalTool"],
	uuid?:boolean | `@${string}`,
	text?:boolean | `@${string}`,
	image?:boolean | `@${string}`,
	answerOptional?:boolean | `@${string}`,
	changingWindowAllowed?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LikertQuestion"]: AliasType<{
	uuid?:boolean | `@${string}`,
	text?:boolean | `@${string}`,
	image?:boolean | `@${string}`,
	answerOptional?:boolean | `@${string}`,
	changingWindowAllowed?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SituationalQuestion"]: AliasType<{
	possibleAnswers?:ValueTypes["PossibleAnswer"],
	uuid?:boolean | `@${string}`,
	text?:boolean | `@${string}`,
	image?:boolean | `@${string}`,
	answerOptional?:boolean | `@${string}`,
	changingWindowAllowed?:boolean | `@${string}`,
	maxAnswers?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TestPreviewResult"]: AliasType<{		["...on TestPreview"] : ValueTypes["TestPreview"],
		["...on TestPreviewUnavailable"] : ValueTypes["TestPreviewUnavailable"]
		__typename?: boolean | `@${string}`
}>;
	["TestPreview"]: AliasType<{
	uuid?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	roleName?:boolean | `@${string}`,
	availableTime?:boolean | `@${string}`,
	numberOfQuestions?:boolean | `@${string}`,
	numberOfOpenQuestions?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	organizationName?:boolean | `@${string}`,
	branding?:ValueTypes["AccountBranding"],
	requiresExternalTool?:boolean | `@${string}`,
	requiredExternalTools?:boolean | `@${string}`,
	locale?:boolean | `@${string}`,
	talentPool?:boolean | `@${string}`,
	testType?:boolean | `@${string}`,
	testLevel?:boolean | `@${string}`,
	competencies?:ValueTypes["Skill"],
	createdAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TestPreviewUnavailable"]: AliasType<{
	/** Test preview unavailable. */
	reason?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CandidateSolutionPreview"]: AliasType<{
	assessment?:ValueTypes["TestPreview"],
	solutionState?:boolean | `@${string}`,
	candidate?:ValueTypes["CandidatePreview"],
	score?:boolean | `@${string}`,
	timeSpent?:boolean | `@${string}`,
	summary?:ValueTypes["CandidateSummaryEntry"],
		__typename?: boolean | `@${string}`
}>;
	/** An enumeration. */
["SolutionState"]:SolutionState;
	["CandidatePreview"]: AliasType<{
	uuid?:boolean | `@${string}`,
	phoneNumber?:boolean | `@${string}`,
	firstName?:boolean | `@${string}`,
	lastName?:boolean | `@${string}`,
	email?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CandidateSummaryEntry"]: AliasType<{
	competency?:ValueTypes["Competency"],
	numberOfQuestions?:boolean | `@${string}`,
	score?:boolean | `@${string}`,
	comment?:boolean | `@${string}`,
	label?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Mutation"]: AliasType<{
auth?: [{	email: string | Variable<any, string>,	password: string | Variable<any, string>},ValueTypes["Auth"]],
heroTracking?: [{	input: ValueTypes["TrackInput"] | Variable<any, string>},ValueTypes["HeroTracking"]],
	candidate?:ValueTypes["CandidateMutation"],
	client?:ValueTypes["ClientMutation"],
		__typename?: boolean | `@${string}`
}>;
	["Auth"]: AliasType<{
	authorizationToken?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["HeroTracking"]: AliasType<{
	result?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TrackInput"]: {
	eventName: string | Variable<any, string>,
	properties: string | Variable<any, string>
};
	["CandidateMutation"]: AliasType<{
talentPoolOptIn?: [{	solutionId: ValueTypes["UUID"] | Variable<any, string>},ValueTypes["TalentPoolOptIn"]],
addCommentForQuestion?: [{	questionId: ValueTypes["UUID"] | Variable<any, string>,	solutionId: ValueTypes["UUID"] | Variable<any, string>,	text: string | Variable<any, string>},ValueTypes["AddCommentForQuestion"]],
submitSolution?: [{	solutionId: ValueTypes["UUID"] | Variable<any, string>},ValueTypes["SubmitSolution"]],
reportAnomaly?: [{	responseId: ValueTypes["UUID"] | Variable<any, string>,	type: ValueTypes["AnomalyType"] | Variable<any, string>},ValueTypes["ReportAnomaly"]],
startSolution?: [{	candidateSessionToken: string | Variable<any, string>},ValueTypes["StartSolution"]],
submitLikertResponse?: [{	answer: ValueTypes["LikertAnswer"] | Variable<any, string>,	id: ValueTypes["UUID"] | Variable<any, string>},ValueTypes["SubmitLikertResponse"]],
submitTest?: [{	input: ValueTypes["SubmitTestInput"] | Variable<any, string>},ValueTypes["SubmitTest"]],
registerCandidate?: [{	assessmentId: ValueTypes["UUID"] | Variable<any, string>,	email: string | Variable<any, string>,	firstName: string | Variable<any, string>},ValueTypes["RegisterCandidate"]],
issue2faToken?: [{	phoneNumber: string | Variable<any, string>,	testId: ValueTypes["UUID"] | Variable<any, string>},ValueTypes["Issue2FAToken"]],
verify2faToken?: [{	externalId?: string | undefined | null | Variable<any, string>,	jwt?: string | undefined | null | Variable<any, string>,	phoneNumber: string | Variable<any, string>,	testId: ValueTypes["UUID"] | Variable<any, string>,	verificationToken: string | Variable<any, string>},ValueTypes["Verify2FAToken"]],
registerVerifiedCandidate?: [{	email: string | Variable<any, string>,	firstName: string | Variable<any, string>,	intermediateToken: string | Variable<any, string>,	lastName: string | Variable<any, string>},ValueTypes["RegisterVerifiedCandidate"]],
startTest?: [{	input: ValueTypes["StartTestInput"] | Variable<any, string>},ValueTypes["StartTest"]],
seeQuestion?: [{	input: ValueTypes["SeeQuestionInput"] | Variable<any, string>},ValueTypes["SeeQuestion"]],
submitResponse?: [{	input: ValueTypes["SubmitResponseInput"] | Variable<any, string>},ValueTypes["SubmitResponse"]],
submitSituationalResponse?: [{	input: ValueTypes["SubmitSituationalResponseInput"] | Variable<any, string>},ValueTypes["SubmitSituationalResponse"]],
skipQuestion?: [{	input: ValueTypes["SkipQuestionInput"] | Variable<any, string>},ValueTypes["SkipQuestion"]],
giveFeedback?: [{	/** Candidate's session token. */
	candidateSessionToken: string | Variable<any, string>,	/** Feedback score given by a candidate. */
	feedbackScore?: ValueTypes["Decimal"] | undefined | null | Variable<any, string>,	/** Feedback score type given by a candidate. */
	feedbackScoreVersion?: string | undefined | null | Variable<any, string>,	/** Text feedback given by a candidate. */
	feedbackText?: string | undefined | null | Variable<any, string>},ValueTypes["CandidateGiveFeedback"]],
		__typename?: boolean | `@${string}`
}>;
	["TalentPoolOptIn"]: AliasType<{
	result?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AddCommentForQuestion"]: AliasType<{
	result?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubmitSolution"]: AliasType<{
	result?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ReportAnomaly"]: AliasType<{
	result?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** An enumeration. */
["AnomalyType"]:AnomalyType;
	["StartSolution"]: AliasType<{
	result?:ValueTypes["SolutionAttempt"],
		__typename?: boolean | `@${string}`
}>;
	["SolutionAttempt"]: AliasType<{
	suggestedTime?:boolean | `@${string}`,
	testType?:boolean | `@${string}`,
	showComments?:boolean | `@${string}`,
	companyName?:boolean | `@${string}`,
	tests?:ValueTypes["Test"],
	solutionId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubmitLikertResponse"]: AliasType<{
	result?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** An enumeration. */
["LikertAnswer"]:LikertAnswer;
	["SubmitTest"]: AliasType<{
	result?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubmitTestInput"]: {
	candidateSessionToken: string | Variable<any, string>,
	testId: number | Variable<any, string>
};
	["RegisterCandidate"]: AliasType<{
	sessionToken?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Issue2FAToken"]: AliasType<{
	result?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Verify2FAToken"]: AliasType<{
	intermediateToken?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RegisterVerifiedCandidate"]: AliasType<{
	candidateSessionToken?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StartTest"]: AliasType<{
	result?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StartTestInput"]: {
	candidateSessionToken: string | Variable<any, string>,
	testId: number | Variable<any, string>
};
	["SeeQuestion"]: AliasType<{
	result?:boolean | `@${string}`,
	responseId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SeeQuestionInput"]: {
	candidateSessionToken: string | Variable<any, string>,
	questionId: ValueTypes["UUID"] | Variable<any, string>,
	testId: number | Variable<any, string>
};
	["SubmitResponse"]: AliasType<{
	result?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubmitResponseInput"]: {
	candidateSessionToken: string | Variable<any, string>,
	questionId: ValueTypes["UUID"] | Variable<any, string>,
	testId: number | Variable<any, string>,
	answersGiven: Array<ValueTypes["AnswerGivenInput"]> | Variable<any, string>
};
	["AnswerGivenInput"]: {
	text?: string | undefined | null | Variable<any, string>,
	answerId?: ValueTypes["UUID"] | undefined | null | Variable<any, string>
};
	["SubmitSituationalResponse"]: AliasType<{
	result?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubmitSituationalResponseInput"]: {
	candidateSessionToken: string | Variable<any, string>,
	questionId: ValueTypes["UUID"] | Variable<any, string>,
	testId: number | Variable<any, string>,
	answersGiven: Array<ValueTypes["SituationalAnswerGivenInput"]> | Variable<any, string>
};
	["SituationalAnswerGivenInput"]: {
	answerId?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	value?: number | undefined | null | Variable<any, string>
};
	["SkipQuestion"]: AliasType<{
	result?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SkipQuestionInput"]: {
	candidateSessionToken: string | Variable<any, string>,
	questionId: ValueTypes["UUID"] | Variable<any, string>,
	testId: number | Variable<any, string>
};
	["CandidateGiveFeedback"]: AliasType<{
	result?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ClientMutation"]: AliasType<{
startCheckoutSession?: [{	cancelUrl: string | Variable<any, string>,	priceLookupKey: string | Variable<any, string>,	successUrl: string | Variable<any, string>},ValueTypes["StartCheckoutSession"]],
	cancelSubscription?:ValueTypes["CancelSubscription"],
createAssessment?: [{	description?: string | undefined | null | Variable<any, string>,	existingOpenQuestions: Array<ValueTypes["UUID"] | undefined | null> | Variable<any, string>,	locale: ValueTypes["Locale"] | Variable<any, string>,	newOpenQuestions: Array<string | undefined | null> | Variable<any, string>,	newOpenQuestionsAiGenerated?: Array<string | undefined | null> | undefined | null | Variable<any, string>,	roleName: string | Variable<any, string>,	seniority: ValueTypes["Seniority"] | Variable<any, string>,	skillsUuids: Array<ValueTypes["UUID"] | undefined | null> | Variable<any, string>,	testType: ValueTypes["TestType"] | Variable<any, string>},ValueTypes["CreateAssessment"]],
signUp?: [{	email: string | Variable<any, string>,	locale: ValueTypes["Locale"] | Variable<any, string>},ValueTypes["SignUp"]],
submitOnboardingSurvey?: [{	survey: ValueTypes["JSONString"] | Variable<any, string>},ValueTypes["SubmitOnboardingSurvey"]],
submitOnboardingAccountDetails?: [{	companyName: string | Variable<any, string>,	firstName: string | Variable<any, string>,	lastName: string | Variable<any, string>,	position: ValueTypes["Role"] | Variable<any, string>},ValueTypes["SubmitOnboardingAccountDetails"]],
	visitOnboarding?:ValueTypes["VisitOnboarding"],
sendEmail?: [{	body: string | Variable<any, string>,	solutionIds: Array<ValueTypes["UUID"] | undefined | null> | Variable<any, string>,	subject: string | Variable<any, string>},ValueTypes["SendEmail"]],
changeNotificationFrequency?: [{	assessmentId: ValueTypes["UUID"] | Variable<any, string>,	notificationFrequency: ValueTypes["NotificationFrequency"] | Variable<any, string>},ValueTypes["ChangeNotificationFrequency"]],
solutionToken?: [{	solutionUuid: ValueTypes["UUID"] | Variable<any, string>},ValueTypes["SolutionToken"]],
changeAtsAssociationSettings?: [{	assessmentUuid: ValueTypes["UUID"] | Variable<any, string>,	emailSubject: string | Variable<any, string>,	emailText: string | Variable<any, string>,	jobId: string | Variable<any, string>,	stageId: string | Variable<any, string>},ValueTypes["ChangeAtsAssociationSettings"]],
deleteAtsAssociation?: [{	assessmentUuid: ValueTypes["UUID"] | Variable<any, string>},ValueTypes["DeleteAtsAssociation"]],
earlyAccessSignup?: [{	email: string | Variable<any, string>,	firstName: string | Variable<any, string>,	lastName: string | Variable<any, string>,	locale: ValueTypes["Locale"] | Variable<any, string>,	phone: string | Variable<any, string>},ValueTypes["EarlyAccessSignup"]],
setPassword?: [{	password: string | Variable<any, string>,	resetToken: string | Variable<any, string>},ValueTypes["SetPassword"]],
sendResetPassword?: [{	resetPassword: ValueTypes["SendResetPasswordInput"] | Variable<any, string>},ValueTypes["SendResetPassword"]],
archiveTest?: [{	testId: ValueTypes["UUID"] | Variable<any, string>},ValueTypes["ArchiveTest"]],
unarchiveTest?: [{	testId: ValueTypes["UUID"] | Variable<any, string>},ValueTypes["UnarchiveTest"]],
deleteTest?: [{	testId: ValueTypes["UUID"] | Variable<any, string>},ValueTypes["DeleteTest"]],
updateCandidatesStatus?: [{	input: ValueTypes["UpdateCandidatesStatusInput"] | Variable<any, string>},ValueTypes["UpdateCandidatesStatus"]],
updateBranding?: [{	input: ValueTypes["BrandingInput"] | Variable<any, string>},ValueTypes["UpdateBranding"]],
seeSolution?: [{	seeSolution: ValueTypes["SeeSolution"] | Variable<any, string>},ValueTypes["ClientSeeSolution"]],
		__typename?: boolean | `@${string}`
}>;
	["StartCheckoutSession"]: AliasType<{
	result?:ValueTypes["StartCheckoutSessionResult"],
		__typename?: boolean | `@${string}`
}>;
	["StartCheckoutSessionResult"]: AliasType<{
	url?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CancelSubscription"]: AliasType<{
	result?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CreateAssessment"]: AliasType<{
	result?:ValueTypes["Assessment"],
		__typename?: boolean | `@${string}`
}>;
	["SignUp"]: AliasType<{
	result?:ValueTypes["SignupResult"],
		__typename?: boolean | `@${string}`
}>;
	["SignupResult"]: AliasType<{		["...on SignupResultSuccess"] : ValueTypes["SignupResultSuccess"],
		["...on SignupResultError"] : ValueTypes["SignupResultError"]
		__typename?: boolean | `@${string}`
}>;
	["SignupResultSuccess"]: AliasType<{
	auth?:boolean | `@${string}`,
	changePasswordToken?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SignupResultError"]: AliasType<{
	value?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubmitOnboardingSurvey"]: AliasType<{
	result?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** Allows use of a JSON String for input / output from the GraphQL schema.

Use of this type is *not recommended* as you lose the benefits of having a defined, static
schema (one of the key benefits of GraphQL). */
["JSONString"]:unknown;
	["SubmitOnboardingAccountDetails"]: AliasType<{
	result?:ValueTypes["SubmitOnboardingAccountDetailsResult"],
		__typename?: boolean | `@${string}`
}>;
	["SubmitOnboardingAccountDetailsResult"]: AliasType<{		["...on SubmitOnboardingAccountDetailsSuccess"] : ValueTypes["SubmitOnboardingAccountDetailsSuccess"],
		["...on SiubmitOnboardingAccountDetailsError"] : ValueTypes["SiubmitOnboardingAccountDetailsError"]
		__typename?: boolean | `@${string}`
}>;
	["SubmitOnboardingAccountDetailsSuccess"]: AliasType<{
	result?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SiubmitOnboardingAccountDetailsError"]: AliasType<{
	error?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["VisitOnboarding"]: AliasType<{
	result?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SendEmail"]: AliasType<{
	result?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ChangeNotificationFrequency"]: AliasType<{
	result?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SolutionToken"]: AliasType<{
	result?:ValueTypes["SolutionTokenResult"],
		__typename?: boolean | `@${string}`
}>;
	["SolutionTokenResult"]: AliasType<{		["...on SolutionTokenResultSuccess"] : ValueTypes["SolutionTokenResultSuccess"],
		["...on SolutionTokenResultError"] : ValueTypes["SolutionTokenResultError"]
		__typename?: boolean | `@${string}`
}>;
	["SolutionTokenResultSuccess"]: AliasType<{
	token?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SolutionTokenResultError"]: AliasType<{
	error?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ChangeAtsAssociationSettings"]: AliasType<{
	result?:ValueTypes["ChangeAtsAssociationSettingsUnion"],
		__typename?: boolean | `@${string}`
}>;
	["ChangeAtsAssociationSettingsUnion"]: AliasType<{		["...on ChangeAtsAssociationSettingsSuccess"] : ValueTypes["ChangeAtsAssociationSettingsSuccess"],
		["...on ChangeAtsAssociationSettingsError"] : ValueTypes["ChangeAtsAssociationSettingsError"]
		__typename?: boolean | `@${string}`
}>;
	["ChangeAtsAssociationSettingsSuccess"]: AliasType<{
	result?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ChangeAtsAssociationSettingsError"]: AliasType<{
	result?:boolean | `@${string}`,
	error?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DeleteAtsAssociation"]: AliasType<{
	result?:ValueTypes["DeleteAtsAssociationUnion"],
		__typename?: boolean | `@${string}`
}>;
	["DeleteAtsAssociationUnion"]: AliasType<{		["...on DeleteAtsAssociationError"] : ValueTypes["DeleteAtsAssociationError"],
		["...on DeleteAtsAssociationSuccess"] : ValueTypes["DeleteAtsAssociationSuccess"]
		__typename?: boolean | `@${string}`
}>;
	["DeleteAtsAssociationError"]: AliasType<{
	result?:boolean | `@${string}`,
	error?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DeleteAtsAssociationSuccess"]: AliasType<{
	result?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EarlyAccessSignup"]: AliasType<{
	result?:ValueTypes["EarlyAccessSignupResult"],
		__typename?: boolean | `@${string}`
}>;
	["EarlyAccessSignupResult"]: AliasType<{
	status?:boolean | `@${string}`,
	errors?:ValueTypes["Error"],
		__typename?: boolean | `@${string}`
}>;
	["Error"]: AliasType<{
	name?:boolean | `@${string}`,
	message?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SetPassword"]: AliasType<{
	authorizationToken?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SendResetPassword"]: AliasType<{
	Result?:boolean | `@${string}`,
	result?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SendResetPasswordInput"]: {
	email: string | Variable<any, string>
};
	["ArchiveTest"]: AliasType<{
	result?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UnarchiveTest"]: AliasType<{
	result?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DeleteTest"]: AliasType<{
	result?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UpdateCandidatesStatus"]: AliasType<{
	candidates?:ValueTypes["SolutionCandidate"],
		__typename?: boolean | `@${string}`
}>;
	["UpdateCandidatesStatusInput"]: {
	candidateIds: Array<ValueTypes["UUID"] | undefined | null> | Variable<any, string>,
	status: ValueTypes["CandidateStatusInput"] | Variable<any, string>
};
	/** An enumeration. */
["CandidateStatusInput"]:CandidateStatusInput;
	["UpdateBranding"]: AliasType<{
	result?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BrandingInput"]: {
	logo?: ValueTypes["Upload"] | undefined | null | Variable<any, string>,
	image?: ValueTypes["Upload"] | undefined | null | Variable<any, string>,
	primaryColor: string | Variable<any, string>,
	secondaryColor?: string | undefined | null | Variable<any, string>,
	headline: string | Variable<any, string>,
	description: string | Variable<any, string>,
	removeLogo?: boolean | undefined | null | Variable<any, string>,
	removeImage?: boolean | undefined | null | Variable<any, string>
};
	/** Create scalar that ignores normal serialization/deserialization, since
that will be handled by the multipart request spec */
["Upload"]:unknown;
	["ClientSeeSolution"]: AliasType<{
	result?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SeeSolution"]: {
	solutionId: ValueTypes["UUID"] | Variable<any, string>
}
  }

export type ResolverInputTypes = {
    ["Query"]: AliasType<{
	_debug?:ResolverInputTypes["DjangoDebug"],
	me?:ResolverInputTypes["User"],
supportedSkills?: [{	position?: ResolverInputTypes["PositionInput"] | undefined | null,	before?: string | undefined | null,	after?: string | undefined | null,	first?: number | undefined | null,	last?: number | undefined | null},ResolverInputTypes["SkillConnection"]],
suggestedSkills?: [{	position?: ResolverInputTypes["PositionInput"] | undefined | null,	before?: string | undefined | null,	after?: string | undefined | null,	first?: number | undefined | null,	last?: number | undefined | null},ResolverInputTypes["SkillConnection"]],
solutionResponses?: [{	solutionResponsesInput?: ResolverInputTypes["SolutionResponsesInput"] | undefined | null},ResolverInputTypes["SolutionResponses"]],
solutionResponsesWithToken?: [{	solutionResponsesInput?: ResolverInputTypes["SolutionResponsesInput"] | undefined | null,	token: string},ResolverInputTypes["SolutionResponses"]],
	client?:ResolverInputTypes["ClientQuery"],
	candidate?:ResolverInputTypes["CandidateQuery"],
		__typename?: boolean | `@${string}`
}>;
	/** Debugging information for the current query. */
["DjangoDebug"]: AliasType<{
	/** Executed SQL queries for this API query. */
	sql?:ResolverInputTypes["DjangoDebugSQL"],
		__typename?: boolean | `@${string}`
}>;
	/** Represents a single database query made to a Django managed DB. */
["DjangoDebugSQL"]: AliasType<{
	/** The type of database being used (e.g. postrgesql, mysql, sqlite). */
	vendor?:boolean | `@${string}`,
	/** The Django database alias (e.g. 'default'). */
	alias?:boolean | `@${string}`,
	/** The actual SQL sent to this database. */
	sql?:boolean | `@${string}`,
	/** Duration of this database query in seconds. */
	duration?:boolean | `@${string}`,
	/** The raw SQL of this query, without params. */
	rawSql?:boolean | `@${string}`,
	/** JSON encoded database query parameters. */
	params?:boolean | `@${string}`,
	/** Start time of this database query. */
	startTime?:boolean | `@${string}`,
	/** Stop time of this database query. */
	stopTime?:boolean | `@${string}`,
	/** Whether this database query took more than 10 seconds. */
	isSlow?:boolean | `@${string}`,
	/** Whether this database query was a SELECT. */
	isSelect?:boolean | `@${string}`,
	/** Postgres transaction ID if available. */
	transId?:boolean | `@${string}`,
	/** Postgres transaction status if available. */
	transStatus?:boolean | `@${string}`,
	/** Postgres isolation level if available. */
	isoLevel?:boolean | `@${string}`,
	/** Postgres connection encoding if available. */
	encoding?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["User"]: AliasType<{
	uuid?:boolean | `@${string}`,
	email?:boolean | `@${string}`,
	firstName?:boolean | `@${string}`,
	lastName?:boolean | `@${string}`,
	locale?:boolean | `@${string}`,
	role?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	account?:ResolverInputTypes["Account"],
	lastSeenAt?:boolean | `@${string}`,
	phone?:boolean | `@${string}`,
	active?:boolean | `@${string}`,
	admin?:boolean | `@${string}`,
	accountLocale?:boolean | `@${string}`,
	onboardingCompleted?:boolean | `@${string}`,
	expiresOn?:boolean | `@${string}`,
	emailConfirmed?:boolean | `@${string}`,
	features?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** Leverages the internal Python implmeentation of UUID (uuid.UUID) to provide native UUID objects
in fields, resolvers and input. */
["UUID"]:unknown;
	/** An enumeration. */
["UserLocale"]:UserLocale;
	/** The `DateTime` scalar type represents a DateTime
value as specified by
[iso8601](https://en.wikipedia.org/wiki/ISO_8601). */
["DateTime"]:unknown;
	["Account"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	expiresOn?:boolean | `@${string}`,
	locale?:boolean | `@${string}`,
	billingModel?:boolean | `@${string}`,
	tokensRemaining?:boolean | `@${string}`,
	slots?:boolean | `@${string}`,
	brandingEnabled?:boolean | `@${string}`,
	hasSubscription?:boolean | `@${string}`,
	verify?:boolean | `@${string}`,
	subscription?:ResolverInputTypes["Subscription"],
	slotsInUse?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** An enumeration. */
["AccountLocale"]:AccountLocale;
	/** An enumeration. */
["AccountBillingModel"]:AccountBillingModel;
	["Subscription"]: AliasType<{
	status?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** An enumeration. */
["SubscriptionState"]:SubscriptionState;
	/** An enumeration. */
["Locale"]:Locale;
	/** An enumeration. */
["Feature"]:Feature;
	["SkillConnection"]: AliasType<{
	/** Pagination data for this connection. */
	pageInfo?:ResolverInputTypes["PageInfo"],
	/** Contains the nodes in this connection. */
	edges?:ResolverInputTypes["SkillEdge"],
		__typename?: boolean | `@${string}`
}>;
	/** The Relay compliant `PageInfo` type, containing data necessary to paginate this connection. */
["PageInfo"]: AliasType<{
	/** When paginating forwards, are there more items? */
	hasNextPage?:boolean | `@${string}`,
	/** When paginating backwards, are there more items? */
	hasPreviousPage?:boolean | `@${string}`,
	/** When paginating backwards, the cursor to continue. */
	startCursor?:boolean | `@${string}`,
	/** When paginating forwards, the cursor to continue. */
	endCursor?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** A Relay edge containing a `Skill` and its cursor. */
["SkillEdge"]: AliasType<{
	/** The item at the end of the edge */
	node?:ResolverInputTypes["Competency"],
	/** A cursor for use in pagination */
	cursor?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Competency"]: AliasType<{
	uuid?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	domain?:boolean | `@${string}`,
	icon?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	skillType?:boolean | `@${string}`,
	testDuration?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** An enumeration. */
["Icon"]:Icon;
	/** An enumeration. */
["SkillType"]:SkillType;
	["PositionInput"]: {
	jobTitle: string,
	seniority?: string | undefined | null,
	testLevel: ResolverInputTypes["TestLevel"],
	testType: ResolverInputTypes["TestType"],
	description?: string | undefined | null,
	locale?: ResolverInputTypes["Locale"] | undefined | null
};
	/** An enumeration. */
["TestLevel"]:TestLevel;
	/** An enumeration. */
["TestType"]:TestType;
	["SolutionResponses"]: AliasType<{
	SolutionResponsesSuccess?:ResolverInputTypes["SolutionResponsesSuccess"],
	SolutionResponsesError?:ResolverInputTypes["SolutionResponsesError"],
		__typename?: boolean | `@${string}`
}>;
	["SolutionResponsesSuccess"]: AliasType<{
	tests?:ResolverInputTypes["TestSolution"],
		__typename?: boolean | `@${string}`
}>;
	["TestSolution"]: AliasType<{
	id?:boolean | `@${string}`,
	solution?:ResolverInputTypes["Solution"],
	startedAt?:boolean | `@${string}`,
	submittedAt?:boolean | `@${string}`,
	score?:boolean | `@${string}`,
	responses?:ResolverInputTypes["SolutionTestResponse"],
	skillName?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Solution"]: AliasType<{
	candidate?:ResolverInputTypes["SolutionCandidate"],
	uuid?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	seenAt?:boolean | `@${string}`,
	submittedAt?:boolean | `@${string}`,
	testRoleName?:boolean | `@${string}`,
	starred?:boolean | `@${string}`,
	solutionState?:boolean | `@${string}`,
	score?:boolean | `@${string}`,
	timeSpent?:boolean | `@${string}`,
	personalityFit?:boolean | `@${string}`,
	skillSummaries?:ResolverInputTypes["SkillSummary"],
	summary?:ResolverInputTypes["SummaryEntry"],
	notificationSentAt?:boolean | `@${string}`,
	notificationsFrequency?:boolean | `@${string}`,
	assessment?:ResolverInputTypes["Assessment"],
	openQuestionsResponses?:ResolverInputTypes["Response"],
	situationalSummaries?:ResolverInputTypes["SituationalSummary"],
	hideMedians?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SolutionCandidate"]: AliasType<{
	firstName?:boolean | `@${string}`,
	lastName?:boolean | `@${string}`,
	email?:boolean | `@${string}`,
	uuid?:boolean | `@${string}`,
	phoneNumber?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** An enumeration. */
["CandidateStatus"]:CandidateStatus;
	/** The `Decimal` scalar type represents a python Decimal. */
["Decimal"]:unknown;
	/** An enumeration. */
["PersonalityFit"]:PersonalityFit;
	["SkillSummary"]: AliasType<{
	skillUuid?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	score?:boolean | `@${string}`,
	availableTime?:boolean | `@${string}`,
	timeSpent?:boolean | `@${string}`,
	areas?:ResolverInputTypes["Area"],
	activities?:ResolverInputTypes["JobActivity"],
	comment?:boolean | `@${string}`,
	detailsIncluded?:boolean | `@${string}`,
	suggestedQuestions?:boolean | `@${string}`,
	questionsAnswered?:boolean | `@${string}`,
	questionsTotal?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Area"]: AliasType<{
	name?:boolean | `@${string}`,
	score?:boolean | `@${string}`,
	scoreRange?:boolean | `@${string}`,
	comment?:boolean | `@${string}`,
	explanation?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AreaScoreRange"]:AreaScoreRange;
	["JobActivity"]: AliasType<{
	name?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SummaryEntry"]: AliasType<{
	competency?:ResolverInputTypes["Competency"],
	score?:boolean | `@${string}`,
	availableTime?:boolean | `@${string}`,
	timeSpent?:boolean | `@${string}`,
	totalQuestionsCount?:boolean | `@${string}`,
	answeredQuestionsCount?:boolean | `@${string}`,
	comment?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** An enumeration. */
["NotificationFrequency"]:NotificationFrequency;
	["Assessment"]: AliasType<{
	uuid?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	roleName?:boolean | `@${string}`,
	availableTime?:boolean | `@${string}`,
	numberOfQuestions?:boolean | `@${string}`,
	numberOfOpenQuestions?:boolean | `@${string}`,
	organizationName?:boolean | `@${string}`,
	requiresExternalTool?:boolean | `@${string}`,
	requiredExternalTools?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	competencies?:ResolverInputTypes["Competency"],
	locale?:boolean | `@${string}`,
	talentPool?:boolean | `@${string}`,
	testType?:boolean | `@${string}`,
	testLevel?:boolean | `@${string}`,
	state?:boolean | `@${string}`,
	notificationFrequency?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	seniority?:boolean | `@${string}`,
	solutionsDistribution?:ResolverInputTypes["Bin"],
	averageSkillSummary?:ResolverInputTypes["SummarySkillEntry"],
	atsAssociation?:ResolverInputTypes["AtsAssociation"],
	completedBy?:boolean | `@${string}`,
	mostRecentActivityAt?:boolean | `@${string}`,
	mostRecentActivitySecondsAgo?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** An enumeration. */
["ExternalToolKind"]:ExternalToolKind;
	/** An enumeration. */
["TestState"]:TestState;
	/** An enumeration. */
["Seniority"]:Seniority;
	["Bin"]: AliasType<{
	label?:boolean | `@${string}`,
	value?:boolean | `@${string}`,
	selected?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SummarySkillEntry"]: AliasType<{
	skill?:ResolverInputTypes["Skill"],
	score?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Skill"]: AliasType<{
	uuid?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	domain?:boolean | `@${string}`,
	icon?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AtsAssociation"]: AliasType<{
	integration?:ResolverInputTypes["AtsIntegration"],
	assessment?:ResolverInputTypes["Assessment"],
	jobId?:boolean | `@${string}`,
	stageId?:boolean | `@${string}`,
	emailText?:boolean | `@${string}`,
	emailSubject?:boolean | `@${string}`,
	lastChangedAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AtsIntegration"]: AliasType<{
	account?:ResolverInputTypes["Account"],
	remoteId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Response"]: AliasType<{
	uuid?:boolean | `@${string}`,
	question?:ResolverInputTypes["Question"],
	score?:boolean | `@${string}`,
	scored?:boolean | `@${string}`,
	outdated?:boolean | `@${string}`,
	answersGiven?:ResolverInputTypes["AnswerGiven"],
		__typename?: boolean | `@${string}`
}>;
	["Question"]: AliasType<{
	uuid?:boolean | `@${string}`,
	competencyName?:boolean | `@${string}`,
	skill?:ResolverInputTypes["Skill"],
	custom?:boolean | `@${string}`,
	questionType?:boolean | `@${string}`,
	answers?:ResolverInputTypes["Answer"],
	content?:boolean | `@${string}`,
	appendix?:boolean | `@${string}`,
	availableTime?:boolean | `@${string}`,
	questionStatus?:boolean | `@${string}`,
	requiresExternalTool?:boolean | `@${string}`,
	aiGenerated?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** An enumeration. */
["QuestionTypes"]:QuestionTypes;
	["Answer"]: AliasType<{
	uuid?:boolean | `@${string}`,
	correct?:boolean | `@${string}`,
	content?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AnswerGiven"]: AliasType<{
	uuid?:boolean | `@${string}`,
	answer?:ResolverInputTypes["Answer"],
	correct?:boolean | `@${string}`,
	userSelected?:boolean | `@${string}`,
	content?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SituationalSummary"]: AliasType<{
	skillUuid?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	dimensions?:ResolverInputTypes["DimensionSummary"],
		__typename?: boolean | `@${string}`
}>;
	["DimensionSummary"]: AliasType<{
	skillUuid?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	score?:boolean | `@${string}`,
	distribution?:ResolverInputTypes["Bin"],
	comment?:boolean | `@${string}`,
	scoreLabel?:boolean | `@${string}`,
	dimensionComment?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SolutionTestResponse"]: AliasType<{
	question?:ResolverInputTypes["SolutionTestQuestion"],
	label?:boolean | `@${string}`,
	answerGiven?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SolutionTestQuestion"]: AliasType<{
	answers?:ResolverInputTypes["SolutionTestAnswer"],
	content?:boolean | `@${string}`,
	externalTool?:ResolverInputTypes["ExternalTool"],
	image?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SolutionTestAnswer"]: AliasType<{
	content?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ExternalTool"]: AliasType<{
	instruction?:boolean | `@${string}`,
	url?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** An enumeration. */
["ResponseLabel"]:ResponseLabel;
	["SolutionResponsesError"]: AliasType<{
	error?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SolutionResponsesInput"]: {
	solutionId: ResolverInputTypes["UUID"]
};
	["ClientQuery"]: AliasType<{
solution?: [{	uuid: ResolverInputTypes["UUID"]},ResolverInputTypes["SolutionUnion"]],
test?: [{	uuid: ResolverInputTypes["UUID"]},ResolverInputTypes["TestAssessment"]],
	branding?:ResolverInputTypes["AccountBranding"],
solutionWithToken?: [{	uuid: ResolverInputTypes["UUID"],	token: string},ResolverInputTypes["SolutionUnion"]],
	productPlan?:ResolverInputTypes["ProductPlan"],
suggestedOpenQuestionsConnection?: [{	skillsUuids: Array<ResolverInputTypes["UUID"] | undefined | null>,	locale: ResolverInputTypes["Locale"],	jobTitle: string,	jobDescription?: string | undefined | null,	before?: string | undefined | null,	after?: string | undefined | null,	first?: number | undefined | null,	last?: number | undefined | null},ResolverInputTypes["QuestionConnection"]],
suggestedOpenAiQuestionsConnection?: [{	locale: ResolverInputTypes["Locale"],	jobTitle: string,	jobDescription?: string | undefined | null,	before?: string | undefined | null,	after?: string | undefined | null,	first?: number | undefined | null,	last?: number | undefined | null},ResolverInputTypes["QuestionConnection"]],
solutionConnection?: [{	uuid: ResolverInputTypes["UUID"],	before?: string | undefined | null,	after?: string | undefined | null,	first?: number | undefined | null,	last?: number | undefined | null},ResolverInputTypes["SolutionConnection"]],
skillsBenchmark?: [{	skillsUuids?: Array<ResolverInputTypes["UUID"] | undefined | null> | undefined | null,	testLevel?: ResolverInputTypes["TestLevel"] | undefined | null,	testType?: ResolverInputTypes["TestType"] | undefined | null,	before?: string | undefined | null,	after?: string | undefined | null,	first?: number | undefined | null,	last?: number | undefined | null},ResolverInputTypes["SkillsBenchmarkConnection"]],
	currentSubscription?:ResolverInputTypes["CurrentSubscription"],
assessmentPreview?: [{	uuid: ResolverInputTypes["UUID"]},ResolverInputTypes["AssessmentPreview"]],
solutionResponses?: [{	solutionUuid: ResolverInputTypes["UUID"]},ResolverInputTypes["SolutionResponses"]],
solutionResponsesWithToken?: [{	solutionUuid: ResolverInputTypes["UUID"],	token: string},ResolverInputTypes["SolutionResponses"]],
	atsAssociationOptions?:ResolverInputTypes["AtsJob"],
assessment?: [{	uuid: ResolverInputTypes["UUID"]},ResolverInputTypes["Assessment"]],
assessmentConnection?: [{	before?: string | undefined | null,	after?: string | undefined | null,	first?: number | undefined | null,	last?: number | undefined | null},ResolverInputTypes["AssessmentConnection"]],
personalityTestResult?: [{	solutionId: ResolverInputTypes["UUID"]},ResolverInputTypes["PersonalityTestResult"]],
		__typename?: boolean | `@${string}`
}>;
	["SolutionUnion"]: AliasType<{
	Solution?:ResolverInputTypes["Solution"],
	SolutionError?:ResolverInputTypes["SolutionError"],
		__typename?: boolean | `@${string}`
}>;
	["SolutionError"]: AliasType<{
	error?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TestAssessment"]: AliasType<{
	uuid?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	roleName?:boolean | `@${string}`,
	availableTime?:boolean | `@${string}`,
	numberOfQuestions?:boolean | `@${string}`,
	numberOfOpenQuestions?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	organizationName?:boolean | `@${string}`,
	branding?:ResolverInputTypes["AccountBranding"],
	requiresExternalTool?:boolean | `@${string}`,
	requiredExternalTools?:boolean | `@${string}`,
	locale?:boolean | `@${string}`,
	talentPool?:boolean | `@${string}`,
	testType?:boolean | `@${string}`,
	testLevel?:boolean | `@${string}`,
	competencies?:ResolverInputTypes["Skill"],
	createdAt?:boolean | `@${string}`,
	expiresAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	mostRecentActivityAt?:boolean | `@${string}`,
	mostRecentActivitySecondsAgo?:boolean | `@${string}`,
	disabledAt?:boolean | `@${string}`,
	typeformTestId?:boolean | `@${string}`,
	seniority?:boolean | `@${string}`,
	testState?:boolean | `@${string}`,
	/** Number of submitted solutions to the test */
	completedBy?:boolean | `@${string}`,
	/** Average time in seconds spent on solutions */
	averageSolutionTime?:boolean | `@${string}`,
	/** Average score of solutions */
	averageSolutionScore?:boolean | `@${string}`,
	/** Owner of the test (HR user / organization) */
	assignedTo?:ResolverInputTypes["BaseUser"],
	solutionsDistribution?:ResolverInputTypes["Bin"],
	experimental?:boolean | `@${string}`,
	questions?:ResolverInputTypes["Question"],
	skills?:ResolverInputTypes["Skill"],
	averageSummary?:ResolverInputTypes["TestAssessmentSummary"],
	solutionUuids?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AccountBranding"]: AliasType<{
	uuid?:boolean | `@${string}`,
	logo?:boolean | `@${string}`,
	image?:boolean | `@${string}`,
	primaryColor?:boolean | `@${string}`,
	secondaryColor?:boolean | `@${string}`,
	headline?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BaseUser"]: AliasType<{
	uuid?:boolean | `@${string}`,
	firstName?:boolean | `@${string}`,
	lastName?:boolean | `@${string}`,
	organizationName?:boolean | `@${string}`,
	email?:boolean | `@${string}`,
	isAdmin?:boolean | `@${string}`,
	locale?:boolean | `@${string}`,
	accountId?:boolean | `@${string}`,
	role?:boolean | `@${string}`,
	logUrl?:boolean | `@${string}`,
	accountLogUrl?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** An enumeration. */
["Role"]:Role;
	["TestAssessmentSummary"]: AliasType<{
	competency?:ResolverInputTypes["SummaryCompetency"],
	numberOfQuestions?:boolean | `@${string}`,
	score?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SummaryCompetency"]: AliasType<{
	uuid?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductPlan"]: AliasType<{
	maxNumberOfSkills?:boolean | `@${string}`,
	maxNumberOfOpenQuestions?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["QuestionConnection"]: AliasType<{
	/** Pagination data for this connection. */
	pageInfo?:ResolverInputTypes["PageInfo"],
	/** Contains the nodes in this connection. */
	edges?:ResolverInputTypes["QuestionEdge"],
		__typename?: boolean | `@${string}`
}>;
	/** A Relay edge containing a `Question` and its cursor. */
["QuestionEdge"]: AliasType<{
	/** The item at the end of the edge */
	node?:ResolverInputTypes["Question"],
	/** A cursor for use in pagination */
	cursor?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SolutionConnection"]: AliasType<{
	/** Pagination data for this connection. */
	pageInfo?:ResolverInputTypes["PageInfo"],
	/** Contains the nodes in this connection. */
	edges?:ResolverInputTypes["SolutionEdge"],
		__typename?: boolean | `@${string}`
}>;
	/** A Relay edge containing a `Solution` and its cursor. */
["SolutionEdge"]: AliasType<{
	/** The item at the end of the edge */
	node?:ResolverInputTypes["Solution"],
	/** A cursor for use in pagination */
	cursor?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SkillsBenchmarkConnection"]: AliasType<{
	/** Pagination data for this connection. */
	pageInfo?:ResolverInputTypes["PageInfo"],
	/** Contains the nodes in this connection. */
	edges?:ResolverInputTypes["SkillsBenchmarkEdge"],
		__typename?: boolean | `@${string}`
}>;
	/** A Relay edge containing a `SkillsBenchmark` and its cursor. */
["SkillsBenchmarkEdge"]: AliasType<{
	/** The item at the end of the edge */
	node?:ResolverInputTypes["SkillsBenchmark"],
	/** A cursor for use in pagination */
	cursor?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SkillsBenchmark"]: AliasType<{
	skill?:ResolverInputTypes["Skill"],
	testType?:boolean | `@${string}`,
	testLevel?:boolean | `@${string}`,
	median?:boolean | `@${string}`,
	mean?:boolean | `@${string}`,
	standardDeviation?:boolean | `@${string}`,
	count?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CurrentSubscription"]: AliasType<{
	plan?:ResolverInputTypes["SubscriptionPlan"],
	cancelAtPeriodEnd?:boolean | `@${string}`,
	currentPeriodStart?:boolean | `@${string}`,
	currentPeriodEnd?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionPlan"]: AliasType<{
	name?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AssessmentPreview"]: AliasType<{
	result?:ResolverInputTypes["AssessmentPreviewResult"],
		__typename?: boolean | `@${string}`
}>;
	["AssessmentPreviewResult"]: AliasType<{
	Assessment?:ResolverInputTypes["Assessment"],
	AssessmentPreviewError?:ResolverInputTypes["AssessmentPreviewError"],
		__typename?: boolean | `@${string}`
}>;
	["AssessmentPreviewError"]: AliasType<{
	value?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AtsJob"]: AliasType<{
	name?:boolean | `@${string}`,
	id?:boolean | `@${string}`,
	stages?:ResolverInputTypes["AtsStage"],
		__typename?: boolean | `@${string}`
}>;
	["AtsStage"]: AliasType<{
	name?:boolean | `@${string}`,
	id?:boolean | `@${string}`,
	index?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AssessmentConnection"]: AliasType<{
	/** Pagination data for this connection. */
	pageInfo?:ResolverInputTypes["PageInfo"],
	/** Contains the nodes in this connection. */
	edges?:ResolverInputTypes["AssessmentEdge"],
		__typename?: boolean | `@${string}`
}>;
	/** A Relay edge containing a `Assessment` and its cursor. */
["AssessmentEdge"]: AliasType<{
	/** The item at the end of the edge */
	node?:ResolverInputTypes["Assessment"],
	/** A cursor for use in pagination */
	cursor?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PersonalityTestResult"]: AliasType<{
	name?:boolean | `@${string}`,
	scales?:ResolverInputTypes["PersonalityTestScale"],
		__typename?: boolean | `@${string}`
}>;
	["PersonalityTestScale"]: AliasType<{
	name?:boolean | `@${string}`,
	scoreRange?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	comment?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** An enumeration. */
["ScoreRange"]:ScoreRange;
	["CandidateQuery"]: AliasType<{
solutionAttemptCandidate?: [{	token: string},ResolverInputTypes["SolutionAttemptCandidate"]],
solutionResults?: [{	id: ResolverInputTypes["UUID"]},ResolverInputTypes["SolutionResults"]],
assessment?: [{	id: ResolverInputTypes["UUID"]},ResolverInputTypes["CandidateAssessment"]],
solution?: [{	id: ResolverInputTypes["UUID"]},ResolverInputTypes["CandidateSolution"]],
candidateTestPreview?: [{	id: ResolverInputTypes["UUID"]},ResolverInputTypes["TestPreviewResult"]],
candidateSolutionPreview?: [{	/** Candidate's session token. */
	sessionToken: string},ResolverInputTypes["CandidateSolutionPreview"]],
		__typename?: boolean | `@${string}`
}>;
	["SolutionAttemptCandidate"]: AliasType<{
	firstName?:boolean | `@${string}`,
	lastName?:boolean | `@${string}`,
	email?:boolean | `@${string}`,
	canAttempt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SolutionResults"]: AliasType<{
	scales?:ResolverInputTypes["CandidateScaleResult"],
		__typename?: boolean | `@${string}`
}>;
	["CandidateScaleResult"]: AliasType<{
	name?:boolean | `@${string}`,
	comment?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CandidateAssessment"]: AliasType<{
	skills?:ResolverInputTypes["CandidateSkill"],
		__typename?: boolean | `@${string}`
}>;
	["CandidateSkill"]: AliasType<{
	name?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CandidateSolution"]: AliasType<{
	suggestedTime?:boolean | `@${string}`,
	testType?:boolean | `@${string}`,
	showComments?:boolean | `@${string}`,
	companyName?:boolean | `@${string}`,
	tests?:ResolverInputTypes["Test"],
		__typename?: boolean | `@${string}`
}>;
	["Test"]: AliasType<{
	availableTime?:boolean | `@${string}`,
	id?:boolean | `@${string}`,
	questions?:ResolverInputTypes["TestQuestion"],
	scorable?:boolean | `@${string}`,
	skillName?:boolean | `@${string}`,
	skillType?:boolean | `@${string}`,
	softTimeLimit?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TestQuestion"]: AliasType<{
	SingleChoiceQuestion?:ResolverInputTypes["SingleChoiceQuestion"],
	MultipleChoiceQuestion?:ResolverInputTypes["MultipleChoiceQuestion"],
	ClosedNumberQuestion?:ResolverInputTypes["ClosedNumberQuestion"],
	OpenTextQuestion?:ResolverInputTypes["OpenTextQuestion"],
	LikertQuestion?:ResolverInputTypes["LikertQuestion"],
	SituationalQuestion?:ResolverInputTypes["SituationalQuestion"],
		__typename?: boolean | `@${string}`
}>;
	["SingleChoiceQuestion"]: AliasType<{
	externalTool?:ResolverInputTypes["ExternalTool"],
	possibleAnswers?:ResolverInputTypes["PossibleAnswer"],
	uuid?:boolean | `@${string}`,
	text?:boolean | `@${string}`,
	image?:boolean | `@${string}`,
	answerOptional?:boolean | `@${string}`,
	changingWindowAllowed?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PossibleAnswer"]: AliasType<{
	uuid?:boolean | `@${string}`,
	text?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MultipleChoiceQuestion"]: AliasType<{
	externalTool?:ResolverInputTypes["ExternalTool"],
	possibleAnswers?:ResolverInputTypes["PossibleAnswer"],
	uuid?:boolean | `@${string}`,
	text?:boolean | `@${string}`,
	image?:boolean | `@${string}`,
	answerOptional?:boolean | `@${string}`,
	changingWindowAllowed?:boolean | `@${string}`,
	maxAnswers?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ClosedNumberQuestion"]: AliasType<{
	externalTool?:ResolverInputTypes["ExternalTool"],
	uuid?:boolean | `@${string}`,
	text?:boolean | `@${string}`,
	image?:boolean | `@${string}`,
	answerOptional?:boolean | `@${string}`,
	changingWindowAllowed?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OpenTextQuestion"]: AliasType<{
	externalTool?:ResolverInputTypes["ExternalTool"],
	uuid?:boolean | `@${string}`,
	text?:boolean | `@${string}`,
	image?:boolean | `@${string}`,
	answerOptional?:boolean | `@${string}`,
	changingWindowAllowed?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LikertQuestion"]: AliasType<{
	uuid?:boolean | `@${string}`,
	text?:boolean | `@${string}`,
	image?:boolean | `@${string}`,
	answerOptional?:boolean | `@${string}`,
	changingWindowAllowed?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SituationalQuestion"]: AliasType<{
	possibleAnswers?:ResolverInputTypes["PossibleAnswer"],
	uuid?:boolean | `@${string}`,
	text?:boolean | `@${string}`,
	image?:boolean | `@${string}`,
	answerOptional?:boolean | `@${string}`,
	changingWindowAllowed?:boolean | `@${string}`,
	maxAnswers?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TestPreviewResult"]: AliasType<{
	TestPreview?:ResolverInputTypes["TestPreview"],
	TestPreviewUnavailable?:ResolverInputTypes["TestPreviewUnavailable"],
		__typename?: boolean | `@${string}`
}>;
	["TestPreview"]: AliasType<{
	uuid?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	roleName?:boolean | `@${string}`,
	availableTime?:boolean | `@${string}`,
	numberOfQuestions?:boolean | `@${string}`,
	numberOfOpenQuestions?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	organizationName?:boolean | `@${string}`,
	branding?:ResolverInputTypes["AccountBranding"],
	requiresExternalTool?:boolean | `@${string}`,
	requiredExternalTools?:boolean | `@${string}`,
	locale?:boolean | `@${string}`,
	talentPool?:boolean | `@${string}`,
	testType?:boolean | `@${string}`,
	testLevel?:boolean | `@${string}`,
	competencies?:ResolverInputTypes["Skill"],
	createdAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TestPreviewUnavailable"]: AliasType<{
	/** Test preview unavailable. */
	reason?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CandidateSolutionPreview"]: AliasType<{
	assessment?:ResolverInputTypes["TestPreview"],
	solutionState?:boolean | `@${string}`,
	candidate?:ResolverInputTypes["CandidatePreview"],
	score?:boolean | `@${string}`,
	timeSpent?:boolean | `@${string}`,
	summary?:ResolverInputTypes["CandidateSummaryEntry"],
		__typename?: boolean | `@${string}`
}>;
	/** An enumeration. */
["SolutionState"]:SolutionState;
	["CandidatePreview"]: AliasType<{
	uuid?:boolean | `@${string}`,
	phoneNumber?:boolean | `@${string}`,
	firstName?:boolean | `@${string}`,
	lastName?:boolean | `@${string}`,
	email?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CandidateSummaryEntry"]: AliasType<{
	competency?:ResolverInputTypes["Competency"],
	numberOfQuestions?:boolean | `@${string}`,
	score?:boolean | `@${string}`,
	comment?:boolean | `@${string}`,
	label?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Mutation"]: AliasType<{
auth?: [{	email: string,	password: string},ResolverInputTypes["Auth"]],
heroTracking?: [{	input: ResolverInputTypes["TrackInput"]},ResolverInputTypes["HeroTracking"]],
	candidate?:ResolverInputTypes["CandidateMutation"],
	client?:ResolverInputTypes["ClientMutation"],
		__typename?: boolean | `@${string}`
}>;
	["Auth"]: AliasType<{
	authorizationToken?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["HeroTracking"]: AliasType<{
	result?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TrackInput"]: {
	eventName: string,
	properties: string
};
	["CandidateMutation"]: AliasType<{
talentPoolOptIn?: [{	solutionId: ResolverInputTypes["UUID"]},ResolverInputTypes["TalentPoolOptIn"]],
addCommentForQuestion?: [{	questionId: ResolverInputTypes["UUID"],	solutionId: ResolverInputTypes["UUID"],	text: string},ResolverInputTypes["AddCommentForQuestion"]],
submitSolution?: [{	solutionId: ResolverInputTypes["UUID"]},ResolverInputTypes["SubmitSolution"]],
reportAnomaly?: [{	responseId: ResolverInputTypes["UUID"],	type: ResolverInputTypes["AnomalyType"]},ResolverInputTypes["ReportAnomaly"]],
startSolution?: [{	candidateSessionToken: string},ResolverInputTypes["StartSolution"]],
submitLikertResponse?: [{	answer: ResolverInputTypes["LikertAnswer"],	id: ResolverInputTypes["UUID"]},ResolverInputTypes["SubmitLikertResponse"]],
submitTest?: [{	input: ResolverInputTypes["SubmitTestInput"]},ResolverInputTypes["SubmitTest"]],
registerCandidate?: [{	assessmentId: ResolverInputTypes["UUID"],	email: string,	firstName: string},ResolverInputTypes["RegisterCandidate"]],
issue2faToken?: [{	phoneNumber: string,	testId: ResolverInputTypes["UUID"]},ResolverInputTypes["Issue2FAToken"]],
verify2faToken?: [{	externalId?: string | undefined | null,	jwt?: string | undefined | null,	phoneNumber: string,	testId: ResolverInputTypes["UUID"],	verificationToken: string},ResolverInputTypes["Verify2FAToken"]],
registerVerifiedCandidate?: [{	email: string,	firstName: string,	intermediateToken: string,	lastName: string},ResolverInputTypes["RegisterVerifiedCandidate"]],
startTest?: [{	input: ResolverInputTypes["StartTestInput"]},ResolverInputTypes["StartTest"]],
seeQuestion?: [{	input: ResolverInputTypes["SeeQuestionInput"]},ResolverInputTypes["SeeQuestion"]],
submitResponse?: [{	input: ResolverInputTypes["SubmitResponseInput"]},ResolverInputTypes["SubmitResponse"]],
submitSituationalResponse?: [{	input: ResolverInputTypes["SubmitSituationalResponseInput"]},ResolverInputTypes["SubmitSituationalResponse"]],
skipQuestion?: [{	input: ResolverInputTypes["SkipQuestionInput"]},ResolverInputTypes["SkipQuestion"]],
giveFeedback?: [{	/** Candidate's session token. */
	candidateSessionToken: string,	/** Feedback score given by a candidate. */
	feedbackScore?: ResolverInputTypes["Decimal"] | undefined | null,	/** Feedback score type given by a candidate. */
	feedbackScoreVersion?: string | undefined | null,	/** Text feedback given by a candidate. */
	feedbackText?: string | undefined | null},ResolverInputTypes["CandidateGiveFeedback"]],
		__typename?: boolean | `@${string}`
}>;
	["TalentPoolOptIn"]: AliasType<{
	result?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AddCommentForQuestion"]: AliasType<{
	result?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubmitSolution"]: AliasType<{
	result?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ReportAnomaly"]: AliasType<{
	result?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** An enumeration. */
["AnomalyType"]:AnomalyType;
	["StartSolution"]: AliasType<{
	result?:ResolverInputTypes["SolutionAttempt"],
		__typename?: boolean | `@${string}`
}>;
	["SolutionAttempt"]: AliasType<{
	suggestedTime?:boolean | `@${string}`,
	testType?:boolean | `@${string}`,
	showComments?:boolean | `@${string}`,
	companyName?:boolean | `@${string}`,
	tests?:ResolverInputTypes["Test"],
	solutionId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubmitLikertResponse"]: AliasType<{
	result?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** An enumeration. */
["LikertAnswer"]:LikertAnswer;
	["SubmitTest"]: AliasType<{
	result?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubmitTestInput"]: {
	candidateSessionToken: string,
	testId: number
};
	["RegisterCandidate"]: AliasType<{
	sessionToken?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Issue2FAToken"]: AliasType<{
	result?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Verify2FAToken"]: AliasType<{
	intermediateToken?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RegisterVerifiedCandidate"]: AliasType<{
	candidateSessionToken?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StartTest"]: AliasType<{
	result?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StartTestInput"]: {
	candidateSessionToken: string,
	testId: number
};
	["SeeQuestion"]: AliasType<{
	result?:boolean | `@${string}`,
	responseId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SeeQuestionInput"]: {
	candidateSessionToken: string,
	questionId: ResolverInputTypes["UUID"],
	testId: number
};
	["SubmitResponse"]: AliasType<{
	result?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubmitResponseInput"]: {
	candidateSessionToken: string,
	questionId: ResolverInputTypes["UUID"],
	testId: number,
	answersGiven: Array<ResolverInputTypes["AnswerGivenInput"]>
};
	["AnswerGivenInput"]: {
	text?: string | undefined | null,
	answerId?: ResolverInputTypes["UUID"] | undefined | null
};
	["SubmitSituationalResponse"]: AliasType<{
	result?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubmitSituationalResponseInput"]: {
	candidateSessionToken: string,
	questionId: ResolverInputTypes["UUID"],
	testId: number,
	answersGiven: Array<ResolverInputTypes["SituationalAnswerGivenInput"]>
};
	["SituationalAnswerGivenInput"]: {
	answerId?: ResolverInputTypes["UUID"] | undefined | null,
	value?: number | undefined | null
};
	["SkipQuestion"]: AliasType<{
	result?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SkipQuestionInput"]: {
	candidateSessionToken: string,
	questionId: ResolverInputTypes["UUID"],
	testId: number
};
	["CandidateGiveFeedback"]: AliasType<{
	result?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ClientMutation"]: AliasType<{
startCheckoutSession?: [{	cancelUrl: string,	priceLookupKey: string,	successUrl: string},ResolverInputTypes["StartCheckoutSession"]],
	cancelSubscription?:ResolverInputTypes["CancelSubscription"],
createAssessment?: [{	description?: string | undefined | null,	existingOpenQuestions: Array<ResolverInputTypes["UUID"] | undefined | null>,	locale: ResolverInputTypes["Locale"],	newOpenQuestions: Array<string | undefined | null>,	newOpenQuestionsAiGenerated?: Array<string | undefined | null> | undefined | null,	roleName: string,	seniority: ResolverInputTypes["Seniority"],	skillsUuids: Array<ResolverInputTypes["UUID"] | undefined | null>,	testType: ResolverInputTypes["TestType"]},ResolverInputTypes["CreateAssessment"]],
signUp?: [{	email: string,	locale: ResolverInputTypes["Locale"]},ResolverInputTypes["SignUp"]],
submitOnboardingSurvey?: [{	survey: ResolverInputTypes["JSONString"]},ResolverInputTypes["SubmitOnboardingSurvey"]],
submitOnboardingAccountDetails?: [{	companyName: string,	firstName: string,	lastName: string,	position: ResolverInputTypes["Role"]},ResolverInputTypes["SubmitOnboardingAccountDetails"]],
	visitOnboarding?:ResolverInputTypes["VisitOnboarding"],
sendEmail?: [{	body: string,	solutionIds: Array<ResolverInputTypes["UUID"] | undefined | null>,	subject: string},ResolverInputTypes["SendEmail"]],
changeNotificationFrequency?: [{	assessmentId: ResolverInputTypes["UUID"],	notificationFrequency: ResolverInputTypes["NotificationFrequency"]},ResolverInputTypes["ChangeNotificationFrequency"]],
solutionToken?: [{	solutionUuid: ResolverInputTypes["UUID"]},ResolverInputTypes["SolutionToken"]],
changeAtsAssociationSettings?: [{	assessmentUuid: ResolverInputTypes["UUID"],	emailSubject: string,	emailText: string,	jobId: string,	stageId: string},ResolverInputTypes["ChangeAtsAssociationSettings"]],
deleteAtsAssociation?: [{	assessmentUuid: ResolverInputTypes["UUID"]},ResolverInputTypes["DeleteAtsAssociation"]],
earlyAccessSignup?: [{	email: string,	firstName: string,	lastName: string,	locale: ResolverInputTypes["Locale"],	phone: string},ResolverInputTypes["EarlyAccessSignup"]],
setPassword?: [{	password: string,	resetToken: string},ResolverInputTypes["SetPassword"]],
sendResetPassword?: [{	resetPassword: ResolverInputTypes["SendResetPasswordInput"]},ResolverInputTypes["SendResetPassword"]],
archiveTest?: [{	testId: ResolverInputTypes["UUID"]},ResolverInputTypes["ArchiveTest"]],
unarchiveTest?: [{	testId: ResolverInputTypes["UUID"]},ResolverInputTypes["UnarchiveTest"]],
deleteTest?: [{	testId: ResolverInputTypes["UUID"]},ResolverInputTypes["DeleteTest"]],
updateCandidatesStatus?: [{	input: ResolverInputTypes["UpdateCandidatesStatusInput"]},ResolverInputTypes["UpdateCandidatesStatus"]],
updateBranding?: [{	input: ResolverInputTypes["BrandingInput"]},ResolverInputTypes["UpdateBranding"]],
seeSolution?: [{	seeSolution: ResolverInputTypes["SeeSolution"]},ResolverInputTypes["ClientSeeSolution"]],
		__typename?: boolean | `@${string}`
}>;
	["StartCheckoutSession"]: AliasType<{
	result?:ResolverInputTypes["StartCheckoutSessionResult"],
		__typename?: boolean | `@${string}`
}>;
	["StartCheckoutSessionResult"]: AliasType<{
	url?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CancelSubscription"]: AliasType<{
	result?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CreateAssessment"]: AliasType<{
	result?:ResolverInputTypes["Assessment"],
		__typename?: boolean | `@${string}`
}>;
	["SignUp"]: AliasType<{
	result?:ResolverInputTypes["SignupResult"],
		__typename?: boolean | `@${string}`
}>;
	["SignupResult"]: AliasType<{
	SignupResultSuccess?:ResolverInputTypes["SignupResultSuccess"],
	SignupResultError?:ResolverInputTypes["SignupResultError"],
		__typename?: boolean | `@${string}`
}>;
	["SignupResultSuccess"]: AliasType<{
	auth?:boolean | `@${string}`,
	changePasswordToken?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SignupResultError"]: AliasType<{
	value?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubmitOnboardingSurvey"]: AliasType<{
	result?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** Allows use of a JSON String for input / output from the GraphQL schema.

Use of this type is *not recommended* as you lose the benefits of having a defined, static
schema (one of the key benefits of GraphQL). */
["JSONString"]:unknown;
	["SubmitOnboardingAccountDetails"]: AliasType<{
	result?:ResolverInputTypes["SubmitOnboardingAccountDetailsResult"],
		__typename?: boolean | `@${string}`
}>;
	["SubmitOnboardingAccountDetailsResult"]: AliasType<{
	SubmitOnboardingAccountDetailsSuccess?:ResolverInputTypes["SubmitOnboardingAccountDetailsSuccess"],
	SiubmitOnboardingAccountDetailsError?:ResolverInputTypes["SiubmitOnboardingAccountDetailsError"],
		__typename?: boolean | `@${string}`
}>;
	["SubmitOnboardingAccountDetailsSuccess"]: AliasType<{
	result?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SiubmitOnboardingAccountDetailsError"]: AliasType<{
	error?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["VisitOnboarding"]: AliasType<{
	result?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SendEmail"]: AliasType<{
	result?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ChangeNotificationFrequency"]: AliasType<{
	result?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SolutionToken"]: AliasType<{
	result?:ResolverInputTypes["SolutionTokenResult"],
		__typename?: boolean | `@${string}`
}>;
	["SolutionTokenResult"]: AliasType<{
	SolutionTokenResultSuccess?:ResolverInputTypes["SolutionTokenResultSuccess"],
	SolutionTokenResultError?:ResolverInputTypes["SolutionTokenResultError"],
		__typename?: boolean | `@${string}`
}>;
	["SolutionTokenResultSuccess"]: AliasType<{
	token?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SolutionTokenResultError"]: AliasType<{
	error?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ChangeAtsAssociationSettings"]: AliasType<{
	result?:ResolverInputTypes["ChangeAtsAssociationSettingsUnion"],
		__typename?: boolean | `@${string}`
}>;
	["ChangeAtsAssociationSettingsUnion"]: AliasType<{
	ChangeAtsAssociationSettingsSuccess?:ResolverInputTypes["ChangeAtsAssociationSettingsSuccess"],
	ChangeAtsAssociationSettingsError?:ResolverInputTypes["ChangeAtsAssociationSettingsError"],
		__typename?: boolean | `@${string}`
}>;
	["ChangeAtsAssociationSettingsSuccess"]: AliasType<{
	result?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ChangeAtsAssociationSettingsError"]: AliasType<{
	result?:boolean | `@${string}`,
	error?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DeleteAtsAssociation"]: AliasType<{
	result?:ResolverInputTypes["DeleteAtsAssociationUnion"],
		__typename?: boolean | `@${string}`
}>;
	["DeleteAtsAssociationUnion"]: AliasType<{
	DeleteAtsAssociationError?:ResolverInputTypes["DeleteAtsAssociationError"],
	DeleteAtsAssociationSuccess?:ResolverInputTypes["DeleteAtsAssociationSuccess"],
		__typename?: boolean | `@${string}`
}>;
	["DeleteAtsAssociationError"]: AliasType<{
	result?:boolean | `@${string}`,
	error?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DeleteAtsAssociationSuccess"]: AliasType<{
	result?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EarlyAccessSignup"]: AliasType<{
	result?:ResolverInputTypes["EarlyAccessSignupResult"],
		__typename?: boolean | `@${string}`
}>;
	["EarlyAccessSignupResult"]: AliasType<{
	status?:boolean | `@${string}`,
	errors?:ResolverInputTypes["Error"],
		__typename?: boolean | `@${string}`
}>;
	["Error"]: AliasType<{
	name?:boolean | `@${string}`,
	message?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SetPassword"]: AliasType<{
	authorizationToken?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SendResetPassword"]: AliasType<{
	Result?:boolean | `@${string}`,
	result?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SendResetPasswordInput"]: {
	email: string
};
	["ArchiveTest"]: AliasType<{
	result?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UnarchiveTest"]: AliasType<{
	result?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DeleteTest"]: AliasType<{
	result?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UpdateCandidatesStatus"]: AliasType<{
	candidates?:ResolverInputTypes["SolutionCandidate"],
		__typename?: boolean | `@${string}`
}>;
	["UpdateCandidatesStatusInput"]: {
	candidateIds: Array<ResolverInputTypes["UUID"] | undefined | null>,
	status: ResolverInputTypes["CandidateStatusInput"]
};
	/** An enumeration. */
["CandidateStatusInput"]:CandidateStatusInput;
	["UpdateBranding"]: AliasType<{
	result?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BrandingInput"]: {
	logo?: ResolverInputTypes["Upload"] | undefined | null,
	image?: ResolverInputTypes["Upload"] | undefined | null,
	primaryColor: string,
	secondaryColor?: string | undefined | null,
	headline: string,
	description: string,
	removeLogo?: boolean | undefined | null,
	removeImage?: boolean | undefined | null
};
	/** Create scalar that ignores normal serialization/deserialization, since
that will be handled by the multipart request spec */
["Upload"]:unknown;
	["ClientSeeSolution"]: AliasType<{
	result?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SeeSolution"]: {
	solutionId: ResolverInputTypes["UUID"]
}
  }

export type ModelTypes = {
    ["Query"]: {
		_debug?: ModelTypes["DjangoDebug"] | undefined,
	me?: ModelTypes["User"] | undefined,
	supportedSkills?: ModelTypes["SkillConnection"] | undefined,
	suggestedSkills?: ModelTypes["SkillConnection"] | undefined,
	solutionResponses?: ModelTypes["SolutionResponses"] | undefined,
	solutionResponsesWithToken?: ModelTypes["SolutionResponses"] | undefined,
	client?: ModelTypes["ClientQuery"] | undefined,
	candidate?: ModelTypes["CandidateQuery"] | undefined
};
	/** Debugging information for the current query. */
["DjangoDebug"]: {
		/** Executed SQL queries for this API query. */
	sql?: Array<ModelTypes["DjangoDebugSQL"] | undefined> | undefined
};
	/** Represents a single database query made to a Django managed DB. */
["DjangoDebugSQL"]: {
		/** The type of database being used (e.g. postrgesql, mysql, sqlite). */
	vendor: string,
	/** The Django database alias (e.g. 'default'). */
	alias: string,
	/** The actual SQL sent to this database. */
	sql?: string | undefined,
	/** Duration of this database query in seconds. */
	duration: number,
	/** The raw SQL of this query, without params. */
	rawSql: string,
	/** JSON encoded database query parameters. */
	params: string,
	/** Start time of this database query. */
	startTime: number,
	/** Stop time of this database query. */
	stopTime: number,
	/** Whether this database query took more than 10 seconds. */
	isSlow: boolean,
	/** Whether this database query was a SELECT. */
	isSelect: boolean,
	/** Postgres transaction ID if available. */
	transId?: string | undefined,
	/** Postgres transaction status if available. */
	transStatus?: string | undefined,
	/** Postgres isolation level if available. */
	isoLevel?: string | undefined,
	/** Postgres connection encoding if available. */
	encoding?: string | undefined
};
	["User"]: {
		uuid: ModelTypes["UUID"],
	email: string,
	firstName: string,
	lastName: string,
	locale: ModelTypes["UserLocale"],
	role?: string | undefined,
	createdAt: ModelTypes["DateTime"],
	updatedAt: ModelTypes["DateTime"],
	account: ModelTypes["Account"],
	lastSeenAt?: ModelTypes["DateTime"] | undefined,
	phone?: string | undefined,
	active?: boolean | undefined,
	admin?: boolean | undefined,
	accountLocale?: ModelTypes["Locale"] | undefined,
	onboardingCompleted?: boolean | undefined,
	expiresOn?: ModelTypes["DateTime"] | undefined,
	emailConfirmed?: boolean | undefined,
	features: Array<ModelTypes["Feature"]>
};
	/** Leverages the internal Python implmeentation of UUID (uuid.UUID) to provide native UUID objects
in fields, resolvers and input. */
["UUID"]:any;
	["UserLocale"]:UserLocale;
	/** The `DateTime` scalar type represents a DateTime
value as specified by
[iso8601](https://en.wikipedia.org/wiki/ISO_8601). */
["DateTime"]:any;
	["Account"]: {
		id: string,
	name?: string | undefined,
	expiresOn?: ModelTypes["DateTime"] | undefined,
	locale: ModelTypes["AccountLocale"],
	billingModel: ModelTypes["AccountBillingModel"],
	tokensRemaining: number,
	slots: number,
	brandingEnabled: boolean,
	hasSubscription: boolean,
	verify: boolean,
	subscription?: ModelTypes["Subscription"] | undefined,
	slotsInUse: number
};
	["AccountLocale"]:AccountLocale;
	["AccountBillingModel"]:AccountBillingModel;
	["Subscription"]: {
		status: ModelTypes["SubscriptionState"]
};
	["SubscriptionState"]:SubscriptionState;
	["Locale"]:Locale;
	["Feature"]:Feature;
	["SkillConnection"]: {
		/** Pagination data for this connection. */
	pageInfo: ModelTypes["PageInfo"],
	/** Contains the nodes in this connection. */
	edges: Array<ModelTypes["SkillEdge"] | undefined>
};
	/** The Relay compliant `PageInfo` type, containing data necessary to paginate this connection. */
["PageInfo"]: {
		/** When paginating forwards, are there more items? */
	hasNextPage: boolean,
	/** When paginating backwards, are there more items? */
	hasPreviousPage: boolean,
	/** When paginating backwards, the cursor to continue. */
	startCursor?: string | undefined,
	/** When paginating forwards, the cursor to continue. */
	endCursor?: string | undefined
};
	/** A Relay edge containing a `Skill` and its cursor. */
["SkillEdge"]: {
		/** The item at the end of the edge */
	node?: ModelTypes["Competency"] | undefined,
	/** A cursor for use in pagination */
	cursor: string
};
	["Competency"]: {
		uuid: ModelTypes["UUID"],
	name: string,
	domain: string,
	icon: ModelTypes["Icon"],
	description: string,
	skillType: ModelTypes["SkillType"],
	testDuration: number
};
	["Icon"]:Icon;
	["SkillType"]:SkillType;
	["PositionInput"]: {
	jobTitle: string,
	seniority?: string | undefined,
	testLevel: ModelTypes["TestLevel"],
	testType: ModelTypes["TestType"],
	description?: string | undefined,
	locale?: ModelTypes["Locale"] | undefined
};
	["TestLevel"]:TestLevel;
	["TestType"]:TestType;
	["SolutionResponses"]:ModelTypes["SolutionResponsesSuccess"] | ModelTypes["SolutionResponsesError"];
	["SolutionResponsesSuccess"]: {
		tests: Array<ModelTypes["TestSolution"]>
};
	["TestSolution"]: {
		id: string,
	solution?: ModelTypes["Solution"] | undefined,
	startedAt: ModelTypes["DateTime"],
	submittedAt?: ModelTypes["DateTime"] | undefined,
	score?: ModelTypes["Decimal"] | undefined,
	responses: Array<ModelTypes["SolutionTestResponse"]>,
	skillName: string
};
	["Solution"]: {
		candidate: ModelTypes["SolutionCandidate"],
	uuid: ModelTypes["UUID"],
	createdAt: ModelTypes["DateTime"],
	updatedAt: ModelTypes["DateTime"],
	seenAt?: ModelTypes["DateTime"] | undefined,
	submittedAt: ModelTypes["DateTime"],
	testRoleName: string,
	starred: boolean,
	solutionState: string,
	score: ModelTypes["Decimal"],
	timeSpent: number,
	personalityFit?: ModelTypes["PersonalityFit"] | undefined,
	skillSummaries: Array<ModelTypes["SkillSummary"] | undefined>,
	summary?: Array<ModelTypes["SummaryEntry"] | undefined> | undefined,
	notificationSentAt?: ModelTypes["DateTime"] | undefined,
	notificationsFrequency: ModelTypes["NotificationFrequency"],
	assessment: ModelTypes["Assessment"],
	openQuestionsResponses: Array<ModelTypes["Response"] | undefined>,
	situationalSummaries: Array<ModelTypes["SituationalSummary"] | undefined>,
	hideMedians: boolean
};
	["SolutionCandidate"]: {
		firstName: string,
	lastName: string,
	email: string,
	uuid: ModelTypes["UUID"],
	phoneNumber?: string | undefined,
	status: ModelTypes["CandidateStatus"]
};
	["CandidateStatus"]:CandidateStatus;
	/** The `Decimal` scalar type represents a python Decimal. */
["Decimal"]:any;
	["PersonalityFit"]:PersonalityFit;
	["SkillSummary"]: {
		skillUuid: ModelTypes["UUID"],
	name: string,
	score: ModelTypes["Decimal"],
	availableTime: number,
	timeSpent: number,
	areas: Array<ModelTypes["Area"] | undefined>,
	activities: Array<ModelTypes["JobActivity"] | undefined>,
	comment: string,
	detailsIncluded: boolean,
	suggestedQuestions: Array<string | undefined>,
	questionsAnswered: number,
	questionsTotal: number
};
	["Area"]: {
		name: string,
	score: ModelTypes["Decimal"],
	scoreRange?: ModelTypes["AreaScoreRange"] | undefined,
	comment?: string | undefined,
	explanation?: string | undefined
};
	["AreaScoreRange"]:AreaScoreRange;
	["JobActivity"]: {
		name: string
};
	["SummaryEntry"]: {
		competency: ModelTypes["Competency"],
	score: ModelTypes["Decimal"],
	availableTime: number,
	timeSpent: number,
	totalQuestionsCount: number,
	answeredQuestionsCount: number,
	comment?: string | undefined
};
	["NotificationFrequency"]:NotificationFrequency;
	["Assessment"]: {
		uuid: ModelTypes["UUID"],
	title?: string | undefined,
	roleName: string,
	availableTime: number,
	numberOfQuestions: number,
	numberOfOpenQuestions: number,
	organizationName: string,
	requiresExternalTool: boolean,
	requiredExternalTools: Array<ModelTypes["ExternalToolKind"] | undefined>,
	description?: string | undefined,
	competencies: Array<ModelTypes["Competency"] | undefined>,
	locale: ModelTypes["Locale"],
	talentPool: boolean,
	testType: ModelTypes["TestType"],
	testLevel: ModelTypes["TestLevel"],
	state: ModelTypes["TestState"],
	notificationFrequency: ModelTypes["NotificationFrequency"],
	createdAt: ModelTypes["DateTime"],
	seniority: ModelTypes["Seniority"],
	solutionsDistribution: Array<ModelTypes["Bin"] | undefined>,
	averageSkillSummary: Array<ModelTypes["SummarySkillEntry"] | undefined>,
	atsAssociation?: ModelTypes["AtsAssociation"] | undefined,
	completedBy: number,
	mostRecentActivityAt?: ModelTypes["DateTime"] | undefined,
	mostRecentActivitySecondsAgo?: number | undefined
};
	["ExternalToolKind"]:ExternalToolKind;
	["TestState"]:TestState;
	["Seniority"]:Seniority;
	["Bin"]: {
		label: string,
	value: number,
	selected?: boolean | undefined
};
	["SummarySkillEntry"]: {
		skill: ModelTypes["Skill"],
	score: number
};
	["Skill"]: {
		uuid: ModelTypes["UUID"],
	name: string,
	domain: string,
	icon: ModelTypes["Icon"],
	description: string,
	type: ModelTypes["SkillType"]
};
	["AtsAssociation"]: {
		integration: ModelTypes["AtsIntegration"],
	assessment: ModelTypes["Assessment"],
	jobId: string,
	stageId: string,
	emailText: string,
	emailSubject: string,
	lastChangedAt?: ModelTypes["DateTime"] | undefined
};
	["AtsIntegration"]: {
		account?: ModelTypes["Account"] | undefined,
	remoteId: string
};
	["Response"]: {
		uuid?: ModelTypes["UUID"] | undefined,
	question?: ModelTypes["Question"] | undefined,
	score?: ModelTypes["Decimal"] | undefined,
	scored?: boolean | undefined,
	outdated?: boolean | undefined,
	answersGiven?: Array<ModelTypes["AnswerGiven"] | undefined> | undefined
};
	["Question"]: {
		uuid: ModelTypes["UUID"],
	competencyName: string,
	skill?: ModelTypes["Skill"] | undefined,
	custom: boolean,
	questionType: ModelTypes["QuestionTypes"],
	answers: Array<ModelTypes["Answer"] | undefined>,
	content: string,
	appendix?: string | undefined,
	availableTime: number,
	questionStatus: ModelTypes["QuestionTypes"],
	requiresExternalTool: string,
	aiGenerated: boolean
};
	["QuestionTypes"]:QuestionTypes;
	["Answer"]: {
		uuid?: ModelTypes["UUID"] | undefined,
	correct?: boolean | undefined,
	content?: string | undefined
};
	["AnswerGiven"]: {
		uuid?: ModelTypes["UUID"] | undefined,
	answer?: ModelTypes["Answer"] | undefined,
	correct?: boolean | undefined,
	userSelected?: boolean | undefined,
	content?: string | undefined
};
	["SituationalSummary"]: {
		skillUuid: ModelTypes["UUID"],
	name: string,
	dimensions: Array<ModelTypes["DimensionSummary"] | undefined>
};
	["DimensionSummary"]: {
		skillUuid: ModelTypes["UUID"],
	name: string,
	score: ModelTypes["Decimal"],
	distribution: Array<ModelTypes["Bin"]>,
	comment: string,
	scoreLabel: string,
	dimensionComment: string
};
	["SolutionTestResponse"]: {
		question: ModelTypes["SolutionTestQuestion"],
	label: ModelTypes["ResponseLabel"],
	answerGiven?: string | undefined
};
	["SolutionTestQuestion"]: {
		answers?: Array<ModelTypes["SolutionTestAnswer"] | undefined> | undefined,
	content: string,
	externalTool?: ModelTypes["ExternalTool"] | undefined,
	image?: string | undefined
};
	["SolutionTestAnswer"]: {
		content: string
};
	["ExternalTool"]: {
		instruction: string,
	url: string,
	name: string
};
	["ResponseLabel"]:ResponseLabel;
	["SolutionResponsesError"]: {
		error?: string | undefined
};
	["SolutionResponsesInput"]: {
	solutionId: ModelTypes["UUID"]
};
	["ClientQuery"]: {
		solution?: ModelTypes["SolutionUnion"] | undefined,
	test?: ModelTypes["TestAssessment"] | undefined,
	branding?: ModelTypes["AccountBranding"] | undefined,
	solutionWithToken?: ModelTypes["SolutionUnion"] | undefined,
	productPlan?: ModelTypes["ProductPlan"] | undefined,
	suggestedOpenQuestionsConnection?: ModelTypes["QuestionConnection"] | undefined,
	suggestedOpenAiQuestionsConnection?: ModelTypes["QuestionConnection"] | undefined,
	solutionConnection?: ModelTypes["SolutionConnection"] | undefined,
	skillsBenchmark?: ModelTypes["SkillsBenchmarkConnection"] | undefined,
	currentSubscription?: ModelTypes["CurrentSubscription"] | undefined,
	assessmentPreview?: ModelTypes["AssessmentPreview"] | undefined,
	solutionResponses?: ModelTypes["SolutionResponses"] | undefined,
	solutionResponsesWithToken?: ModelTypes["SolutionResponses"] | undefined,
	atsAssociationOptions?: Array<ModelTypes["AtsJob"] | undefined> | undefined,
	assessment?: ModelTypes["Assessment"] | undefined,
	assessmentConnection?: ModelTypes["AssessmentConnection"] | undefined,
	personalityTestResult?: ModelTypes["PersonalityTestResult"] | undefined
};
	["SolutionUnion"]:ModelTypes["Solution"] | ModelTypes["SolutionError"];
	["SolutionError"]: {
		error?: string | undefined
};
	["TestAssessment"]: {
		uuid: ModelTypes["UUID"],
	title?: string | undefined,
	roleName: string,
	availableTime: number,
	numberOfQuestions: number,
	numberOfOpenQuestions: number,
	description?: string | undefined,
	organizationName: string,
	branding?: ModelTypes["AccountBranding"] | undefined,
	requiresExternalTool: boolean,
	requiredExternalTools: Array<ModelTypes["ExternalToolKind"] | undefined>,
	locale: ModelTypes["Locale"],
	talentPool: boolean,
	testType: ModelTypes["TestType"],
	testLevel: ModelTypes["TestLevel"],
	competencies: Array<ModelTypes["Skill"] | undefined>,
	createdAt: ModelTypes["DateTime"],
	expiresAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	mostRecentActivityAt?: ModelTypes["DateTime"] | undefined,
	mostRecentActivitySecondsAgo?: number | undefined,
	disabledAt?: ModelTypes["DateTime"] | undefined,
	typeformTestId?: string | undefined,
	seniority: ModelTypes["Seniority"],
	testState: ModelTypes["TestState"],
	/** Number of submitted solutions to the test */
	completedBy: number,
	/** Average time in seconds spent on solutions */
	averageSolutionTime?: number | undefined,
	/** Average score of solutions */
	averageSolutionScore?: ModelTypes["Decimal"] | undefined,
	/** Owner of the test (HR user / organization) */
	assignedTo?: ModelTypes["BaseUser"] | undefined,
	solutionsDistribution: Array<ModelTypes["Bin"] | undefined>,
	experimental?: boolean | undefined,
	questions?: Array<ModelTypes["Question"] | undefined> | undefined,
	skills?: Array<ModelTypes["Skill"] | undefined> | undefined,
	averageSummary?: Array<ModelTypes["TestAssessmentSummary"] | undefined> | undefined,
	solutionUuids?: Array<ModelTypes["UUID"] | undefined> | undefined
};
	["AccountBranding"]: {
		uuid: ModelTypes["UUID"],
	logo?: string | undefined,
	image?: string | undefined,
	primaryColor: string,
	secondaryColor?: string | undefined,
	headline: string,
	description: string
};
	["BaseUser"]: {
		uuid: ModelTypes["UUID"],
	firstName: string,
	lastName: string,
	organizationName?: string | undefined,
	email: string,
	isAdmin: boolean,
	locale: ModelTypes["Locale"],
	accountId: number,
	role?: ModelTypes["Role"] | undefined,
	logUrl: string,
	accountLogUrl: string
};
	["Role"]:Role;
	["TestAssessmentSummary"]: {
		competency: ModelTypes["SummaryCompetency"],
	numberOfQuestions: number,
	score: ModelTypes["Decimal"]
};
	["SummaryCompetency"]: {
		uuid: ModelTypes["UUID"],
	name: string
};
	["ProductPlan"]: {
		maxNumberOfSkills: number,
	maxNumberOfOpenQuestions: number
};
	["QuestionConnection"]: {
		/** Pagination data for this connection. */
	pageInfo: ModelTypes["PageInfo"],
	/** Contains the nodes in this connection. */
	edges: Array<ModelTypes["QuestionEdge"] | undefined>
};
	/** A Relay edge containing a `Question` and its cursor. */
["QuestionEdge"]: {
		/** The item at the end of the edge */
	node?: ModelTypes["Question"] | undefined,
	/** A cursor for use in pagination */
	cursor: string
};
	["SolutionConnection"]: {
		/** Pagination data for this connection. */
	pageInfo: ModelTypes["PageInfo"],
	/** Contains the nodes in this connection. */
	edges: Array<ModelTypes["SolutionEdge"] | undefined>
};
	/** A Relay edge containing a `Solution` and its cursor. */
["SolutionEdge"]: {
		/** The item at the end of the edge */
	node?: ModelTypes["Solution"] | undefined,
	/** A cursor for use in pagination */
	cursor: string
};
	["SkillsBenchmarkConnection"]: {
		/** Pagination data for this connection. */
	pageInfo: ModelTypes["PageInfo"],
	/** Contains the nodes in this connection. */
	edges: Array<ModelTypes["SkillsBenchmarkEdge"] | undefined>
};
	/** A Relay edge containing a `SkillsBenchmark` and its cursor. */
["SkillsBenchmarkEdge"]: {
		/** The item at the end of the edge */
	node?: ModelTypes["SkillsBenchmark"] | undefined,
	/** A cursor for use in pagination */
	cursor: string
};
	["SkillsBenchmark"]: {
		skill?: ModelTypes["Skill"] | undefined,
	testType: ModelTypes["TestType"],
	testLevel: ModelTypes["TestLevel"],
	median?: number | undefined,
	mean?: number | undefined,
	standardDeviation?: number | undefined,
	count?: number | undefined
};
	["CurrentSubscription"]: {
		plan: ModelTypes["SubscriptionPlan"],
	cancelAtPeriodEnd: boolean,
	currentPeriodStart: ModelTypes["DateTime"],
	currentPeriodEnd: ModelTypes["DateTime"],
	status: ModelTypes["SubscriptionState"]
};
	["SubscriptionPlan"]: {
		name: string
};
	["AssessmentPreview"]: {
		result: ModelTypes["AssessmentPreviewResult"]
};
	["AssessmentPreviewResult"]:ModelTypes["Assessment"] | ModelTypes["AssessmentPreviewError"];
	["AssessmentPreviewError"]: {
		value?: string | undefined
};
	["AtsJob"]: {
		name: string,
	id: string,
	stages?: Array<ModelTypes["AtsStage"] | undefined> | undefined
};
	["AtsStage"]: {
		name: string,
	id: string,
	index: number
};
	["AssessmentConnection"]: {
		/** Pagination data for this connection. */
	pageInfo: ModelTypes["PageInfo"],
	/** Contains the nodes in this connection. */
	edges: Array<ModelTypes["AssessmentEdge"] | undefined>
};
	/** A Relay edge containing a `Assessment` and its cursor. */
["AssessmentEdge"]: {
		/** The item at the end of the edge */
	node?: ModelTypes["Assessment"] | undefined,
	/** A cursor for use in pagination */
	cursor: string
};
	["PersonalityTestResult"]: {
		name: string,
	scales: Array<ModelTypes["PersonalityTestScale"]>
};
	["PersonalityTestScale"]: {
		name: string,
	scoreRange: ModelTypes["ScoreRange"],
	description: string,
	comment: string
};
	["ScoreRange"]:ScoreRange;
	["CandidateQuery"]: {
		solutionAttemptCandidate?: ModelTypes["SolutionAttemptCandidate"] | undefined,
	solutionResults?: ModelTypes["SolutionResults"] | undefined,
	assessment?: ModelTypes["CandidateAssessment"] | undefined,
	solution?: ModelTypes["CandidateSolution"] | undefined,
	candidateTestPreview?: ModelTypes["TestPreviewResult"] | undefined,
	/** Preview Solution details with a session token as a candidate. */
	candidateSolutionPreview?: ModelTypes["CandidateSolutionPreview"] | undefined
};
	["SolutionAttemptCandidate"]: {
		firstName: string,
	lastName: string,
	email: string,
	canAttempt: boolean
};
	["SolutionResults"]: {
		scales: Array<ModelTypes["CandidateScaleResult"] | undefined>
};
	["CandidateScaleResult"]: {
		name: string,
	comment: string
};
	["CandidateAssessment"]: {
		skills: Array<ModelTypes["CandidateSkill"]>
};
	["CandidateSkill"]: {
		name: string,
	type: ModelTypes["SkillType"]
};
	["CandidateSolution"]: {
		suggestedTime: number,
	testType: ModelTypes["TestType"],
	showComments: boolean,
	companyName: string,
	tests: Array<ModelTypes["Test"] | undefined>
};
	["Test"]: {
		availableTime?: number | undefined,
	id: number,
	questions: Array<ModelTypes["TestQuestion"] | undefined>,
	scorable: boolean,
	skillName: string,
	skillType: ModelTypes["SkillType"],
	softTimeLimit?: number | undefined
};
	["TestQuestion"]:ModelTypes["SingleChoiceQuestion"] | ModelTypes["MultipleChoiceQuestion"] | ModelTypes["ClosedNumberQuestion"] | ModelTypes["OpenTextQuestion"] | ModelTypes["LikertQuestion"] | ModelTypes["SituationalQuestion"];
	["SingleChoiceQuestion"]: {
		externalTool?: ModelTypes["ExternalTool"] | undefined,
	possibleAnswers: Array<ModelTypes["PossibleAnswer"] | undefined>,
	uuid: ModelTypes["UUID"],
	text: string,
	image?: string | undefined,
	answerOptional?: boolean | undefined,
	changingWindowAllowed: boolean
};
	["PossibleAnswer"]: {
		uuid: ModelTypes["UUID"],
	text: string
};
	["MultipleChoiceQuestion"]: {
		externalTool?: ModelTypes["ExternalTool"] | undefined,
	possibleAnswers: Array<ModelTypes["PossibleAnswer"] | undefined>,
	uuid: ModelTypes["UUID"],
	text: string,
	image?: string | undefined,
	answerOptional?: boolean | undefined,
	changingWindowAllowed: boolean,
	maxAnswers?: number | undefined
};
	["ClosedNumberQuestion"]: {
		externalTool?: ModelTypes["ExternalTool"] | undefined,
	uuid: ModelTypes["UUID"],
	text: string,
	image?: string | undefined,
	answerOptional?: boolean | undefined,
	changingWindowAllowed: boolean
};
	["OpenTextQuestion"]: {
		externalTool?: ModelTypes["ExternalTool"] | undefined,
	uuid: ModelTypes["UUID"],
	text: string,
	image?: string | undefined,
	answerOptional?: boolean | undefined,
	changingWindowAllowed: boolean
};
	["LikertQuestion"]: {
		uuid: ModelTypes["UUID"],
	text: string,
	image?: string | undefined,
	answerOptional?: boolean | undefined,
	changingWindowAllowed: boolean
};
	["SituationalQuestion"]: {
		possibleAnswers: Array<ModelTypes["PossibleAnswer"] | undefined>,
	uuid: ModelTypes["UUID"],
	text: string,
	image?: string | undefined,
	answerOptional?: boolean | undefined,
	changingWindowAllowed: boolean,
	maxAnswers?: number | undefined
};
	["TestPreviewResult"]:ModelTypes["TestPreview"] | ModelTypes["TestPreviewUnavailable"];
	["TestPreview"]: {
		uuid: ModelTypes["UUID"],
	title?: string | undefined,
	roleName: string,
	availableTime: number,
	numberOfQuestions: number,
	numberOfOpenQuestions: number,
	description?: string | undefined,
	organizationName?: string | undefined,
	branding?: ModelTypes["AccountBranding"] | undefined,
	requiresExternalTool: boolean,
	requiredExternalTools: Array<ModelTypes["ExternalToolKind"] | undefined>,
	locale: ModelTypes["Locale"],
	talentPool: boolean,
	testType: ModelTypes["TestType"],
	testLevel: ModelTypes["TestLevel"],
	competencies: Array<ModelTypes["Skill"] | undefined>,
	createdAt: ModelTypes["DateTime"]
};
	["TestPreviewUnavailable"]: {
		/** Test preview unavailable. */
	reason?: string | undefined
};
	["CandidateSolutionPreview"]: {
		assessment: ModelTypes["TestPreview"],
	solutionState: ModelTypes["SolutionState"],
	candidate: ModelTypes["CandidatePreview"],
	score?: ModelTypes["Decimal"] | undefined,
	timeSpent?: number | undefined,
	summary?: Array<ModelTypes["CandidateSummaryEntry"] | undefined> | undefined
};
	["SolutionState"]:SolutionState;
	["CandidatePreview"]: {
		uuid: ModelTypes["UUID"],
	phoneNumber?: string | undefined,
	firstName: string,
	lastName: string,
	email: string
};
	["CandidateSummaryEntry"]: {
		competency: ModelTypes["Competency"],
	numberOfQuestions: number,
	score: ModelTypes["Decimal"],
	comment?: string | undefined,
	label?: string | undefined
};
	["Mutation"]: {
		/** Authenticate using an email and password */
	auth?: ModelTypes["Auth"] | undefined,
	heroTracking?: ModelTypes["HeroTracking"] | undefined,
	candidate?: ModelTypes["CandidateMutation"] | undefined,
	client?: ModelTypes["ClientMutation"] | undefined
};
	["Auth"]: {
		authorizationToken: string
};
	["HeroTracking"]: {
		result?: string | undefined
};
	["TrackInput"]: {
	eventName: string,
	properties: string
};
	["CandidateMutation"]: {
		talentPoolOptIn?: ModelTypes["TalentPoolOptIn"] | undefined,
	addCommentForQuestion?: ModelTypes["AddCommentForQuestion"] | undefined,
	submitSolution?: ModelTypes["SubmitSolution"] | undefined,
	reportAnomaly?: ModelTypes["ReportAnomaly"] | undefined,
	startSolution?: ModelTypes["StartSolution"] | undefined,
	submitLikertResponse?: ModelTypes["SubmitLikertResponse"] | undefined,
	submitTest?: ModelTypes["SubmitTest"] | undefined,
	registerCandidate?: ModelTypes["RegisterCandidate"] | undefined,
	issue2faToken?: ModelTypes["Issue2FAToken"] | undefined,
	verify2faToken?: ModelTypes["Verify2FAToken"] | undefined,
	registerVerifiedCandidate?: ModelTypes["RegisterVerifiedCandidate"] | undefined,
	startTest?: ModelTypes["StartTest"] | undefined,
	seeQuestion?: ModelTypes["SeeQuestion"] | undefined,
	submitResponse?: ModelTypes["SubmitResponse"] | undefined,
	submitSituationalResponse?: ModelTypes["SubmitSituationalResponse"] | undefined,
	skipQuestion?: ModelTypes["SkipQuestion"] | undefined,
	giveFeedback?: ModelTypes["CandidateGiveFeedback"] | undefined
};
	["TalentPoolOptIn"]: {
		result: boolean
};
	["AddCommentForQuestion"]: {
		result: boolean
};
	["SubmitSolution"]: {
		result: boolean
};
	["ReportAnomaly"]: {
		result: boolean
};
	["AnomalyType"]:AnomalyType;
	["StartSolution"]: {
		result: ModelTypes["SolutionAttempt"]
};
	["SolutionAttempt"]: {
		suggestedTime: number,
	testType: ModelTypes["TestType"],
	showComments: boolean,
	companyName: string,
	tests: Array<ModelTypes["Test"] | undefined>,
	solutionId: ModelTypes["UUID"]
};
	["SubmitLikertResponse"]: {
		result: boolean
};
	["LikertAnswer"]:LikertAnswer;
	["SubmitTest"]: {
		result: string
};
	["SubmitTestInput"]: {
	candidateSessionToken: string,
	testId: number
};
	["RegisterCandidate"]: {
		sessionToken: string
};
	["Issue2FAToken"]: {
		result?: string | undefined
};
	["Verify2FAToken"]: {
		intermediateToken?: string | undefined
};
	["RegisterVerifiedCandidate"]: {
		candidateSessionToken: string
};
	["StartTest"]: {
		result?: string | undefined
};
	["StartTestInput"]: {
	candidateSessionToken: string,
	testId: number
};
	["SeeQuestion"]: {
		result: string,
	responseId: string
};
	["SeeQuestionInput"]: {
	candidateSessionToken: string,
	questionId: ModelTypes["UUID"],
	testId: number
};
	["SubmitResponse"]: {
		result: string
};
	["SubmitResponseInput"]: {
	candidateSessionToken: string,
	questionId: ModelTypes["UUID"],
	testId: number,
	answersGiven: Array<ModelTypes["AnswerGivenInput"]>
};
	["AnswerGivenInput"]: {
	text?: string | undefined,
	answerId?: ModelTypes["UUID"] | undefined
};
	["SubmitSituationalResponse"]: {
		result: string
};
	["SubmitSituationalResponseInput"]: {
	candidateSessionToken: string,
	questionId: ModelTypes["UUID"],
	testId: number,
	answersGiven: Array<ModelTypes["SituationalAnswerGivenInput"]>
};
	["SituationalAnswerGivenInput"]: {
	answerId?: ModelTypes["UUID"] | undefined,
	value?: number | undefined
};
	["SkipQuestion"]: {
		result: string
};
	["SkipQuestionInput"]: {
	candidateSessionToken: string,
	questionId: ModelTypes["UUID"],
	testId: number
};
	["CandidateGiveFeedback"]: {
		result: string
};
	["ClientMutation"]: {
		startCheckoutSession?: ModelTypes["StartCheckoutSession"] | undefined,
	cancelSubscription?: ModelTypes["CancelSubscription"] | undefined,
	createAssessment?: ModelTypes["CreateAssessment"] | undefined,
	signUp?: ModelTypes["SignUp"] | undefined,
	submitOnboardingSurvey?: ModelTypes["SubmitOnboardingSurvey"] | undefined,
	submitOnboardingAccountDetails?: ModelTypes["SubmitOnboardingAccountDetails"] | undefined,
	visitOnboarding?: ModelTypes["VisitOnboarding"] | undefined,
	sendEmail?: ModelTypes["SendEmail"] | undefined,
	changeNotificationFrequency?: ModelTypes["ChangeNotificationFrequency"] | undefined,
	solutionToken?: ModelTypes["SolutionToken"] | undefined,
	changeAtsAssociationSettings?: ModelTypes["ChangeAtsAssociationSettings"] | undefined,
	deleteAtsAssociation?: ModelTypes["DeleteAtsAssociation"] | undefined,
	earlyAccessSignup?: ModelTypes["EarlyAccessSignup"] | undefined,
	setPassword?: ModelTypes["SetPassword"] | undefined,
	sendResetPassword?: ModelTypes["SendResetPassword"] | undefined,
	archiveTest?: ModelTypes["ArchiveTest"] | undefined,
	unarchiveTest?: ModelTypes["UnarchiveTest"] | undefined,
	deleteTest?: ModelTypes["DeleteTest"] | undefined,
	updateCandidatesStatus?: ModelTypes["UpdateCandidatesStatus"] | undefined,
	updateBranding?: ModelTypes["UpdateBranding"] | undefined,
	seeSolution?: ModelTypes["ClientSeeSolution"] | undefined
};
	["StartCheckoutSession"]: {
		result: ModelTypes["StartCheckoutSessionResult"]
};
	["StartCheckoutSessionResult"]: {
		url: string
};
	["CancelSubscription"]: {
		result: string
};
	["CreateAssessment"]: {
		result: ModelTypes["Assessment"]
};
	["SignUp"]: {
		result: ModelTypes["SignupResult"]
};
	["SignupResult"]:ModelTypes["SignupResultSuccess"] | ModelTypes["SignupResultError"];
	["SignupResultSuccess"]: {
		auth: string,
	changePasswordToken: string
};
	["SignupResultError"]: {
		value: string
};
	["SubmitOnboardingSurvey"]: {
		result: boolean
};
	/** Allows use of a JSON String for input / output from the GraphQL schema.

Use of this type is *not recommended* as you lose the benefits of having a defined, static
schema (one of the key benefits of GraphQL). */
["JSONString"]:any;
	["SubmitOnboardingAccountDetails"]: {
		result: ModelTypes["SubmitOnboardingAccountDetailsResult"]
};
	["SubmitOnboardingAccountDetailsResult"]:ModelTypes["SubmitOnboardingAccountDetailsSuccess"] | ModelTypes["SiubmitOnboardingAccountDetailsError"];
	["SubmitOnboardingAccountDetailsSuccess"]: {
		result: boolean
};
	["SiubmitOnboardingAccountDetailsError"]: {
		error: string
};
	["VisitOnboarding"]: {
		result: boolean
};
	["SendEmail"]: {
		result: boolean
};
	["ChangeNotificationFrequency"]: {
		result: boolean
};
	["SolutionToken"]: {
		result: ModelTypes["SolutionTokenResult"]
};
	["SolutionTokenResult"]:ModelTypes["SolutionTokenResultSuccess"] | ModelTypes["SolutionTokenResultError"];
	["SolutionTokenResultSuccess"]: {
		token?: string | undefined
};
	["SolutionTokenResultError"]: {
		error?: string | undefined
};
	["ChangeAtsAssociationSettings"]: {
		result: ModelTypes["ChangeAtsAssociationSettingsUnion"]
};
	["ChangeAtsAssociationSettingsUnion"]:ModelTypes["ChangeAtsAssociationSettingsSuccess"] | ModelTypes["ChangeAtsAssociationSettingsError"];
	["ChangeAtsAssociationSettingsSuccess"]: {
		result: boolean
};
	["ChangeAtsAssociationSettingsError"]: {
		result: boolean,
	error: string
};
	["DeleteAtsAssociation"]: {
		result: ModelTypes["DeleteAtsAssociationUnion"]
};
	["DeleteAtsAssociationUnion"]:ModelTypes["DeleteAtsAssociationError"] | ModelTypes["DeleteAtsAssociationSuccess"];
	["DeleteAtsAssociationError"]: {
		result: boolean,
	error: string
};
	["DeleteAtsAssociationSuccess"]: {
		result: boolean
};
	["EarlyAccessSignup"]: {
		result: ModelTypes["EarlyAccessSignupResult"]
};
	["EarlyAccessSignupResult"]: {
		status: boolean,
	errors: Array<ModelTypes["Error"]>
};
	["Error"]: {
		name: string,
	message: string
};
	["SetPassword"]: {
		authorizationToken?: string | undefined
};
	["SendResetPassword"]: {
		Result: string,
	result: string
};
	["SendResetPasswordInput"]: {
	email: string
};
	["ArchiveTest"]: {
		result: string
};
	["UnarchiveTest"]: {
		result: string
};
	["DeleteTest"]: {
		result: string
};
	["UpdateCandidatesStatus"]: {
		candidates: Array<ModelTypes["SolutionCandidate"]>
};
	["UpdateCandidatesStatusInput"]: {
	candidateIds: Array<ModelTypes["UUID"] | undefined>,
	status: ModelTypes["CandidateStatusInput"]
};
	["CandidateStatusInput"]:CandidateStatusInput;
	["UpdateBranding"]: {
		result: string
};
	["BrandingInput"]: {
	logo?: ModelTypes["Upload"] | undefined,
	image?: ModelTypes["Upload"] | undefined,
	primaryColor: string,
	secondaryColor?: string | undefined,
	headline: string,
	description: string,
	removeLogo?: boolean | undefined,
	removeImage?: boolean | undefined
};
	/** Create scalar that ignores normal serialization/deserialization, since
that will be handled by the multipart request spec */
["Upload"]:any;
	["ClientSeeSolution"]: {
		result: string
};
	["SeeSolution"]: {
	solutionId: ModelTypes["UUID"]
}
    }

export type GraphQLTypes = {
    ["Query"]: {
	__typename: "Query",
	_debug?: GraphQLTypes["DjangoDebug"] | undefined,
	me?: GraphQLTypes["User"] | undefined,
	supportedSkills?: GraphQLTypes["SkillConnection"] | undefined,
	suggestedSkills?: GraphQLTypes["SkillConnection"] | undefined,
	solutionResponses?: GraphQLTypes["SolutionResponses"] | undefined,
	solutionResponsesWithToken?: GraphQLTypes["SolutionResponses"] | undefined,
	client?: GraphQLTypes["ClientQuery"] | undefined,
	candidate?: GraphQLTypes["CandidateQuery"] | undefined
};
	/** Debugging information for the current query. */
["DjangoDebug"]: {
	__typename: "DjangoDebug",
	/** Executed SQL queries for this API query. */
	sql?: Array<GraphQLTypes["DjangoDebugSQL"] | undefined> | undefined
};
	/** Represents a single database query made to a Django managed DB. */
["DjangoDebugSQL"]: {
	__typename: "DjangoDebugSQL",
	/** The type of database being used (e.g. postrgesql, mysql, sqlite). */
	vendor: string,
	/** The Django database alias (e.g. 'default'). */
	alias: string,
	/** The actual SQL sent to this database. */
	sql?: string | undefined,
	/** Duration of this database query in seconds. */
	duration: number,
	/** The raw SQL of this query, without params. */
	rawSql: string,
	/** JSON encoded database query parameters. */
	params: string,
	/** Start time of this database query. */
	startTime: number,
	/** Stop time of this database query. */
	stopTime: number,
	/** Whether this database query took more than 10 seconds. */
	isSlow: boolean,
	/** Whether this database query was a SELECT. */
	isSelect: boolean,
	/** Postgres transaction ID if available. */
	transId?: string | undefined,
	/** Postgres transaction status if available. */
	transStatus?: string | undefined,
	/** Postgres isolation level if available. */
	isoLevel?: string | undefined,
	/** Postgres connection encoding if available. */
	encoding?: string | undefined
};
	["User"]: {
	__typename: "User",
	uuid: GraphQLTypes["UUID"],
	email: string,
	firstName: string,
	lastName: string,
	locale: GraphQLTypes["UserLocale"],
	role?: string | undefined,
	createdAt: GraphQLTypes["DateTime"],
	updatedAt: GraphQLTypes["DateTime"],
	account: GraphQLTypes["Account"],
	lastSeenAt?: GraphQLTypes["DateTime"] | undefined,
	phone?: string | undefined,
	active?: boolean | undefined,
	admin?: boolean | undefined,
	accountLocale?: GraphQLTypes["Locale"] | undefined,
	onboardingCompleted?: boolean | undefined,
	expiresOn?: GraphQLTypes["DateTime"] | undefined,
	emailConfirmed?: boolean | undefined,
	features: Array<GraphQLTypes["Feature"]>
};
	/** Leverages the internal Python implmeentation of UUID (uuid.UUID) to provide native UUID objects
in fields, resolvers and input. */
["UUID"]: "scalar" & { name: "UUID" };
	/** An enumeration. */
["UserLocale"]: UserLocale;
	/** The `DateTime` scalar type represents a DateTime
value as specified by
[iso8601](https://en.wikipedia.org/wiki/ISO_8601). */
["DateTime"]: "scalar" & { name: "DateTime" };
	["Account"]: {
	__typename: "Account",
	id: string,
	name?: string | undefined,
	expiresOn?: GraphQLTypes["DateTime"] | undefined,
	locale: GraphQLTypes["AccountLocale"],
	billingModel: GraphQLTypes["AccountBillingModel"],
	tokensRemaining: number,
	slots: number,
	brandingEnabled: boolean,
	hasSubscription: boolean,
	verify: boolean,
	subscription?: GraphQLTypes["Subscription"] | undefined,
	slotsInUse: number
};
	/** An enumeration. */
["AccountLocale"]: AccountLocale;
	/** An enumeration. */
["AccountBillingModel"]: AccountBillingModel;
	["Subscription"]: {
	__typename: "Subscription",
	status: GraphQLTypes["SubscriptionState"]
};
	/** An enumeration. */
["SubscriptionState"]: SubscriptionState;
	/** An enumeration. */
["Locale"]: Locale;
	/** An enumeration. */
["Feature"]: Feature;
	["SkillConnection"]: {
	__typename: "SkillConnection",
	/** Pagination data for this connection. */
	pageInfo: GraphQLTypes["PageInfo"],
	/** Contains the nodes in this connection. */
	edges: Array<GraphQLTypes["SkillEdge"] | undefined>
};
	/** The Relay compliant `PageInfo` type, containing data necessary to paginate this connection. */
["PageInfo"]: {
	__typename: "PageInfo",
	/** When paginating forwards, are there more items? */
	hasNextPage: boolean,
	/** When paginating backwards, are there more items? */
	hasPreviousPage: boolean,
	/** When paginating backwards, the cursor to continue. */
	startCursor?: string | undefined,
	/** When paginating forwards, the cursor to continue. */
	endCursor?: string | undefined
};
	/** A Relay edge containing a `Skill` and its cursor. */
["SkillEdge"]: {
	__typename: "SkillEdge",
	/** The item at the end of the edge */
	node?: GraphQLTypes["Competency"] | undefined,
	/** A cursor for use in pagination */
	cursor: string
};
	["Competency"]: {
	__typename: "Competency",
	uuid: GraphQLTypes["UUID"],
	name: string,
	domain: string,
	icon: GraphQLTypes["Icon"],
	description: string,
	skillType: GraphQLTypes["SkillType"],
	testDuration: number
};
	/** An enumeration. */
["Icon"]: Icon;
	/** An enumeration. */
["SkillType"]: SkillType;
	["PositionInput"]: {
		jobTitle: string,
	seniority?: string | undefined,
	testLevel: GraphQLTypes["TestLevel"],
	testType: GraphQLTypes["TestType"],
	description?: string | undefined,
	locale?: GraphQLTypes["Locale"] | undefined
};
	/** An enumeration. */
["TestLevel"]: TestLevel;
	/** An enumeration. */
["TestType"]: TestType;
	["SolutionResponses"]:{
        	__typename:"SolutionResponsesSuccess" | "SolutionResponsesError"
        	['...on SolutionResponsesSuccess']: '__union' & GraphQLTypes["SolutionResponsesSuccess"];
	['...on SolutionResponsesError']: '__union' & GraphQLTypes["SolutionResponsesError"];
};
	["SolutionResponsesSuccess"]: {
	__typename: "SolutionResponsesSuccess",
	tests: Array<GraphQLTypes["TestSolution"]>
};
	["TestSolution"]: {
	__typename: "TestSolution",
	id: string,
	solution?: GraphQLTypes["Solution"] | undefined,
	startedAt: GraphQLTypes["DateTime"],
	submittedAt?: GraphQLTypes["DateTime"] | undefined,
	score?: GraphQLTypes["Decimal"] | undefined,
	responses: Array<GraphQLTypes["SolutionTestResponse"]>,
	skillName: string
};
	["Solution"]: {
	__typename: "Solution",
	candidate: GraphQLTypes["SolutionCandidate"],
	uuid: GraphQLTypes["UUID"],
	createdAt: GraphQLTypes["DateTime"],
	updatedAt: GraphQLTypes["DateTime"],
	seenAt?: GraphQLTypes["DateTime"] | undefined,
	submittedAt: GraphQLTypes["DateTime"],
	testRoleName: string,
	starred: boolean,
	solutionState: string,
	score: GraphQLTypes["Decimal"],
	timeSpent: number,
	personalityFit?: GraphQLTypes["PersonalityFit"] | undefined,
	skillSummaries: Array<GraphQLTypes["SkillSummary"] | undefined>,
	summary?: Array<GraphQLTypes["SummaryEntry"] | undefined> | undefined,
	notificationSentAt?: GraphQLTypes["DateTime"] | undefined,
	notificationsFrequency: GraphQLTypes["NotificationFrequency"],
	assessment: GraphQLTypes["Assessment"],
	openQuestionsResponses: Array<GraphQLTypes["Response"] | undefined>,
	situationalSummaries: Array<GraphQLTypes["SituationalSummary"] | undefined>,
	hideMedians: boolean
};
	["SolutionCandidate"]: {
	__typename: "SolutionCandidate",
	firstName: string,
	lastName: string,
	email: string,
	uuid: GraphQLTypes["UUID"],
	phoneNumber?: string | undefined,
	status: GraphQLTypes["CandidateStatus"]
};
	/** An enumeration. */
["CandidateStatus"]: CandidateStatus;
	/** The `Decimal` scalar type represents a python Decimal. */
["Decimal"]: "scalar" & { name: "Decimal" };
	/** An enumeration. */
["PersonalityFit"]: PersonalityFit;
	["SkillSummary"]: {
	__typename: "SkillSummary",
	skillUuid: GraphQLTypes["UUID"],
	name: string,
	score: GraphQLTypes["Decimal"],
	availableTime: number,
	timeSpent: number,
	areas: Array<GraphQLTypes["Area"] | undefined>,
	activities: Array<GraphQLTypes["JobActivity"] | undefined>,
	comment: string,
	detailsIncluded: boolean,
	suggestedQuestions: Array<string | undefined>,
	questionsAnswered: number,
	questionsTotal: number
};
	["Area"]: {
	__typename: "Area",
	name: string,
	score: GraphQLTypes["Decimal"],
	scoreRange?: GraphQLTypes["AreaScoreRange"] | undefined,
	comment?: string | undefined,
	explanation?: string | undefined
};
	["AreaScoreRange"]: AreaScoreRange;
	["JobActivity"]: {
	__typename: "JobActivity",
	name: string
};
	["SummaryEntry"]: {
	__typename: "SummaryEntry",
	competency: GraphQLTypes["Competency"],
	score: GraphQLTypes["Decimal"],
	availableTime: number,
	timeSpent: number,
	totalQuestionsCount: number,
	answeredQuestionsCount: number,
	comment?: string | undefined
};
	/** An enumeration. */
["NotificationFrequency"]: NotificationFrequency;
	["Assessment"]: {
	__typename: "Assessment",
	uuid: GraphQLTypes["UUID"],
	title?: string | undefined,
	roleName: string,
	availableTime: number,
	numberOfQuestions: number,
	numberOfOpenQuestions: number,
	organizationName: string,
	requiresExternalTool: boolean,
	requiredExternalTools: Array<GraphQLTypes["ExternalToolKind"] | undefined>,
	description?: string | undefined,
	competencies: Array<GraphQLTypes["Competency"] | undefined>,
	locale: GraphQLTypes["Locale"],
	talentPool: boolean,
	testType: GraphQLTypes["TestType"],
	testLevel: GraphQLTypes["TestLevel"],
	state: GraphQLTypes["TestState"],
	notificationFrequency: GraphQLTypes["NotificationFrequency"],
	createdAt: GraphQLTypes["DateTime"],
	seniority: GraphQLTypes["Seniority"],
	solutionsDistribution: Array<GraphQLTypes["Bin"] | undefined>,
	averageSkillSummary: Array<GraphQLTypes["SummarySkillEntry"] | undefined>,
	atsAssociation?: GraphQLTypes["AtsAssociation"] | undefined,
	completedBy: number,
	mostRecentActivityAt?: GraphQLTypes["DateTime"] | undefined,
	mostRecentActivitySecondsAgo?: number | undefined
};
	/** An enumeration. */
["ExternalToolKind"]: ExternalToolKind;
	/** An enumeration. */
["TestState"]: TestState;
	/** An enumeration. */
["Seniority"]: Seniority;
	["Bin"]: {
	__typename: "Bin",
	label: string,
	value: number,
	selected?: boolean | undefined
};
	["SummarySkillEntry"]: {
	__typename: "SummarySkillEntry",
	skill: GraphQLTypes["Skill"],
	score: number
};
	["Skill"]: {
	__typename: "Skill",
	uuid: GraphQLTypes["UUID"],
	name: string,
	domain: string,
	icon: GraphQLTypes["Icon"],
	description: string,
	type: GraphQLTypes["SkillType"]
};
	["AtsAssociation"]: {
	__typename: "AtsAssociation",
	integration: GraphQLTypes["AtsIntegration"],
	assessment: GraphQLTypes["Assessment"],
	jobId: string,
	stageId: string,
	emailText: string,
	emailSubject: string,
	lastChangedAt?: GraphQLTypes["DateTime"] | undefined
};
	["AtsIntegration"]: {
	__typename: "AtsIntegration",
	account?: GraphQLTypes["Account"] | undefined,
	remoteId: string
};
	["Response"]: {
	__typename: "Response",
	uuid?: GraphQLTypes["UUID"] | undefined,
	question?: GraphQLTypes["Question"] | undefined,
	score?: GraphQLTypes["Decimal"] | undefined,
	scored?: boolean | undefined,
	outdated?: boolean | undefined,
	answersGiven?: Array<GraphQLTypes["AnswerGiven"] | undefined> | undefined
};
	["Question"]: {
	__typename: "Question",
	uuid: GraphQLTypes["UUID"],
	competencyName: string,
	skill?: GraphQLTypes["Skill"] | undefined,
	custom: boolean,
	questionType: GraphQLTypes["QuestionTypes"],
	answers: Array<GraphQLTypes["Answer"] | undefined>,
	content: string,
	appendix?: string | undefined,
	availableTime: number,
	questionStatus: GraphQLTypes["QuestionTypes"],
	requiresExternalTool: string,
	aiGenerated: boolean
};
	/** An enumeration. */
["QuestionTypes"]: QuestionTypes;
	["Answer"]: {
	__typename: "Answer",
	uuid?: GraphQLTypes["UUID"] | undefined,
	correct?: boolean | undefined,
	content?: string | undefined
};
	["AnswerGiven"]: {
	__typename: "AnswerGiven",
	uuid?: GraphQLTypes["UUID"] | undefined,
	answer?: GraphQLTypes["Answer"] | undefined,
	correct?: boolean | undefined,
	userSelected?: boolean | undefined,
	content?: string | undefined
};
	["SituationalSummary"]: {
	__typename: "SituationalSummary",
	skillUuid: GraphQLTypes["UUID"],
	name: string,
	dimensions: Array<GraphQLTypes["DimensionSummary"] | undefined>
};
	["DimensionSummary"]: {
	__typename: "DimensionSummary",
	skillUuid: GraphQLTypes["UUID"],
	name: string,
	score: GraphQLTypes["Decimal"],
	distribution: Array<GraphQLTypes["Bin"]>,
	comment: string,
	scoreLabel: string,
	dimensionComment: string
};
	["SolutionTestResponse"]: {
	__typename: "SolutionTestResponse",
	question: GraphQLTypes["SolutionTestQuestion"],
	label: GraphQLTypes["ResponseLabel"],
	answerGiven?: string | undefined
};
	["SolutionTestQuestion"]: {
	__typename: "SolutionTestQuestion",
	answers?: Array<GraphQLTypes["SolutionTestAnswer"] | undefined> | undefined,
	content: string,
	externalTool?: GraphQLTypes["ExternalTool"] | undefined,
	image?: string | undefined
};
	["SolutionTestAnswer"]: {
	__typename: "SolutionTestAnswer",
	content: string
};
	["ExternalTool"]: {
	__typename: "ExternalTool",
	instruction: string,
	url: string,
	name: string
};
	/** An enumeration. */
["ResponseLabel"]: ResponseLabel;
	["SolutionResponsesError"]: {
	__typename: "SolutionResponsesError",
	error?: string | undefined
};
	["SolutionResponsesInput"]: {
		solutionId: GraphQLTypes["UUID"]
};
	["ClientQuery"]: {
	__typename: "ClientQuery",
	solution?: GraphQLTypes["SolutionUnion"] | undefined,
	test?: GraphQLTypes["TestAssessment"] | undefined,
	branding?: GraphQLTypes["AccountBranding"] | undefined,
	solutionWithToken?: GraphQLTypes["SolutionUnion"] | undefined,
	productPlan?: GraphQLTypes["ProductPlan"] | undefined,
	suggestedOpenQuestionsConnection?: GraphQLTypes["QuestionConnection"] | undefined,
	suggestedOpenAiQuestionsConnection?: GraphQLTypes["QuestionConnection"] | undefined,
	solutionConnection?: GraphQLTypes["SolutionConnection"] | undefined,
	skillsBenchmark?: GraphQLTypes["SkillsBenchmarkConnection"] | undefined,
	currentSubscription?: GraphQLTypes["CurrentSubscription"] | undefined,
	assessmentPreview?: GraphQLTypes["AssessmentPreview"] | undefined,
	solutionResponses?: GraphQLTypes["SolutionResponses"] | undefined,
	solutionResponsesWithToken?: GraphQLTypes["SolutionResponses"] | undefined,
	atsAssociationOptions?: Array<GraphQLTypes["AtsJob"] | undefined> | undefined,
	assessment?: GraphQLTypes["Assessment"] | undefined,
	assessmentConnection?: GraphQLTypes["AssessmentConnection"] | undefined,
	personalityTestResult?: GraphQLTypes["PersonalityTestResult"] | undefined
};
	["SolutionUnion"]:{
        	__typename:"Solution" | "SolutionError"
        	['...on Solution']: '__union' & GraphQLTypes["Solution"];
	['...on SolutionError']: '__union' & GraphQLTypes["SolutionError"];
};
	["SolutionError"]: {
	__typename: "SolutionError",
	error?: string | undefined
};
	["TestAssessment"]: {
	__typename: "TestAssessment",
	uuid: GraphQLTypes["UUID"],
	title?: string | undefined,
	roleName: string,
	availableTime: number,
	numberOfQuestions: number,
	numberOfOpenQuestions: number,
	description?: string | undefined,
	organizationName: string,
	branding?: GraphQLTypes["AccountBranding"] | undefined,
	requiresExternalTool: boolean,
	requiredExternalTools: Array<GraphQLTypes["ExternalToolKind"] | undefined>,
	locale: GraphQLTypes["Locale"],
	talentPool: boolean,
	testType: GraphQLTypes["TestType"],
	testLevel: GraphQLTypes["TestLevel"],
	competencies: Array<GraphQLTypes["Skill"] | undefined>,
	createdAt: GraphQLTypes["DateTime"],
	expiresAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	mostRecentActivityAt?: GraphQLTypes["DateTime"] | undefined,
	mostRecentActivitySecondsAgo?: number | undefined,
	disabledAt?: GraphQLTypes["DateTime"] | undefined,
	typeformTestId?: string | undefined,
	seniority: GraphQLTypes["Seniority"],
	testState: GraphQLTypes["TestState"],
	/** Number of submitted solutions to the test */
	completedBy: number,
	/** Average time in seconds spent on solutions */
	averageSolutionTime?: number | undefined,
	/** Average score of solutions */
	averageSolutionScore?: GraphQLTypes["Decimal"] | undefined,
	/** Owner of the test (HR user / organization) */
	assignedTo?: GraphQLTypes["BaseUser"] | undefined,
	solutionsDistribution: Array<GraphQLTypes["Bin"] | undefined>,
	experimental?: boolean | undefined,
	questions?: Array<GraphQLTypes["Question"] | undefined> | undefined,
	skills?: Array<GraphQLTypes["Skill"] | undefined> | undefined,
	averageSummary?: Array<GraphQLTypes["TestAssessmentSummary"] | undefined> | undefined,
	solutionUuids?: Array<GraphQLTypes["UUID"] | undefined> | undefined
};
	["AccountBranding"]: {
	__typename: "AccountBranding",
	uuid: GraphQLTypes["UUID"],
	logo?: string | undefined,
	image?: string | undefined,
	primaryColor: string,
	secondaryColor?: string | undefined,
	headline: string,
	description: string
};
	["BaseUser"]: {
	__typename: "BaseUser",
	uuid: GraphQLTypes["UUID"],
	firstName: string,
	lastName: string,
	organizationName?: string | undefined,
	email: string,
	isAdmin: boolean,
	locale: GraphQLTypes["Locale"],
	accountId: number,
	role?: GraphQLTypes["Role"] | undefined,
	logUrl: string,
	accountLogUrl: string
};
	/** An enumeration. */
["Role"]: Role;
	["TestAssessmentSummary"]: {
	__typename: "TestAssessmentSummary",
	competency: GraphQLTypes["SummaryCompetency"],
	numberOfQuestions: number,
	score: GraphQLTypes["Decimal"]
};
	["SummaryCompetency"]: {
	__typename: "SummaryCompetency",
	uuid: GraphQLTypes["UUID"],
	name: string
};
	["ProductPlan"]: {
	__typename: "ProductPlan",
	maxNumberOfSkills: number,
	maxNumberOfOpenQuestions: number
};
	["QuestionConnection"]: {
	__typename: "QuestionConnection",
	/** Pagination data for this connection. */
	pageInfo: GraphQLTypes["PageInfo"],
	/** Contains the nodes in this connection. */
	edges: Array<GraphQLTypes["QuestionEdge"] | undefined>
};
	/** A Relay edge containing a `Question` and its cursor. */
["QuestionEdge"]: {
	__typename: "QuestionEdge",
	/** The item at the end of the edge */
	node?: GraphQLTypes["Question"] | undefined,
	/** A cursor for use in pagination */
	cursor: string
};
	["SolutionConnection"]: {
	__typename: "SolutionConnection",
	/** Pagination data for this connection. */
	pageInfo: GraphQLTypes["PageInfo"],
	/** Contains the nodes in this connection. */
	edges: Array<GraphQLTypes["SolutionEdge"] | undefined>
};
	/** A Relay edge containing a `Solution` and its cursor. */
["SolutionEdge"]: {
	__typename: "SolutionEdge",
	/** The item at the end of the edge */
	node?: GraphQLTypes["Solution"] | undefined,
	/** A cursor for use in pagination */
	cursor: string
};
	["SkillsBenchmarkConnection"]: {
	__typename: "SkillsBenchmarkConnection",
	/** Pagination data for this connection. */
	pageInfo: GraphQLTypes["PageInfo"],
	/** Contains the nodes in this connection. */
	edges: Array<GraphQLTypes["SkillsBenchmarkEdge"] | undefined>
};
	/** A Relay edge containing a `SkillsBenchmark` and its cursor. */
["SkillsBenchmarkEdge"]: {
	__typename: "SkillsBenchmarkEdge",
	/** The item at the end of the edge */
	node?: GraphQLTypes["SkillsBenchmark"] | undefined,
	/** A cursor for use in pagination */
	cursor: string
};
	["SkillsBenchmark"]: {
	__typename: "SkillsBenchmark",
	skill?: GraphQLTypes["Skill"] | undefined,
	testType: GraphQLTypes["TestType"],
	testLevel: GraphQLTypes["TestLevel"],
	median?: number | undefined,
	mean?: number | undefined,
	standardDeviation?: number | undefined,
	count?: number | undefined
};
	["CurrentSubscription"]: {
	__typename: "CurrentSubscription",
	plan: GraphQLTypes["SubscriptionPlan"],
	cancelAtPeriodEnd: boolean,
	currentPeriodStart: GraphQLTypes["DateTime"],
	currentPeriodEnd: GraphQLTypes["DateTime"],
	status: GraphQLTypes["SubscriptionState"]
};
	["SubscriptionPlan"]: {
	__typename: "SubscriptionPlan",
	name: string
};
	["AssessmentPreview"]: {
	__typename: "AssessmentPreview",
	result: GraphQLTypes["AssessmentPreviewResult"]
};
	["AssessmentPreviewResult"]:{
        	__typename:"Assessment" | "AssessmentPreviewError"
        	['...on Assessment']: '__union' & GraphQLTypes["Assessment"];
	['...on AssessmentPreviewError']: '__union' & GraphQLTypes["AssessmentPreviewError"];
};
	["AssessmentPreviewError"]: {
	__typename: "AssessmentPreviewError",
	value?: string | undefined
};
	["AtsJob"]: {
	__typename: "AtsJob",
	name: string,
	id: string,
	stages?: Array<GraphQLTypes["AtsStage"] | undefined> | undefined
};
	["AtsStage"]: {
	__typename: "AtsStage",
	name: string,
	id: string,
	index: number
};
	["AssessmentConnection"]: {
	__typename: "AssessmentConnection",
	/** Pagination data for this connection. */
	pageInfo: GraphQLTypes["PageInfo"],
	/** Contains the nodes in this connection. */
	edges: Array<GraphQLTypes["AssessmentEdge"] | undefined>
};
	/** A Relay edge containing a `Assessment` and its cursor. */
["AssessmentEdge"]: {
	__typename: "AssessmentEdge",
	/** The item at the end of the edge */
	node?: GraphQLTypes["Assessment"] | undefined,
	/** A cursor for use in pagination */
	cursor: string
};
	["PersonalityTestResult"]: {
	__typename: "PersonalityTestResult",
	name: string,
	scales: Array<GraphQLTypes["PersonalityTestScale"]>
};
	["PersonalityTestScale"]: {
	__typename: "PersonalityTestScale",
	name: string,
	scoreRange: GraphQLTypes["ScoreRange"],
	description: string,
	comment: string
};
	/** An enumeration. */
["ScoreRange"]: ScoreRange;
	["CandidateQuery"]: {
	__typename: "CandidateQuery",
	solutionAttemptCandidate?: GraphQLTypes["SolutionAttemptCandidate"] | undefined,
	solutionResults?: GraphQLTypes["SolutionResults"] | undefined,
	assessment?: GraphQLTypes["CandidateAssessment"] | undefined,
	solution?: GraphQLTypes["CandidateSolution"] | undefined,
	candidateTestPreview?: GraphQLTypes["TestPreviewResult"] | undefined,
	/** Preview Solution details with a session token as a candidate. */
	candidateSolutionPreview?: GraphQLTypes["CandidateSolutionPreview"] | undefined
};
	["SolutionAttemptCandidate"]: {
	__typename: "SolutionAttemptCandidate",
	firstName: string,
	lastName: string,
	email: string,
	canAttempt: boolean
};
	["SolutionResults"]: {
	__typename: "SolutionResults",
	scales: Array<GraphQLTypes["CandidateScaleResult"] | undefined>
};
	["CandidateScaleResult"]: {
	__typename: "CandidateScaleResult",
	name: string,
	comment: string
};
	["CandidateAssessment"]: {
	__typename: "CandidateAssessment",
	skills: Array<GraphQLTypes["CandidateSkill"]>
};
	["CandidateSkill"]: {
	__typename: "CandidateSkill",
	name: string,
	type: GraphQLTypes["SkillType"]
};
	["CandidateSolution"]: {
	__typename: "CandidateSolution",
	suggestedTime: number,
	testType: GraphQLTypes["TestType"],
	showComments: boolean,
	companyName: string,
	tests: Array<GraphQLTypes["Test"] | undefined>
};
	["Test"]: {
	__typename: "Test",
	availableTime?: number | undefined,
	id: number,
	questions: Array<GraphQLTypes["TestQuestion"] | undefined>,
	scorable: boolean,
	skillName: string,
	skillType: GraphQLTypes["SkillType"],
	softTimeLimit?: number | undefined
};
	["TestQuestion"]:{
        	__typename:"SingleChoiceQuestion" | "MultipleChoiceQuestion" | "ClosedNumberQuestion" | "OpenTextQuestion" | "LikertQuestion" | "SituationalQuestion"
        	['...on SingleChoiceQuestion']: '__union' & GraphQLTypes["SingleChoiceQuestion"];
	['...on MultipleChoiceQuestion']: '__union' & GraphQLTypes["MultipleChoiceQuestion"];
	['...on ClosedNumberQuestion']: '__union' & GraphQLTypes["ClosedNumberQuestion"];
	['...on OpenTextQuestion']: '__union' & GraphQLTypes["OpenTextQuestion"];
	['...on LikertQuestion']: '__union' & GraphQLTypes["LikertQuestion"];
	['...on SituationalQuestion']: '__union' & GraphQLTypes["SituationalQuestion"];
};
	["SingleChoiceQuestion"]: {
	__typename: "SingleChoiceQuestion",
	externalTool?: GraphQLTypes["ExternalTool"] | undefined,
	possibleAnswers: Array<GraphQLTypes["PossibleAnswer"] | undefined>,
	uuid: GraphQLTypes["UUID"],
	text: string,
	image?: string | undefined,
	answerOptional?: boolean | undefined,
	changingWindowAllowed: boolean
};
	["PossibleAnswer"]: {
	__typename: "PossibleAnswer",
	uuid: GraphQLTypes["UUID"],
	text: string
};
	["MultipleChoiceQuestion"]: {
	__typename: "MultipleChoiceQuestion",
	externalTool?: GraphQLTypes["ExternalTool"] | undefined,
	possibleAnswers: Array<GraphQLTypes["PossibleAnswer"] | undefined>,
	uuid: GraphQLTypes["UUID"],
	text: string,
	image?: string | undefined,
	answerOptional?: boolean | undefined,
	changingWindowAllowed: boolean,
	maxAnswers?: number | undefined
};
	["ClosedNumberQuestion"]: {
	__typename: "ClosedNumberQuestion",
	externalTool?: GraphQLTypes["ExternalTool"] | undefined,
	uuid: GraphQLTypes["UUID"],
	text: string,
	image?: string | undefined,
	answerOptional?: boolean | undefined,
	changingWindowAllowed: boolean
};
	["OpenTextQuestion"]: {
	__typename: "OpenTextQuestion",
	externalTool?: GraphQLTypes["ExternalTool"] | undefined,
	uuid: GraphQLTypes["UUID"],
	text: string,
	image?: string | undefined,
	answerOptional?: boolean | undefined,
	changingWindowAllowed: boolean
};
	["LikertQuestion"]: {
	__typename: "LikertQuestion",
	uuid: GraphQLTypes["UUID"],
	text: string,
	image?: string | undefined,
	answerOptional?: boolean | undefined,
	changingWindowAllowed: boolean
};
	["SituationalQuestion"]: {
	__typename: "SituationalQuestion",
	possibleAnswers: Array<GraphQLTypes["PossibleAnswer"] | undefined>,
	uuid: GraphQLTypes["UUID"],
	text: string,
	image?: string | undefined,
	answerOptional?: boolean | undefined,
	changingWindowAllowed: boolean,
	maxAnswers?: number | undefined
};
	["TestPreviewResult"]:{
        	__typename:"TestPreview" | "TestPreviewUnavailable"
        	['...on TestPreview']: '__union' & GraphQLTypes["TestPreview"];
	['...on TestPreviewUnavailable']: '__union' & GraphQLTypes["TestPreviewUnavailable"];
};
	["TestPreview"]: {
	__typename: "TestPreview",
	uuid: GraphQLTypes["UUID"],
	title?: string | undefined,
	roleName: string,
	availableTime: number,
	numberOfQuestions: number,
	numberOfOpenQuestions: number,
	description?: string | undefined,
	organizationName?: string | undefined,
	branding?: GraphQLTypes["AccountBranding"] | undefined,
	requiresExternalTool: boolean,
	requiredExternalTools: Array<GraphQLTypes["ExternalToolKind"] | undefined>,
	locale: GraphQLTypes["Locale"],
	talentPool: boolean,
	testType: GraphQLTypes["TestType"],
	testLevel: GraphQLTypes["TestLevel"],
	competencies: Array<GraphQLTypes["Skill"] | undefined>,
	createdAt: GraphQLTypes["DateTime"]
};
	["TestPreviewUnavailable"]: {
	__typename: "TestPreviewUnavailable",
	/** Test preview unavailable. */
	reason?: string | undefined
};
	["CandidateSolutionPreview"]: {
	__typename: "CandidateSolutionPreview",
	assessment: GraphQLTypes["TestPreview"],
	solutionState: GraphQLTypes["SolutionState"],
	candidate: GraphQLTypes["CandidatePreview"],
	score?: GraphQLTypes["Decimal"] | undefined,
	timeSpent?: number | undefined,
	summary?: Array<GraphQLTypes["CandidateSummaryEntry"] | undefined> | undefined
};
	/** An enumeration. */
["SolutionState"]: SolutionState;
	["CandidatePreview"]: {
	__typename: "CandidatePreview",
	uuid: GraphQLTypes["UUID"],
	phoneNumber?: string | undefined,
	firstName: string,
	lastName: string,
	email: string
};
	["CandidateSummaryEntry"]: {
	__typename: "CandidateSummaryEntry",
	competency: GraphQLTypes["Competency"],
	numberOfQuestions: number,
	score: GraphQLTypes["Decimal"],
	comment?: string | undefined,
	label?: string | undefined
};
	["Mutation"]: {
	__typename: "Mutation",
	/** Authenticate using an email and password */
	auth?: GraphQLTypes["Auth"] | undefined,
	heroTracking?: GraphQLTypes["HeroTracking"] | undefined,
	candidate?: GraphQLTypes["CandidateMutation"] | undefined,
	client?: GraphQLTypes["ClientMutation"] | undefined
};
	["Auth"]: {
	__typename: "Auth",
	authorizationToken: string
};
	["HeroTracking"]: {
	__typename: "HeroTracking",
	result?: string | undefined
};
	["TrackInput"]: {
		eventName: string,
	properties: string
};
	["CandidateMutation"]: {
	__typename: "CandidateMutation",
	talentPoolOptIn?: GraphQLTypes["TalentPoolOptIn"] | undefined,
	addCommentForQuestion?: GraphQLTypes["AddCommentForQuestion"] | undefined,
	submitSolution?: GraphQLTypes["SubmitSolution"] | undefined,
	reportAnomaly?: GraphQLTypes["ReportAnomaly"] | undefined,
	startSolution?: GraphQLTypes["StartSolution"] | undefined,
	submitLikertResponse?: GraphQLTypes["SubmitLikertResponse"] | undefined,
	submitTest?: GraphQLTypes["SubmitTest"] | undefined,
	registerCandidate?: GraphQLTypes["RegisterCandidate"] | undefined,
	issue2faToken?: GraphQLTypes["Issue2FAToken"] | undefined,
	verify2faToken?: GraphQLTypes["Verify2FAToken"] | undefined,
	registerVerifiedCandidate?: GraphQLTypes["RegisterVerifiedCandidate"] | undefined,
	startTest?: GraphQLTypes["StartTest"] | undefined,
	seeQuestion?: GraphQLTypes["SeeQuestion"] | undefined,
	submitResponse?: GraphQLTypes["SubmitResponse"] | undefined,
	submitSituationalResponse?: GraphQLTypes["SubmitSituationalResponse"] | undefined,
	skipQuestion?: GraphQLTypes["SkipQuestion"] | undefined,
	giveFeedback?: GraphQLTypes["CandidateGiveFeedback"] | undefined
};
	["TalentPoolOptIn"]: {
	__typename: "TalentPoolOptIn",
	result: boolean
};
	["AddCommentForQuestion"]: {
	__typename: "AddCommentForQuestion",
	result: boolean
};
	["SubmitSolution"]: {
	__typename: "SubmitSolution",
	result: boolean
};
	["ReportAnomaly"]: {
	__typename: "ReportAnomaly",
	result: boolean
};
	/** An enumeration. */
["AnomalyType"]: AnomalyType;
	["StartSolution"]: {
	__typename: "StartSolution",
	result: GraphQLTypes["SolutionAttempt"]
};
	["SolutionAttempt"]: {
	__typename: "SolutionAttempt",
	suggestedTime: number,
	testType: GraphQLTypes["TestType"],
	showComments: boolean,
	companyName: string,
	tests: Array<GraphQLTypes["Test"] | undefined>,
	solutionId: GraphQLTypes["UUID"]
};
	["SubmitLikertResponse"]: {
	__typename: "SubmitLikertResponse",
	result: boolean
};
	/** An enumeration. */
["LikertAnswer"]: LikertAnswer;
	["SubmitTest"]: {
	__typename: "SubmitTest",
	result: string
};
	["SubmitTestInput"]: {
		candidateSessionToken: string,
	testId: number
};
	["RegisterCandidate"]: {
	__typename: "RegisterCandidate",
	sessionToken: string
};
	["Issue2FAToken"]: {
	__typename: "Issue2FAToken",
	result?: string | undefined
};
	["Verify2FAToken"]: {
	__typename: "Verify2FAToken",
	intermediateToken?: string | undefined
};
	["RegisterVerifiedCandidate"]: {
	__typename: "RegisterVerifiedCandidate",
	candidateSessionToken: string
};
	["StartTest"]: {
	__typename: "StartTest",
	result?: string | undefined
};
	["StartTestInput"]: {
		candidateSessionToken: string,
	testId: number
};
	["SeeQuestion"]: {
	__typename: "SeeQuestion",
	result: string,
	responseId: string
};
	["SeeQuestionInput"]: {
		candidateSessionToken: string,
	questionId: GraphQLTypes["UUID"],
	testId: number
};
	["SubmitResponse"]: {
	__typename: "SubmitResponse",
	result: string
};
	["SubmitResponseInput"]: {
		candidateSessionToken: string,
	questionId: GraphQLTypes["UUID"],
	testId: number,
	answersGiven: Array<GraphQLTypes["AnswerGivenInput"]>
};
	["AnswerGivenInput"]: {
		text?: string | undefined,
	answerId?: GraphQLTypes["UUID"] | undefined
};
	["SubmitSituationalResponse"]: {
	__typename: "SubmitSituationalResponse",
	result: string
};
	["SubmitSituationalResponseInput"]: {
		candidateSessionToken: string,
	questionId: GraphQLTypes["UUID"],
	testId: number,
	answersGiven: Array<GraphQLTypes["SituationalAnswerGivenInput"]>
};
	["SituationalAnswerGivenInput"]: {
		answerId?: GraphQLTypes["UUID"] | undefined,
	value?: number | undefined
};
	["SkipQuestion"]: {
	__typename: "SkipQuestion",
	result: string
};
	["SkipQuestionInput"]: {
		candidateSessionToken: string,
	questionId: GraphQLTypes["UUID"],
	testId: number
};
	["CandidateGiveFeedback"]: {
	__typename: "CandidateGiveFeedback",
	result: string
};
	["ClientMutation"]: {
	__typename: "ClientMutation",
	startCheckoutSession?: GraphQLTypes["StartCheckoutSession"] | undefined,
	cancelSubscription?: GraphQLTypes["CancelSubscription"] | undefined,
	createAssessment?: GraphQLTypes["CreateAssessment"] | undefined,
	signUp?: GraphQLTypes["SignUp"] | undefined,
	submitOnboardingSurvey?: GraphQLTypes["SubmitOnboardingSurvey"] | undefined,
	submitOnboardingAccountDetails?: GraphQLTypes["SubmitOnboardingAccountDetails"] | undefined,
	visitOnboarding?: GraphQLTypes["VisitOnboarding"] | undefined,
	sendEmail?: GraphQLTypes["SendEmail"] | undefined,
	changeNotificationFrequency?: GraphQLTypes["ChangeNotificationFrequency"] | undefined,
	solutionToken?: GraphQLTypes["SolutionToken"] | undefined,
	changeAtsAssociationSettings?: GraphQLTypes["ChangeAtsAssociationSettings"] | undefined,
	deleteAtsAssociation?: GraphQLTypes["DeleteAtsAssociation"] | undefined,
	earlyAccessSignup?: GraphQLTypes["EarlyAccessSignup"] | undefined,
	setPassword?: GraphQLTypes["SetPassword"] | undefined,
	sendResetPassword?: GraphQLTypes["SendResetPassword"] | undefined,
	archiveTest?: GraphQLTypes["ArchiveTest"] | undefined,
	unarchiveTest?: GraphQLTypes["UnarchiveTest"] | undefined,
	deleteTest?: GraphQLTypes["DeleteTest"] | undefined,
	updateCandidatesStatus?: GraphQLTypes["UpdateCandidatesStatus"] | undefined,
	updateBranding?: GraphQLTypes["UpdateBranding"] | undefined,
	seeSolution?: GraphQLTypes["ClientSeeSolution"] | undefined
};
	["StartCheckoutSession"]: {
	__typename: "StartCheckoutSession",
	result: GraphQLTypes["StartCheckoutSessionResult"]
};
	["StartCheckoutSessionResult"]: {
	__typename: "StartCheckoutSessionResult",
	url: string
};
	["CancelSubscription"]: {
	__typename: "CancelSubscription",
	result: string
};
	["CreateAssessment"]: {
	__typename: "CreateAssessment",
	result: GraphQLTypes["Assessment"]
};
	["SignUp"]: {
	__typename: "SignUp",
	result: GraphQLTypes["SignupResult"]
};
	["SignupResult"]:{
        	__typename:"SignupResultSuccess" | "SignupResultError"
        	['...on SignupResultSuccess']: '__union' & GraphQLTypes["SignupResultSuccess"];
	['...on SignupResultError']: '__union' & GraphQLTypes["SignupResultError"];
};
	["SignupResultSuccess"]: {
	__typename: "SignupResultSuccess",
	auth: string,
	changePasswordToken: string
};
	["SignupResultError"]: {
	__typename: "SignupResultError",
	value: string
};
	["SubmitOnboardingSurvey"]: {
	__typename: "SubmitOnboardingSurvey",
	result: boolean
};
	/** Allows use of a JSON String for input / output from the GraphQL schema.

Use of this type is *not recommended* as you lose the benefits of having a defined, static
schema (one of the key benefits of GraphQL). */
["JSONString"]: "scalar" & { name: "JSONString" };
	["SubmitOnboardingAccountDetails"]: {
	__typename: "SubmitOnboardingAccountDetails",
	result: GraphQLTypes["SubmitOnboardingAccountDetailsResult"]
};
	["SubmitOnboardingAccountDetailsResult"]:{
        	__typename:"SubmitOnboardingAccountDetailsSuccess" | "SiubmitOnboardingAccountDetailsError"
        	['...on SubmitOnboardingAccountDetailsSuccess']: '__union' & GraphQLTypes["SubmitOnboardingAccountDetailsSuccess"];
	['...on SiubmitOnboardingAccountDetailsError']: '__union' & GraphQLTypes["SiubmitOnboardingAccountDetailsError"];
};
	["SubmitOnboardingAccountDetailsSuccess"]: {
	__typename: "SubmitOnboardingAccountDetailsSuccess",
	result: boolean
};
	["SiubmitOnboardingAccountDetailsError"]: {
	__typename: "SiubmitOnboardingAccountDetailsError",
	error: string
};
	["VisitOnboarding"]: {
	__typename: "VisitOnboarding",
	result: boolean
};
	["SendEmail"]: {
	__typename: "SendEmail",
	result: boolean
};
	["ChangeNotificationFrequency"]: {
	__typename: "ChangeNotificationFrequency",
	result: boolean
};
	["SolutionToken"]: {
	__typename: "SolutionToken",
	result: GraphQLTypes["SolutionTokenResult"]
};
	["SolutionTokenResult"]:{
        	__typename:"SolutionTokenResultSuccess" | "SolutionTokenResultError"
        	['...on SolutionTokenResultSuccess']: '__union' & GraphQLTypes["SolutionTokenResultSuccess"];
	['...on SolutionTokenResultError']: '__union' & GraphQLTypes["SolutionTokenResultError"];
};
	["SolutionTokenResultSuccess"]: {
	__typename: "SolutionTokenResultSuccess",
	token?: string | undefined
};
	["SolutionTokenResultError"]: {
	__typename: "SolutionTokenResultError",
	error?: string | undefined
};
	["ChangeAtsAssociationSettings"]: {
	__typename: "ChangeAtsAssociationSettings",
	result: GraphQLTypes["ChangeAtsAssociationSettingsUnion"]
};
	["ChangeAtsAssociationSettingsUnion"]:{
        	__typename:"ChangeAtsAssociationSettingsSuccess" | "ChangeAtsAssociationSettingsError"
        	['...on ChangeAtsAssociationSettingsSuccess']: '__union' & GraphQLTypes["ChangeAtsAssociationSettingsSuccess"];
	['...on ChangeAtsAssociationSettingsError']: '__union' & GraphQLTypes["ChangeAtsAssociationSettingsError"];
};
	["ChangeAtsAssociationSettingsSuccess"]: {
	__typename: "ChangeAtsAssociationSettingsSuccess",
	result: boolean
};
	["ChangeAtsAssociationSettingsError"]: {
	__typename: "ChangeAtsAssociationSettingsError",
	result: boolean,
	error: string
};
	["DeleteAtsAssociation"]: {
	__typename: "DeleteAtsAssociation",
	result: GraphQLTypes["DeleteAtsAssociationUnion"]
};
	["DeleteAtsAssociationUnion"]:{
        	__typename:"DeleteAtsAssociationError" | "DeleteAtsAssociationSuccess"
        	['...on DeleteAtsAssociationError']: '__union' & GraphQLTypes["DeleteAtsAssociationError"];
	['...on DeleteAtsAssociationSuccess']: '__union' & GraphQLTypes["DeleteAtsAssociationSuccess"];
};
	["DeleteAtsAssociationError"]: {
	__typename: "DeleteAtsAssociationError",
	result: boolean,
	error: string
};
	["DeleteAtsAssociationSuccess"]: {
	__typename: "DeleteAtsAssociationSuccess",
	result: boolean
};
	["EarlyAccessSignup"]: {
	__typename: "EarlyAccessSignup",
	result: GraphQLTypes["EarlyAccessSignupResult"]
};
	["EarlyAccessSignupResult"]: {
	__typename: "EarlyAccessSignupResult",
	status: boolean,
	errors: Array<GraphQLTypes["Error"]>
};
	["Error"]: {
	__typename: "Error",
	name: string,
	message: string
};
	["SetPassword"]: {
	__typename: "SetPassword",
	authorizationToken?: string | undefined
};
	["SendResetPassword"]: {
	__typename: "SendResetPassword",
	Result: string,
	result: string
};
	["SendResetPasswordInput"]: {
		email: string
};
	["ArchiveTest"]: {
	__typename: "ArchiveTest",
	result: string
};
	["UnarchiveTest"]: {
	__typename: "UnarchiveTest",
	result: string
};
	["DeleteTest"]: {
	__typename: "DeleteTest",
	result: string
};
	["UpdateCandidatesStatus"]: {
	__typename: "UpdateCandidatesStatus",
	candidates: Array<GraphQLTypes["SolutionCandidate"]>
};
	["UpdateCandidatesStatusInput"]: {
		candidateIds: Array<GraphQLTypes["UUID"] | undefined>,
	status: GraphQLTypes["CandidateStatusInput"]
};
	/** An enumeration. */
["CandidateStatusInput"]: CandidateStatusInput;
	["UpdateBranding"]: {
	__typename: "UpdateBranding",
	result: string
};
	["BrandingInput"]: {
		logo?: GraphQLTypes["Upload"] | undefined,
	image?: GraphQLTypes["Upload"] | undefined,
	primaryColor: string,
	secondaryColor?: string | undefined,
	headline: string,
	description: string,
	removeLogo?: boolean | undefined,
	removeImage?: boolean | undefined
};
	/** Create scalar that ignores normal serialization/deserialization, since
that will be handled by the multipart request spec */
["Upload"]: "scalar" & { name: "Upload" };
	["ClientSeeSolution"]: {
	__typename: "ClientSeeSolution",
	result: string
};
	["SeeSolution"]: {
		solutionId: GraphQLTypes["UUID"]
}
    }
/** An enumeration. */
export const enum UserLocale {
	PL = "PL",
	EN = "EN"
}
/** An enumeration. */
export const enum AccountLocale {
	PL = "PL",
	EN = "EN"
}
/** An enumeration. */
export const enum AccountBillingModel {
	NONE = "NONE",
	TOKENS = "TOKENS",
	SLOTS = "SLOTS"
}
/** An enumeration. */
export const enum SubscriptionState {
	Active = "Active",
	PastDue = "PastDue",
	Trialing = "Trialing",
	Canceled = "Canceled"
}
/** An enumeration. */
export const enum Locale {
	PL = "PL",
	EN = "EN"
}
/** An enumeration. */
export const enum Feature {
	ATS = "ATS"
}
/** An enumeration. */
export const enum Icon {
	ATTENTION_TO_DETAILS = "ATTENTION_TO_DETAILS",
	COGNITIVE_SKILLS = "COGNITIVE_SKILLS",
	GOOGLE_ADS = "GOOGLE_ADS",
	GOOGLE_ANALYTICS = "GOOGLE_ANALYTICS",
	MARKETING_B2B = "MARKETING_B2B"
}
/** An enumeration. */
export const enum SkillType {
	COGNITIVE = "COGNITIVE",
	KNOWLEDGE = "KNOWLEDGE",
	PERSONALITY = "PERSONALITY",
	OPEN_QUESTIONS = "OPEN_QUESTIONS",
	SITUATIONAL = "SITUATIONAL"
}
/** An enumeration. */
export const enum TestLevel {
	BASIC = "BASIC",
	ADVANCED = "ADVANCED",
	MANAGER = "MANAGER"
}
/** An enumeration. */
export const enum TestType {
	VALIDATION = "VALIDATION",
	FILTERING = "FILTERING",
	ADVISORY = "ADVISORY"
}
/** An enumeration. */
export const enum CandidateStatus {
	NEW = "NEW",
	SEEN = "SEEN",
	GOOD = "GOOD",
	MAYBE = "MAYBE",
	REJECTED = "REJECTED"
}
/** An enumeration. */
export const enum PersonalityFit {
	VeryLow = "VeryLow",
	Low = "Low",
	Medium = "Medium",
	High = "High",
	VeryHigh = "VeryHigh"
}
export const enum AreaScoreRange {
	LOW = "LOW",
	MEDIUM = "MEDIUM",
	HIGH = "HIGH"
}
/** An enumeration. */
export const enum NotificationFrequency {
	NEVER = "NEVER",
	DAILY = "DAILY",
	IMMEDIATELY = "IMMEDIATELY"
}
/** An enumeration. */
export const enum ExternalToolKind {
	UA = "UA",
	GA4 = "GA4",
	GoogleSheets = "GoogleSheets",
	MicrosoftExcel = "MicrosoftExcel"
}
/** An enumeration. */
export const enum TestState {
	ACTIVE = "ACTIVE",
	DISABLED = "DISABLED",
	DRAFT = "DRAFT"
}
/** An enumeration. */
export const enum Seniority {
	JUNIOR = "JUNIOR",
	MID_SENIOR = "MID_SENIOR",
	MANAGER = "MANAGER"
}
/** An enumeration. */
export const enum QuestionTypes {
	SINGLE_CHOICE = "SINGLE_CHOICE",
	MULTI_CHOICE = "MULTI_CHOICE",
	OPEN_TEXT = "OPEN_TEXT",
	CLOSED_TEXT = "CLOSED_TEXT",
	CLOSED_NUMBER = "CLOSED_NUMBER",
	FILE_UPLOAD = "FILE_UPLOAD",
	SITUATIONAL = "SITUATIONAL",
	LIKERT = "LIKERT"
}
/** An enumeration. */
export const enum ResponseLabel {
	CORRECT = "CORRECT",
	INCORRECT = "INCORRECT",
	PARTIALLY_CORRECT = "PARTIALLY_CORRECT",
	SKIPPED = "SKIPPED",
	ANSWERED = "ANSWERED"
}
/** An enumeration. */
export const enum Role {
	CXO = "CXO",
	HIRING_MANAGER = "HIRING_MANAGER",
	IN_HOUSE_HR = "IN_HOUSE_HR",
	HR_AGENCY = "HR_AGENCY",
	OTHER = "OTHER"
}
/** An enumeration. */
export const enum ScoreRange {
	VeryLow = "VeryLow",
	Low = "Low",
	Medium = "Medium",
	High = "High",
	VeryHigh = "VeryHigh"
}
/** An enumeration. */
export const enum SolutionState {
	SENT = "SENT",
	OPENED = "OPENED",
	STARTED = "STARTED",
	SUBMITTED = "SUBMITTED",
	EXPIRED = "EXPIRED",
	PENDING = "PENDING"
}
/** An enumeration. */
export const enum AnomalyType {
	BLUR = "BLUR"
}
/** An enumeration. */
export const enum LikertAnswer {
	STRONGLY_AGREE = "STRONGLY_AGREE",
	AGREE = "AGREE",
	NEUTRAL = "NEUTRAL",
	DISAGREE = "DISAGREE",
	STRONGLY_DISAGREE = "STRONGLY_DISAGREE"
}
/** An enumeration. */
export const enum CandidateStatusInput {
	NEW = "NEW",
	SEEN = "SEEN",
	GOOD = "GOOD",
	MAYBE = "MAYBE",
	REJECTED = "REJECTED"
}

type ZEUS_VARIABLES = {
	["UUID"]: ValueTypes["UUID"];
	["UserLocale"]: ValueTypes["UserLocale"];
	["DateTime"]: ValueTypes["DateTime"];
	["AccountLocale"]: ValueTypes["AccountLocale"];
	["AccountBillingModel"]: ValueTypes["AccountBillingModel"];
	["SubscriptionState"]: ValueTypes["SubscriptionState"];
	["Locale"]: ValueTypes["Locale"];
	["Feature"]: ValueTypes["Feature"];
	["Icon"]: ValueTypes["Icon"];
	["SkillType"]: ValueTypes["SkillType"];
	["PositionInput"]: ValueTypes["PositionInput"];
	["TestLevel"]: ValueTypes["TestLevel"];
	["TestType"]: ValueTypes["TestType"];
	["CandidateStatus"]: ValueTypes["CandidateStatus"];
	["Decimal"]: ValueTypes["Decimal"];
	["PersonalityFit"]: ValueTypes["PersonalityFit"];
	["AreaScoreRange"]: ValueTypes["AreaScoreRange"];
	["NotificationFrequency"]: ValueTypes["NotificationFrequency"];
	["ExternalToolKind"]: ValueTypes["ExternalToolKind"];
	["TestState"]: ValueTypes["TestState"];
	["Seniority"]: ValueTypes["Seniority"];
	["QuestionTypes"]: ValueTypes["QuestionTypes"];
	["ResponseLabel"]: ValueTypes["ResponseLabel"];
	["SolutionResponsesInput"]: ValueTypes["SolutionResponsesInput"];
	["Role"]: ValueTypes["Role"];
	["ScoreRange"]: ValueTypes["ScoreRange"];
	["SolutionState"]: ValueTypes["SolutionState"];
	["TrackInput"]: ValueTypes["TrackInput"];
	["AnomalyType"]: ValueTypes["AnomalyType"];
	["LikertAnswer"]: ValueTypes["LikertAnswer"];
	["SubmitTestInput"]: ValueTypes["SubmitTestInput"];
	["StartTestInput"]: ValueTypes["StartTestInput"];
	["SeeQuestionInput"]: ValueTypes["SeeQuestionInput"];
	["SubmitResponseInput"]: ValueTypes["SubmitResponseInput"];
	["AnswerGivenInput"]: ValueTypes["AnswerGivenInput"];
	["SubmitSituationalResponseInput"]: ValueTypes["SubmitSituationalResponseInput"];
	["SituationalAnswerGivenInput"]: ValueTypes["SituationalAnswerGivenInput"];
	["SkipQuestionInput"]: ValueTypes["SkipQuestionInput"];
	["JSONString"]: ValueTypes["JSONString"];
	["SendResetPasswordInput"]: ValueTypes["SendResetPasswordInput"];
	["UpdateCandidatesStatusInput"]: ValueTypes["UpdateCandidatesStatusInput"];
	["CandidateStatusInput"]: ValueTypes["CandidateStatusInput"];
	["BrandingInput"]: ValueTypes["BrandingInput"];
	["Upload"]: ValueTypes["Upload"];
	["SeeSolution"]: ValueTypes["SeeSolution"];
}