/* eslint-disable no-console */
import React from "react"
import env from "@/env"
import {
    ApolloClient,
    ApolloProvider as _ApolloProvider,
    defaultDataIdFromObject,
    from,
    HttpLink,
    InMemoryCache,
    Observable,
} from "@apollo/client"
import { setContext } from "@apollo/client/link/context"
import { onError } from "@apollo/client/link/error"
import { notification } from "antd"
import { createUploadLink } from "apollo-upload-client"

import { API_URL, NEW_API_URL, RETRY_FAILED_REQUEST_DELAY_IN_MILLISECONDS } from "../../constants"
import { getClientTokenFromCookies } from "../../utils"

const uriToGqlUri = (uri: string) => `${uri}/graphql`

const oldBackendHttpLink = new HttpLink({
    uri: uriToGqlUri(API_URL),
})

const authLink = setContext((_, { headers }) => {
    const token = getClientTokenFromCookies()
    return {
        headers: {
            ...headers,
            ...(token ? { authorization: `Bearer ${token}` } : {}),
        },
    }
})

const isProduction = env.deploymentEnv === "production"
const isStaging = env.deploymentEnv === "staging"

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
        graphQLErrors.forEach(({ message }, index) => {
            if (index > 5) {
                console.log("More errors!")
                return
            }
            if (process.browser && !isProduction && !isStaging) return notification.error({ message })
        })
    }

    if (networkError) {
        console.log(`[Network error]: ${networkError}`)

        return new Observable(observer => {
            const interval = setInterval(() => {
                forward(operation).subscribe({
                    next: result => {
                        observer.next(result)
                        observer.complete()
                        clearInterval(interval)
                    },
                    error: err => {
                        console.log(`[Retry error]: ${err}`)
                    },
                })
            }, RETRY_FAILED_REQUEST_DELAY_IN_MILLISECONDS)
        })
    }
})

const uploadLink = createUploadLink({
    uri: `${uriToGqlUri(`${NEW_API_URL}`)}/`,
})

const client = new ApolloClient({
    cache: new InMemoryCache({
        typePolicies: {
            ClientQuery: {
                merge: true,
            },
            CandidateQuery: {
                merge: true,
            },
        },
        dataIdFromObject(object) {
            /* Our backend returns `uuid` instead of (Apollo's default) `id` property */
            if ("__typename" in object && "uuid" in object) {
                return `${object.__typename}:${object.uuid}`
            }

            return defaultDataIdFromObject(object)
        },
    }),
    connectToDevTools: true,
    link: from([
        errorLink,
        authLink.split(
            operation => {
                if (operation.getContext().newApi === true) {
                    console.log(`Using new API for operation ${operation.operationName}`)
                    return true
                }

                return false
            },
            uploadLink,
            oldBackendHttpLink
        ),
    ]),
})

export const ApolloProvider = ({ children }: { children: React.ReactNode }) => {
    return <_ApolloProvider client={client}>{children}</_ApolloProvider>
}
